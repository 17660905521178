import { css } from 'styled-components'

const base = css`
  display: block;
  width: 100%;
  border: solid 1px #e8ebf7;
  padding: 0.6875rem 0.6875rem;
  border-radius: 0.25rem;
  font-size: 16px; // Will be kept constant by zoom issue on iOS
  line-height: 1.5;
  color: #030f1c;

  &:hover {
    border-color: #030f1c;
  }

  &:focus {
    border-color: #030f1c;
    outline: 0;
  }

  &:disabled {
    background: ${(props) => props.theme.colors.dark25};
    color: ${(props) => props.theme.colors.dark};
  }
`

const basePlaceholder = css`
  &::placeholder {
    color: #5f6f86;
    opacity: 1;
  }
`

export const baseInput = css`
  ${base}
  ${basePlaceholder}
`
export const baseSelect = css`
  ${base};

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' %3E%3Cg id='Icons/chevron_down' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Box-24pt' x='0' y='0' width='24' height='24'%3E%3C/rect%3E%3Cg id='chevron-down' transform='translate(5.000000, 8.000000)' fill='%235f6f86'%3E%3Cpath d='M0.292893219,0.292893219 C0.65337718,-0.0675907428 1.22060824,-0.0953202783 1.61289944,0.209704612 L1.70710678,0.292893219 L7,5.585 L12.2928932,0.292893219 C12.6533772,-0.0675907428 13.2206082,-0.0953202783 13.6128994,0.209704612 L13.7071068,0.292893219 C14.0675907,0.65337718 14.0953203,1.22060824 13.7902954,1.61289944 L13.7071068,1.70710678 L7.70710678,7.70710678 C7.34662282,8.06759074 6.77939176,8.09532028 6.38710056,7.79029539 L6.29289322,7.70710678 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.6875rem center;
  background-size: 24px 24px;
  padding-right: 2.875rem;

  option[disabled] {
    color: #aaaaaa;
  }

  &::-ms-expand {
    display: none;
  }
`
