import { AxiosPromise } from 'axios'
import fetch from './fetch'
import { Country } from '../../types'

interface CommerceLayer {
  id?: number
  stockLocation: { number: number; name: string }
  market: { number: number; id: string }
  shippingMethod: { id: string }
  paymentMethod: { id: string }
}

interface Zone {
  id?: number
  name?: string
}

export interface City {
  id: number
  slug: string
  name: string
  internalName: string
  closingTime: string
  googleEmbedId: string
  deliveryMaxCapacity: number
  zones: Zone[]
  commerceLayer: CommerceLayer
  metadata: { commerceMid?: string } | null
}

type ResponseCities = City[]

export function getCities(params?: { country: Country }): AxiosPromise<ResponseCities> {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    country: (params && params.country) || 'CL',
  }

  return fetch('/cities', { headers, method: 'GET' })
}
