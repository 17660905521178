import { AxiosPromise } from 'axios'
import { log } from '../../utils/log'
import fetch from './fetch'
import config from '../../config'

const { COUNTRY } = config

interface ArgumentsDeleteLineItem {
  lineItemId: string
}

export function deleteLineItem(params: ArgumentsDeleteLineItem): AxiosPromise {
  const { lineItemId } = params
  log.trace('DELETING LINE ITEM', lineItemId)

  return fetch(`line_items`, { method: 'DELETE', data: { country: COUNTRY, id: lineItemId } })
}
