import React, { ReactNode, useEffect, useReducer } from 'react'
import reducer, { SetUserParams } from './reducer'
import { AuthContext, DispatchContext, AuthState } from './context'
import {
  CLOrder,
  setOrderCustomer,
  getOrderId,
  createOrderAndGetId,
  setOrderId,
  getCartId,
  loadState,
  saveState,
  City,
  updateOrderMetadata,
  CountryCode,
} from '../../..'
import {
  actionReset,
  actionSignIn,
  actionSignOut,
  actionUpdateCustomer,
  actionHasToken,
  actionSetIsLoading,
  SignInReturn as TypeSignInReturn,
  actionSetGuest,
  actionSetUser,
  actionSetFavoriteSkus,
  actionSetAvailabilitiesSkus,
} from './actions'

export const AUTH_PERSIST_KEY = 'auth-v5'

export function AuthProvider(props: { children: ReactNode }) {
  const persistKey = AUTH_PERSIST_KEY
  const { children } = props

  const refreshToken = !!loadState('refreshToken')
  const persistState = loadState<AuthState>(persistKey)

  const initialState = persistState ? { ...persistState, isAuth: refreshToken, error: null } : AuthState

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    saveState(persistKey, state)
  }, [state])

  return (
    <AuthContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </AuthContext.Provider>
  )
}

export interface ShoppingCart {
  state: {
    globalQuantity: number
    orderId: string
  }
  cloneOrder: (fromOrder: CLOrder, toOrderId: string) => void
}

export interface SignInParams {
  username: string
  password: string
}

export function useAuth(country?: CountryCode) {
  const state = React.useContext(AuthContext)
  const dispatch = React.useContext(DispatchContext)

  if (state === undefined || dispatch === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  interface OnGuestParams {
    firstName: string
    lastName: string
    rut: string
    email: string
    birthdate: string
    terms: boolean
    willCheckout: boolean
    currentCity?: City
    billingName?: string
    dniComplement?: string
    documentType?: string
  }

  const onGuest = async (params: OnGuestParams): Promise<void> => {
    const cartId = getCartId()
    if (!cartId) throw new Error(`Invalid value: cartId is ${cartId}`)

    let orderId = getOrderId()
    if (!params.willCheckout) return
    if (!orderId) {
      orderId = await createOrderAndGetId()
      setOrderId(orderId)
    }
    await setOrderCustomer({ orderId, customerEmail: params.email })
    const orderMetadata = {
      firstName: params.firstName,
      lastName: params.lastName,
      dni: params.rut,
      birthdate: params.birthdate,
    }
    if (country) await updateOrderMetadata({ orderId, metadata: orderMetadata, country })
    actionSetGuest(dispatch)(params)
  }

  return {
    state,
    onGuest,
    onReset: actionReset(dispatch),
    onSignIn: actionSignIn(dispatch),
    onSignOut: actionSignOut(dispatch),
    onHasToken: actionHasToken(dispatch),
    onHasAnonymousToken: () => dispatch({ type: 'HAS_ANONYMOUS_TOKEN' }),
    onCustomerUpdate: actionUpdateCustomer(dispatch),
    setIsLoading: actionSetIsLoading(dispatch),
    setGuest: actionSetGuest(dispatch),
    setUser: actionSetUser(dispatch),
    setFavorites: actionSetFavoriteSkus(dispatch),
    setAvailabilities: actionSetAvailabilitiesSkus(dispatch),
  }
}

export type SignInReturn = TypeSignInReturn
export type { SetUserParams }
