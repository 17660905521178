import { City } from '../../services/Location/cities'
import { Country } from '../../..'
import { LocationDispatch } from './context'

export function actionSetCities(dispatch: LocationDispatch) {
  return (cities: City[]) => dispatch({ type: 'SET_CITIES', cities })
}

export function actionSetCountry(dispatch: LocationDispatch) {
  return (country: Country) => dispatch({ type: 'SET_COUNTRY', country })
}

export function actionSetLocation(dispatch: LocationDispatch) {
  return (city: City) => dispatch({ type: 'SET_CURRENT_CITY', city })
}
