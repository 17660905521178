// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-category-redirect-tsx": () => import("./../../../src/pages/category-redirect.tsx" /* webpackChunkName: "component---src-pages-category-redirect-tsx" */),
  "component---src-pages-category-tsx": () => import("./../../../src/pages/category.tsx" /* webpackChunkName: "component---src-pages-category-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-concursos-tsx": () => import("./../../../src/pages/concursos.tsx" /* webpackChunkName: "component---src-pages-concursos-tsx" */),
  "component---src-pages-coverage-tsx": () => import("./../../../src/pages/coverage.tsx" /* webpackChunkName: "component---src-pages-coverage-tsx" */),
  "component---src-pages-covid-tsx": () => import("./../../../src/pages/covid.tsx" /* webpackChunkName: "component---src-pages-covid-tsx" */),
  "component---src-pages-cuenta-activada-tsx": () => import("./../../../src/pages/cuenta-activada.tsx" /* webpackChunkName: "component---src-pages-cuenta-activada-tsx" */),
  "component---src-pages-cyber-tsx": () => import("./../../../src/pages/cyber.tsx" /* webpackChunkName: "component---src-pages-cyber-tsx" */),
  "component---src-pages-direccion-tsx": () => import("./../../../src/pages/direccion.tsx" /* webpackChunkName: "component---src-pages-direccion-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jw-label-tsx": () => import("./../../../src/pages/jw-label.tsx" /* webpackChunkName: "component---src-pages-jw-label-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-licores-tsx": () => import("./../../../src/pages/licores.tsx" /* webpackChunkName: "component---src-pages-licores-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-mis-direcciones-tsx": () => import("./../../../src/pages/mis-direcciones.tsx" /* webpackChunkName: "component---src-pages-mis-direcciones-tsx" */),
  "component---src-pages-mis-favoritos-tsx": () => import("./../../../src/pages/mis-favoritos.tsx" /* webpackChunkName: "component---src-pages-mis-favoritos-tsx" */),
  "component---src-pages-moment-redirect-tsx": () => import("./../../../src/pages/moment-redirect.tsx" /* webpackChunkName: "component---src-pages-moment-redirect-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-my-orders-index-tsx": () => import("./../../../src/pages/my-orders/index.tsx" /* webpackChunkName: "component---src-pages-my-orders-index-tsx" */),
  "component---src-pages-my-orders-order-tsx": () => import("./../../../src/pages/my-orders/order.tsx" /* webpackChunkName: "component---src-pages-my-orders-order-tsx" */),
  "component---src-pages-network-error-tsx": () => import("./../../../src/pages/network-error.tsx" /* webpackChunkName: "component---src-pages-network-error-tsx" */),
  "component---src-pages-new-password-index-tsx": () => import("./../../../src/pages/new-password/index.tsx" /* webpackChunkName: "component---src-pages-new-password-index-tsx" */),
  "component---src-pages-new-password-success-tsx": () => import("./../../../src/pages/new-password/success.tsx" /* webpackChunkName: "component---src-pages-new-password-success-tsx" */),
  "component---src-pages-payment-cancelled-tsx": () => import("./../../../src/pages/payment/cancelled.tsx" /* webpackChunkName: "component---src-pages-payment-cancelled-tsx" */),
  "component---src-pages-payment-pending-tsx": () => import("./../../../src/pages/payment/pending.tsx" /* webpackChunkName: "component---src-pages-payment-pending-tsx" */),
  "component---src-pages-payment-processing-tsx": () => import("./../../../src/pages/payment/processing.tsx" /* webpackChunkName: "component---src-pages-payment-processing-tsx" */),
  "component---src-pages-politica-de-privacidad-tsx": () => import("./../../../src/pages/politica-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-politica-de-privacidad-tsx" */),
  "component---src-pages-product-redirect-tsx": () => import("./../../../src/pages/product-redirect.tsx" /* webpackChunkName: "component---src-pages-product-redirect-tsx" */),
  "component---src-pages-promo-presiona-play-y-gana-tsx": () => import("./../../../src/pages/PromoPresionaPlayYGana.tsx" /* webpackChunkName: "component---src-pages-promo-presiona-play-y-gana-tsx" */),
  "component---src-pages-promotion-redirect-tsx": () => import("./../../../src/pages/promotion-redirect.tsx" /* webpackChunkName: "component---src-pages-promotion-redirect-tsx" */),
  "component---src-pages-promotion-tsx": () => import("./../../../src/pages/promotion.tsx" /* webpackChunkName: "component---src-pages-promotion-tsx" */),
  "component---src-pages-qr-code-tsx": () => import("./../../../src/pages/qr-code.tsx" /* webpackChunkName: "component---src-pages-qr-code-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-registro-confirmacion-tsx": () => import("./../../../src/pages/registro/confirmacion.tsx" /* webpackChunkName: "component---src-pages-registro-confirmacion-tsx" */),
  "component---src-pages-registro-exito-tsx": () => import("./../../../src/pages/registro/exito.tsx" /* webpackChunkName: "component---src-pages-registro-exito-tsx" */),
  "component---src-pages-registro-index-tsx": () => import("./../../../src/pages/registro/index.tsx" /* webpackChunkName: "component---src-pages-registro-index-tsx" */),
  "component---src-pages-reset-password-confirm-tsx": () => import("./../../../src/pages/reset-password/confirm.tsx" /* webpackChunkName: "component---src-pages-reset-password-confirm-tsx" */),
  "component---src-pages-reset-password-index-tsx": () => import("./../../../src/pages/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-reset-password-index-tsx" */),
  "component---src-pages-reset-password-success-tsx": () => import("./../../../src/pages/reset-password/success.tsx" /* webpackChunkName: "component---src-pages-reset-password-success-tsx" */),
  "component---src-pages-returnability-tsx": () => import("./../../../src/pages/returnability.tsx" /* webpackChunkName: "component---src-pages-returnability-tsx" */),
  "component---src-pages-search-redirect-tsx": () => import("./../../../src/pages/search-redirect.tsx" /* webpackChunkName: "component---src-pages-search-redirect-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-tracking-tsx": () => import("./../../../src/pages/tracking.tsx" /* webpackChunkName: "component---src-pages-tracking-tsx" */),
  "component---src-pages-vamos-por-otra-tsx": () => import("./../../../src/pages/vamos-por-otra.tsx" /* webpackChunkName: "component---src-pages-vamos-por-otra-tsx" */),
  "component---src-templates-category-view-tsx": () => import("./../../../src/templates/CategoryView.tsx" /* webpackChunkName: "component---src-templates-category-view-tsx" */),
  "component---src-templates-moments-index-tsx": () => import("./../../../src/templates/moments/index.tsx" /* webpackChunkName: "component---src-templates-moments-index-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news/index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */),
  "component---src-templates-product-view-tsx": () => import("./../../../src/templates/ProductView.tsx" /* webpackChunkName: "component---src-templates-product-view-tsx" */),
  "component---src-templates-recipes-all-recipes-tsx": () => import("./../../../src/templates/recipes/AllRecipes.tsx" /* webpackChunkName: "component---src-templates-recipes-all-recipes-tsx" */),
  "component---src-templates-recipes-index-tsx": () => import("./../../../src/templates/recipes/index.tsx" /* webpackChunkName: "component---src-templates-recipes-index-tsx" */)
}

