import { createContext } from 'react'
import { ShoppingCartActions } from './reducer'
import { ByHash, Promotions, PromotionDetail } from '../../types'
import { getStoredCity } from '../../utils/store'

export interface LineItemMetadata {
  isBundle: boolean
  recyclable: boolean
  price: number
  originalPrice: number
}

// Data strictly describing shopping cart
export interface ShoppingCart {
  byHash: ByHash
  globalQuantity: number
  globalRawTotal: number
  globalTotal: number
  globalTotalDiscounted: number
  couponCode?: string
  globalCouponDiscounted: number
  isRawDiscount?: boolean
  adjustment?: number
  hasRawXunitDiscounts?: boolean
  promotions?: Promotions
  promotionsUpdatedAt?: number
  globalTotalPromotion?: number
  giftLineItems?: PromotionDetail[]
  totalGifts?: number
}

// Metadata for the shopping cart
export interface ShoppingCartState extends ShoppingCart {
  shippingCost: number
  cartId: string
  maxCartLineProductQuantity: number
  maxCartLineBundleQuantity: number
  promotionCouponsWhitelist: string[]
  citySlug?: string
}

export const ShoppingCartState: ShoppingCartState = {
  byHash: {} as ByHash,
  globalQuantity: 0,
  globalTotalDiscounted: 0,
  globalCouponDiscounted: 0,
  couponCode: undefined as string | undefined,
  globalRawTotal: 0,
  globalTotal: 0,
  shippingCost: 0,
  cartId: '',
  maxCartLineProductQuantity: 0,
  maxCartLineBundleQuantity: 0,
  promotionCouponsWhitelist: [],
  isRawDiscount: false,
  promotions: {},
  promotionsUpdatedAt: 0,
  globalTotalPromotion: 0,
  citySlug: getStoredCity()?.slug,
  giftLineItems: [],
  totalGifts: 0,
}

export const ShoppingCartContext = createContext<ShoppingCartState>(ShoppingCartState)
ShoppingCartContext.displayName = 'ShoppingCart'

export type ShoppingDispatch = (action: ShoppingCartActions) => void
export const DispatchContext = createContext<ShoppingDispatch | undefined>(undefined)
