import React from 'react'
import { FormattedProduct } from '../services/Algolia'

export type Error = {
  status?: number
  code: string
  description?: string
  metadata?: string
}

export type SelectOption<T = any> = {
  value: T
  label: string
}

export enum Country {
  CHILE = 'CL',
  BOLIVIA = 'BO',
}

export type CommerceLayerAddress = {
  id: string
  attributes: {
    line_1: string
    line_2?: string
    city: string
    phone: string
    first_name: string
    last_name: string
    full_name: string
    country_code: string
    state_code: string
    zip_code: string
    lat?: number
    lng?: number
    notes?: string
    metadata: {
      alias: string
      cityId: number
      cityName: string
      favorite: boolean
      region: string
    }
  }
  relationships?: {
    address: {
      data: {
        id: string
      }
    }
  }
}

export type FormattedAddress = {
  id: string
  shipping_address: string
  shipping_name: string
  shipping_phone: string
  city: string
  cityId: number
  cityName: string
  region: string
  name: string
  shipping_number?: string
  shipping_instructions?: string
  customer_address?: string
  isFavorite?: boolean
  geocode: {
    latitude?: number
    longitude?: number
  }
}

export type RadioInput = SelectOption & {
  checked?: boolean
  descriptionText?: string
}

export type MouseEventButton = React.MouseEvent<HTMLButtonElement, MouseEvent>

export type Order = {
  shipping_address: string
  shipping_number: string
  shipping_name: string
  shipping_phone: string
  shipping_instructions: string
  billing: BillingData | null
  payment: number | string | undefined
  date: string | undefined
  marketing: boolean | undefined
  terms: boolean | undefined
  addressId?: string
  payment_type?: PaymentTypes
}

export type BillingData = {
  billing_first_name: string
  billing_last_name: string
  billing_city?: string
  billing_city_code?: string
  billing_address: string
  billing_user_id: string
}

export type OrderData = Order & { addressId: string; isQRSelected?: boolean }

export interface ContentfulImageQueryParams {
  fm?: 'jpg' | 'png' | 'webp'
  fl?: 'progressive' | 'png8'
  w?: number
  h?: number
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb'
  f?:
    | 'center'
    | 'top'
    | 'right'
    | 'left'
    | 'bottom_right'
    | 'bottom_left'
    | 'top_right'
    | 'top_left'
    | 'bottom_right'
    | 'bottom_left'
    | 'face'
    | 'faces'
  r?: number | 'max'
  q?: number
  bg?: string
}

export enum PromotionType {
  PERCENTAGE_DISCOUNT = 'percentage_discount',
  MXN = 'mxn',
  X_UNIT = 'x_unit',
  A_DISCOUNTS_B = 'a_discounts_b',
  A_PERCENTAGE_DISCOUNTS_B = 'a_percentage_discounts_b',
}

export type ProductPromotion = {
  type: PromotionType
  name: string
  startsAt: string
  startsAtTime: string
  expiresAt: string
  exp: string
  skus: string[]
  markets: string[]
  productDetailTitle: string
  productDetailDescription: string
  productDetailInfoText: string
  productCardLabel: string
  discountSkus?: string[]
  uuid: string
  discountPercentage?: number
  isRaw: boolean
  discountedProduct?: FormattedProduct
  activationQuantity?: number
  catalogDetail?: string
}

export interface Product {
  skuCode: string
  image: string | null
  title: string
  price: number
  rawPrice: number
  hasDiscount: boolean
  hasStock?: boolean
  discount?: number
  recyclable?: boolean
  returnabilityLabelType?: string
  unavailable?: boolean
  isBundle?: boolean
  brandName?: string
  categoryName?: string
  packing?: string
  size?: string
  slugLocation?: string
  netContent?: number
  promotion?: ProductPromotion
  isCustomizable?: boolean
  johnnieWalkerTag?: string
  labelId?: string | null
  labelUrl?: string | null
  presale?: boolean
  thumbnail?: string
  isDummy?: boolean
}

// Region types
export type DistrictState = {
  checkRegion: boolean
  hasError: boolean
  selectedRegion: string | undefined
}

export type DistrictProps = {
  state: DistrictState
  dispatcher: React.Dispatch<React.SetStateAction<DistrictState>>
}

// Bolivia payment types
export enum PaymentTypes {
  CL = 'webpay',
  REDENLACE = 'redenlace',
  QR = 'qr-contra-entrega',
  EFECTIVO_CONTRA_ENREGA = 'efectivo-contra-entrega',
  TARJETA_CONTRA_ENREGA = 'tarjeta-contra-entrega',
  CONTRA_ENTREGA = 'contra-entrega',
}

export enum PaymentStatus {
  PAID = 'pagado',
  PENDING = 'por-pagar',
}

export type PaymentMethodType = RadioInput & { QRID?: number }

export type PaymentMethodsObject = {
  types: PaymentMethodType[]
  options: RadioInput[]
}

export type Maps = google.maps.FusionTablesMarkerOptions &
  google.maps.FusionTablesMouseEvent &
  google.maps.FusionTablesCell &
  google.maps.FusionTablesPolygonOptions

export interface WindowWithMaps extends Window {
  google: {
    maps: Maps | null
  }
}

// Cart Types
export interface ProductCart extends Product {
  quantity: number
  rawPrice: number // It must always exist in the shopping cart
  discount: number
  lineItemId?: string
  stock?: number
  onNavigate?: () => void
  promotionDetail?: PromotionDetail
  isGift?: boolean
  discountQuantity?: number
}

export type ByHash = { [K: string]: ProductCart }

export type TotalsObject = {
  globalQuantity: number
  globalRawTotal: number
  globalTotal: number
  globalTotalDiscounted: number
  globalCouponDiscounted: number
}

export type Promotions = { [K: string]: PromotionDetail }

export type PromotionDetail = {
  discountAmount?: number
  labels?: string[]
  names?: string[]
  isGift?: boolean
  discountQuantity?: number
  sku?: string
  total?: number
  promotion?: ProductPromotion
}

export type ProductCardType = 'small' | 'large' | 'responsive' | 'small-flex' | 'responsive-flex' | 'small-horizontal'

export type ApplyCouponParams = {
  code: string
  orderId: string
  market: number
  country: Country
}

export type ApplyGiftCardParams = {
  code: string
  orderId: string
  market: number
  country: Country
}

export type RemoveCouponOrGiftCardParams = {
  code: string
  orderId: string
  country: Country
}

export type ActionApplyCoupon = ({
  ...args
}: Omit<ApplyCouponParams, 'market'>) => Promise<void | {
  discountedAmount: number
  isRaw?: boolean
  adjustment?: number
}>
export type ActionRemoveCoupon = (params: RemoveCouponOrGiftCardParams) => Promise<void>

export enum AuthFormType {
  GUEST = 'guest',
  SIGNUP = 'signup',
  SIGNIN = 'signin',
}

export type StoredCustomer = {
  name: string
  email: string
  clayerId?: string
  favoriteSkus?: number[]
  slugLocation: string
  skusAvailabilityList?: number[]
}

export type vamosXOtraContactParams = {
  localName: string
  address: string
  region?: string
  contact: string
  email: string
  description: string
}
