import React, { useState } from 'react'
import NotifyModal from '@ecommerce/chile-customer-webapp/src/components/ProductDetail/NotifyModal'
import { getAuth } from '@ecommerce/shared'
import loadable from '@loadable/component'
import AddProductButton from '../AddProductButton'
import { DragState, initialDragState, startDragCheck, endDragCheck } from './dragHandler'
import Image from '../../atoms/Image'
import { useShoppingCart, CartStockErrorHandler } from '../../../context/ShoppingCart'
import { Product, ProductCardType } from '../../../types'
import { useLocation } from '../../../context/Location'
import { toCssPrefix } from '../../../utils/format'
import { ProductCardWrapper, cssPrefix as prefix, HeartIcon } from './styled'
import { PromotionLabel } from './PromotionLabel'
import secrets from '../../../config'
import useFavoriteProducts from '../../../hooks/useFavoriteProducts'
import NotifyButton from './NotifyButton'

interface Props {
  product: Product
  type?: ProductCardType
  onNavigate?: () => void
  stockErrorHandler?: CartStockErrorHandler
  bgColor?: string
  className?: string
  showAddButton?: boolean
  showPrice?: boolean
  useDragHandler?: boolean
  hasStock?: boolean
  notify?: boolean
  isLoading?: boolean
}

const { cssPrefix, toPrefix } = toCssPrefix(prefix)

const loadableOptions = {
  ssr: false,
}

const ModalBackground = loadable(() => import('@ecommerce/shared'), {
  resolveComponent: (components) => components.ModalBackground,
  ...loadableOptions,
})

const ProductCard = ({
  type,
  onNavigate,
  product,
  bgColor,
  stockErrorHandler,
  showAddButton = true,
  showPrice = product.hasStock ?? !product.unavailable,
  className = '',
  useDragHandler = true,
  hasStock = product.hasStock ?? !product.unavailable,
  notify = false,
  isLoading = false,
}: Props) => {
  const {
    skuCode,
    thumbnail,
    image,
    title,
    price,
    rawPrice,
    discount,
    unavailable,
    recyclable = false,
    isBundle,
    hasDiscount,
    returnabilityLabelType,
    netContent,
    isCustomizable,
    isDummy,
  } = product

  const [showAddNotifyModal, setShowAddNotifyModal] = useState(false)

  const isAuth = getAuth()
  const {
    state: { byHash },
  } = useShoppingCart()
  const { toCurrency, textByCountry, includesBoxProductFormats } = useLocation()

  const {
    shouldRenderProductCardButton: shouldRenderAddFavoriteButton,
    isFavorite,
    onButtonClick,
  } = useFavoriteProducts({
    sku: Number(skuCode),
  })

  const productAdded = byHash[skuCode || '']
  const quantity = productAdded ? productAdded.quantity : 0
  const [dragState, setDragState] = useState<DragState>(initialDragState)
  const recyclableText = `${
    isBundle && returnabilityLabelType !== 'product'
      ? `INCLUYE ${textByCountry(
          `ENVASE ${netContent && includesBoxProductFormats.includes(netContent) ? 'Y JABA' : ''}`,
          'BOTELLA',
        )}`
      : `RECUERDA TU ${textByCountry('ENVASE', 'BOTELLA')}`
  } `

  const isForPreSale = Number(skuCode) === secrets.JW_PRESALE_SKU

  const onAddNotify = () => {
    setShowAddNotifyModal(true)
  }

  return (
    <ProductCardWrapper
      type={type ?? 'responsive'}
      data-test="product-card"
      onMouseDown={(e) => setDragState(startDragCheck(e))}
      onMouseUp={(e) => {
        setDragState(
          endDragCheck(e, dragState, () => {
            if (onNavigate) onNavigate()
          }),
        )
      }}
      onTouchStart={(e) => e.preventDefault()}
      onClick={() => {
        if (hasStock) {
          return !useDragHandler && onNavigate && !isDummy ? onNavigate() : null
        }
        return null
      }}
      off={unavailable}
      isRecyclable={recyclable}
      isBundle={isBundle && returnabilityLabelType !== 'product'}
      hasDiscount={hasDiscount}
      hasStock={hasStock}
      bgColor={bgColor}
      className={`${cssPrefix} ${className}`}
      hasLabel={recyclable || isCustomizable}
      isDummy={isDummy}
      isLoading
      notify={notify}
      isAuth={isAuth}
    >
      {showAddNotifyModal && (
        <ModalBackground>
          <NotifyModal product={product} onCancel={() => setShowAddNotifyModal(false)} />
        </ModalBackground>
      )}
      {product?.promotion?.catalogDetail && product?.promotion?.catalogDetail.length > 0 && (
        <PromotionLabel product={product} promotion={product?.promotion} isActiveTooltip />
      )}
      <Image className={toPrefix('image')} draggable="false" src={thumbnail ?? image} alt={title} />
      <div className={toPrefix('content')}>
        {recyclable ? <span className={toPrefix('recyclable-badge')}>{recyclableText}</span> : null}
        {isCustomizable && !recyclable ? (
          <span className={toPrefix('custom-label-support-text')}>PERSONALÍZAME</span>
        ) : null}
        {isForPreSale && !recyclable && !isCustomizable ? (
          <span className={toPrefix('custom-label-support-text is-presale')}>PREVENTA</span>
        ) : null}
        <p className={toPrefix('title')}>{title}</p>
        {showPrice && !notify && (
          <div className={toPrefix('discount-wrapper')}>
            {discount && discount > 0 ? <span className={toPrefix('discount-badge')}>{`-${discount}%`}</span> : null}
            <p className={toPrefix('discount-price')}>{hasDiscount ? toCurrency(rawPrice) : null}</p>
          </div>
        )}
        {showPrice && notify && (
          <div className={toPrefix('discount-wrapper')}>
            {discount && discount > 0 ? <span className={toPrefix('discount-badge')}>{`-${discount}%`}</span> : null}
            <p className={toPrefix('discount-price')}>{hasDiscount ? toCurrency(rawPrice) : null}</p>
          </div>
        )}
        <div className={toPrefix('bottom')}>
          {showPrice && !notify && <p className={toPrefix('final-price')}>{toCurrency(price > 1 ? price : 0)}</p>}
          {!showPrice && !notify && <NotifyButton onAddNotify={onAddNotify} isAuth />}
        </div>
        <div className={toPrefix('action-buttons')}>
          {showAddButton && (
            <AddProductButton
              isLoadingNotify={isLoading}
              notify={notify}
              stockErrorHandler={stockErrorHandler}
              typeButton="large"
              product={{ ...product, presale: isForPreSale }}
              className={toPrefix('add-button')}
              quantity={quantity}
              disabled={isDummy || ((!!productAdded?.labelUrl || isForPreSale) && quantity === 1)}
              promotionActivationQuantity={product?.promotion?.activationQuantity}
            />
          )}
          {shouldRenderAddFavoriteButton && !isDummy && hasStock && !notify ? (
            <button
              onClick={onButtonClick}
              onMouseUp={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              className={`${toPrefix('add-favorite')} ${isFavorite && 'is-favorite'}`}
              type="button"
              title="Agregar a Mis Favoritos"
            >
              <HeartIcon />
            </button>
          ) : null}
        </div>
      </div>
    </ProductCardWrapper>
  )
}

export default ProductCard

export { ProductCardWrapper }
