import { useShoppingCart, useLocation, getProductsBySkus, getStoredCity, log, City, slugify } from '@ecommerce/shared'

export const useCartRefresh = () => {
  const {
    state: { byHash },
    addProduct,
    empty: emptyCart,
  } = useShoppingCart()
  const {
    state: { currentCity },
  } = useLocation()

  const refreshCart = async (newCity?: City) => {
    const city = newCity ?? currentCity ?? getStoredCity()
    const cart = { ...byHash }
    const skus = Object.keys(cart)
    if (!city || !skus || !skus.length) return
    const refreshedProducts = await getProductsBySkus(slugify(city?.commerceLayer.stockLocation.name ?? ''), skus)
    emptyCart()
    refreshedProducts.forEach((product) => {
      log.trace('replacing product ', product.title)
      addProduct({ product, quantity: cart[product.skuCode].quantity })
    })
  }
  return { refreshCart }
}
