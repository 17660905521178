import React, { ReactNode } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'

export interface ThemeExtension {
  brand: {
    default: string
    selected: string
    hover: string
    active: string
  }
  brandText: {
    default: string
    selected: string
    hover: string
    active: string
  }
  bodyBackground: {
    default: string
    alt: string
  }
  productView: {
    wrapperBackground: string
    containerBackground: string
  }
  bodyText: string
}

export const defaultTheme: DefaultTheme = {
  borderRadius: '4px',
  sizes: {
    width: {
      desktopMin: '1025px',
    },
  },
  boxShadow: {
    lvlOne: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
    lvlTwo: '0 8px 16px 0 rgba(0, 0, 0, 0.1)',
    lvlThree: '0 12px 24px 0 rgba(0, 0, 0, 0.1)',
    lvlFour: '0 16px 40px 0 rgba(0, 0, 0, 0.1)',
  },
  colors: {
    /* CONTENTFUL THEME COLORS */
    brand: {
      default: '#f00000',
      selected: 'rgb(255,255,255)',
      hover: '#ff4f4f',
      click: '#ff4f4f',
    },
    brandText: {
      default: 'rgb(255,255,255)',
      selected: '#f00000',
      hover: 'rgb(255,255,255)',
      click: 'rgb(255,255,255)',
    },
    bodyBackground: {
      default: 'rgb(255,255,255)',
      alt: 'rgb(232,235,247)',
    },
    productView: {
      wrapperBackground: 'rgb(232,235,247)',
      containerBackground: '#fff',
    },
    bodyText: 'rgb(3,15,28)',

    /* BASE COLORS  */
    primary: '#f00000',
    secondary: '#c00000',
    tertiary: '#ff4f4f',
    success: '#12c269',
    warning: '#f0b63f',
    error: '#9c0000',
    blue: '#0970d0',
    green: '#209B2C',
    orange: '#F28231',
    pink: `#f0a5a5`,
    gold: '#cba138',

    /* NEUTRAL COLORS  */
    black: 'rgb(3,15,28)',
    black75: 'rgba(3, 15, 28, 0.75)',
    black50: 'rgba(3, 15, 28, 0.50)',
    black25: 'rgba(3, 15, 28, 0.25)',
    black10: 'rgba(3, 15, 28, 0.10)',
    blackHigh: 'rgb(53,68,85)',
    blackMedium: 'rgb(94,112,131)',
    blackLow: 'rgb(155,176,199)',

    dark: 'rgb(95,111,134)',
    dark75: 'rgb(95,111,134, 0.75)',
    dark50: 'rgb(95,111,134, 0.50)',
    dark25: 'rgb(95,111,134, 0.25)',
    dark10: 'rgb(95,111,134, 0.10)',
    darkHigh: 'rgb(182,198,216)',
    darkMedium: 'rgb(204,216,229)',
    darkLow: 'rgb(230,236,244)',

    medium: 'rgb(232,235,247)',
    medium75: 'rgba(232,235,247, 0.75)',
    medium50: 'rgba(232,235,247, 0.50)',
    medium25: 'rgba(232,235,247, 0.25)',
    medium10: 'rgba(232,235,247, 0.10)',
    mediumHigh: 'rgb(236,241,247)',
    mediumMedium: 'rgb(241,245,249)',
    mediumLow: 'rgb(247,249,252)',

    light: 'rgb(247,249,252)',
    white: 'rgb(255,255,255)',
    gray: '#9bb0c7',
  },
}

export default ({ children, themeExtension }: { children: ReactNode; themeExtension?: ThemeExtension }) => {
  const theme = { ...defaultTheme, colors: { ...defaultTheme.colors, ...(themeExtension ?? {}) } }

  return <ThemeProvider theme={theme as DefaultTheme}>{children}</ThemeProvider>
}
