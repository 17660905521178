import React from 'react'
import styled from 'styled-components'
import Checkbox from '../../atoms/Checkbox'

type Address = {
  name: string
  address: string
  city: string
}

type Ref = HTMLInputElement
type Props = {
  title: string
  address: Address
  onEdit: () => void
  onCheck: (isCheked: boolean) => void
  isChecked?: boolean
  buttonTitle?: string
  className?: string
  isAddressesPage?: boolean
  isDefault?: boolean
  isDesktop?: boolean
  onDelete?: () => void
}

const Wrapper = styled.div<{ isDefault?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  padding: 14px;
  border: 2px solid ${({ theme, isDefault }) => (!isDefault ? theme.colors.black10 : theme.colors.primary)};
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 100%;
  margin-bottom: 25px;
  position: relative;

  .delete-button {
    background: none;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
    z-index: 1;

    svg {
      width: 100%;
      height: 100%;
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const ContentWrapper = styled.div<{ hasPadding: boolean; isDesktop: boolean }>`
  margin-left: ${({ isDesktop }) => (isDesktop ? '16px' : '25px')};
  position: ${({ hasPadding }) => (hasPadding ? 'relative' : 'static')};

  span {
    display: block;
    line-height: 20px;
    font-size: 15px;
    color: ${({ theme }) => theme.colors.black};
  }

  .AddressCard__title {
    font-weight: bold;
  }
  
  .AddressCard__content {
	  padding-bottom: ${({ hasPadding }) => (hasPadding ? '20px' : '0')};
  }

  p {
    margin: 4px 0;
    font-size: 15px;
  }

  .AddressCard__bottom {
	  display: flex;
	  justify-content: flex-start;
	  align-items: center;
	  margin-top: 10px;
	  
	  button {
		background: none;
		border: none;
		padding: 0;
		cursor: pointer;
		color: ${({ theme }) => theme.colors.primary};
		font-weight: bold;
		
		 &.set-default-button {
			 margin-left: 8px;
			 padding-left: 8px;
			 border-left: 2px solid ${({ theme }) => theme.colors.black10};
			 white-space: nowrap;
			 display: flex;
			justify-content: flex-start;
			align-items: center;
			 
			 svg {
				 width: 22px;
				 height: 20px;
				  fill: ${({ theme }) => theme.colors.black50};
			 }
			 
			 &.is-default {
				 color: ${({ theme }) => theme.colors.black};
				 
				 svg {
					 fill: ${({ theme }) => theme.colors.primary};
				 }
			 }
			 
			 &.is-mobile {
				 position: absolute;
				 left: 10px;
				 top: 12px;
				 font-size: 0;
				 margin: 0;
				 border: none;
				 padding: 0;
				 }
				 
				 svg {
					 width: 23px;
					 height: 23px;
				 }
			 }
		 }
		 
		  &.has-multiple-buttons {
		  	position: absolute;
		  	left: 0;
		  	bottom: 0;
		  
		  	button { 
					font-size: 12px;
			  }
			}
	  }
  }
`

const AddressCard = React.forwardRef<Ref, Props>(
  (
    {
      title,
      className,
      address,
      onEdit,
      onCheck,
      buttonTitle,
      isChecked = false,
      isAddressesPage = false,
      isDefault,
      isDesktop = false,
      onDelete,
    },
    ref,
  ) => (
    <Wrapper isDefault={false} className={`AddressCard ${className}`}>
      {!isAddressesPage ? (
        <Checkbox onChange={({ target }) => onCheck(target.checked)} checked={isChecked} ref={ref} />
      ) : null}
      {isAddressesPage ? (
        <button type="button" className="delete-button" onClick={onDelete}>
          <svg viewBox="0 0 32 32">
            <path d="M26.933 7.467c0.736 0 1.333 0.597 1.333 1.333 0 0.684-0.515 1.247-1.178 1.324l-0.155 0.009h-21.6c-0.736 0-1.333-0.597-1.333-1.333 0-0.684 0.515-1.247 1.178-1.324l0.155-0.009h21.6z" />
            <path d="M24.533 7.467c0.684 0 1.247 0.515 1.324 1.178l0.009 0.155v16.8c0 1.986-1.55 3.609-3.506 3.727l-0.227 0.007h-12c-1.986 0-3.609-1.55-3.727-3.506l-0.007-0.227v-16.8c0-0.736 0.597-1.333 1.333-1.333 0.684 0 1.247 0.515 1.324 1.178l0.009 0.155v16.8c0 0.544 0.407 0.993 0.933 1.058l0.134 0.008h12c0.544 0 0.993-0.407 1.058-0.933l0.008-0.134v-16.8c0-0.736 0.597-1.333 1.333-1.333zM18.533 2.667c1.986 0 3.609 1.55 3.727 3.506l0.007 0.227v2.4c0 0.736-0.597 1.333-1.333 1.333-0.684 0-1.247-0.515-1.324-1.178l-0.009-0.155v-2.4c0-0.544-0.407-0.993-0.933-1.058l-0.134-0.008h-4.8c-0.544 0-0.993 0.407-1.058 0.933l-0.008 0.134v2.4c0 0.736-0.597 1.333-1.333 1.333-0.684 0-1.247-0.515-1.324-1.178l-0.009-0.155v-2.4c0-1.986 1.55-3.609 3.506-3.727l0.227-0.007h4.8z" />
            <path d="M13.733 13.467c0.684 0 1.247 0.515 1.324 1.178l0.009 0.155v7.2c0 0.736-0.597 1.333-1.333 1.333-0.684 0-1.247-0.515-1.324-1.178l-0.009-0.155v-7.2c0-0.736 0.597-1.333 1.333-1.333z" />
            <path d="M18.533 13.467c0.684 0 1.247 0.515 1.324 1.178l0.009 0.155v7.2c0 0.736-0.597 1.333-1.333 1.333-0.684 0-1.247-0.515-1.324-1.178l-0.009-0.155v-7.2c0-0.736 0.597-1.333 1.333-1.333z" />
          </svg>
        </button>
      ) : null}
      <ContentWrapper isDesktop={isDesktop} hasPadding={isAddressesPage && isDesktop}>
        <div className="AddressCard__title">
          <span>{title}</span>
        </div>
        <div className="AddressCard__content">
          <p>{address.name}</p>
          <p>{address.address}</p>
          <p>{address.city}</p>
        </div>
        <div className={`AddressCard__bottom ${isAddressesPage && isDesktop ? 'has-multiple-buttons' : ''}`}>
          <button className="edit-button" type="button" title={buttonTitle} onClick={onEdit}>
            Editar
          </button>
        </div>
      </ContentWrapper>
    </Wrapper>
  ),
)

export default AddressCard
