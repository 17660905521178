import React from 'react'
import { ProductCard, Product, CartStockErrorHandler } from '@ecommerce/shared'
import styled from 'styled-components'
import Carousel from 'react-multi-carousel'
import { ZIndex } from '../../../utils/Constants'
import { breakpoints } from '../../../utils/breakpoints'
import { ChevronRightIcon } from './ArrowIcon'
import Anchor from '../../atoms/Anchor'
import 'react-multi-carousel/lib/styles.css'

const Wrapper = styled.div`
  position: relative;

  .product-list {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 22px 29px 11px;
    z-index: ${ZIndex.low};

    &-scroll {
      column-gap: 12px;
      display: grid;
      grid-auto-flow: column;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > svg {
        fill: ${(props) => props.theme.colors.black};
        width: 24px;
      }
    }
    &-header-action {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .show-more {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    width: 200px;
    padding-bottom: 75px;
    &-icon {
      background-color: ${(props) => props.theme.colors.primary};
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }

      > svg {
        fill: ${(props) => props.theme.colors.white};
        width: 26px;
      }
    }
    &-text {
      width: 125px;
      height: 53px;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.1px;
      text-align: center;
      color: ${(props) => props.theme.colors.black};
    }
  }

  .bg {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    &-first-section {
      background: ${(props) => props.theme.colors.white};
      height: 37%;
    }

    &-second-section {
      background: ${(props) => props.theme.colors.medium};
      height: 63%;
    }
  }

  .carousel-container {
    .react-multiple-carousel__arrow--right {
      right: 8px;
      min-width: 35px;
      min-height: 35px;
    }
    .react-multiple-carousel__arrow--left {
      left: 8px;
      min-width: 35px;
      min-height: 35px;
    }
    .react-multiple-carousel__arrow {
      background: rgba(0, 0, 0, 0.4);
      opacity: 0.8;
    }
    .react-multiple-carousel__arrow:hover {
      background-color: #f40002;
      opacity: 0.8;
    }
    .react-multi-carousel-track {
      touch-action: none;
    }
  }

  @media (${breakpoints.desktop.min}) {
    .product-list {
      padding: 20px 105px 32px;

      &-scroll {
        column-gap: 42px;
      }
    }
    .show-more {
      padding-bottom: 130px;
    }
  }
`
interface CustomProduct {
  isBundle?: boolean
  onNavigate?: () => void
}
interface Props {
  title: string
  products: (Product & CustomProduct)[]
  type?: 'small' | 'large'
  removeArrowOnDeviceType?: string[]
  responsive: any
  onNavigateShowMore?: () => void
  hideNavigation?: boolean
  stockErrorHandler?: CartStockErrorHandler
}

const ProductList = (props: Props) => {
  const {
    title,
    products,
    type,
    responsive,
    removeArrowOnDeviceType,
    onNavigateShowMore,
    hideNavigation = false,
    stockErrorHandler,
  } = props

  return (
    <Wrapper>
      <div className="bg">
        <div className="bg-first-section" />
        <div className="bg-second-section" />
      </div>
      <div className="product-list">
        <div className="product-list-header">
          <h2>{title}</h2>
          {!hideNavigation && (
            <Anchor className="product-list-header-action" onClick={onNavigateShowMore}>
              <span>Ver todo</span>
              <ChevronRightIcon />
            </Anchor>
          )}
        </div>
        <Carousel
          swipeable
          draggable
          responsive={responsive}
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={removeArrowOnDeviceType}
          itemClass="carousel-item-padding-40-px"
        >
          {products.map((product) => (
            <ProductCard
              product={product}
              key={product.skuCode}
              type={type || 'small'}
              onNavigate={product.onNavigate}
              stockErrorHandler={stockErrorHandler}
            />
          ))}
          {!hideNavigation && (
            <div className="show-more">
              <span className="show-more-text">Ver toda la categoría</span>
              <Anchor onClick={onNavigateShowMore}>
                <span className="show-more-icon">
                  <ChevronRightIcon />
                </span>
              </Anchor>
            </div>
          )}
        </Carousel>
      </div>
    </Wrapper>
  )
}

export default ProductList
