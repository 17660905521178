import React from 'react'
import styled from 'styled-components'
import { breakpoints, toCssPrefix, getStoredCity } from '@ecommerce/shared'
import { Link } from 'gatsby'
import FooterCollapse from './FooterCollapse/FooterCollapse'
import { useFooterQuery } from './useFooterQuery'

const { cssPrefix, toPrefix, toPrefixArray } = toCssPrefix('Footer__')

const FooterWrapper = styled.footer`
  display: block;
  background: ${(props) => props.theme.colors.brand.default};
  color: ${(props) => props.theme.colors.white};
  width: 100%;
  padding: 14px 24px 16px;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'list'
    'logo'
    'social'
    'info';
  .${cssPrefix} {
    &list {
      display: none;
    }
    &collapse {
      grid-area: list;
    }
    &list-content {
      padding-top: 14px;
      display: flex;
      flex-direction: column;
    }
    &list-option {
      all: unset;
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.06px;
      margin: 6px 0;
    }
    &content-logo {
      grid-area: logo;
      width: 100%;
      padding: 25px 0;
      display: flex;
      justify-content: center;
      a {
        all: unset;
        cursor: pointer;
      }
    }
    &logo {
      width: 146px;
      height: fit-content;
    }
    &content-social {
      grid-area: social;
      display: flex;
      align-items: center;
      flex-direction: column;
      span {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.1px;
        font-weight: 600;
        margin-bottom: 12px;
      }
    }
    &social {
      a {
        margin: 0 9px;
        img {
          width: 30px;
        }
      }
      a :first-child {
        margin-left: 0;
      }
      a :last-child {
        margin-right: 0;
      }
    }
    &content-info {
      grid-area: info;
      font-size: 11px;
      line-height: 15px;
      letter-spacing: 0.055px;
      text-align: center;
      margin-top: 42px;
      span {
        margin: 0 5px;
        font-weight: 400;
        a {
          all: unset;
          cursor: pointer;
          font-weight: 600;
          margin: 0 5px;
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    padding: 32px 0 12px;
    grid-template-columns: 0.4fr 1fr 0.4fr;
    grid-template-areas:
      'logo list social'
      'info info info';
    .${cssPrefix} {
      &collapse {
        display: none;
      }
      &list {
        all: unset;
        grid-area: list;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      &list-wrapper {
        all: unset;
      }
      &list-title {
        font-weight: 600;
      }
      &content-logo {
        align-items: initial;
        padding: 0;
        justify-content: center;
      }
      &content-social {
        align-items: flex-start;
        width: fit-content;
        margin: 0 auto;
      }
      &content-info {
        font-size: 15px;
        display: flex;
        align-items: end;
        justify-content: center;
      }
    }
    br {
      display: none;
    }
  }
`

interface OptionLink {
  href: string
  label: string
}

export const Footer = () => {
  const data = useFooterQuery()
  const currentCity = getStoredCity()

  const getLinkOption = (option: OptionLink) => {
    return option.href.startsWith('/') || option.href.startsWith('https') ? (
      <a target="_blank" rel="noreferrer" className={toPrefix('list-option')} href={option.href} key={option.href}>
        {option.label}
      </a>
    ) : (
      <Link className={toPrefix('list-option')} to={`/${currentCity?.slug}/${option.href}`} key={option.href}>
        {option.label}
      </Link>
    )
  }

  return (
    <FooterWrapper data-testid="footer">
      <div className={toPrefix('collapse')}>
        {data?.linksList.map((list) => (
          <FooterCollapse label={list.title} key={list.title}>
            <div className={toPrefix('list-content')}>{list.items.map((option) => getLinkOption(option))}</div>
          </FooterCollapse>
        ))}
      </div>
      <div className={toPrefix('content-logo')}>
        <a target="_blank" rel="noreferrer" href="/">
          <img alt="logo" src={data?.logo.file.url} className={toPrefix('logo')} />
        </a>
      </div>
      <ul className={toPrefix('list')}>
        {data?.linksList.map((list) => (
          <li className={toPrefix('list-wrapper')} key={list.title}>
            <div className={toPrefix('list-title')}>{list.title}</div>
            <div className={toPrefix('list-content')}>{list.items.map((option) => getLinkOption(option))}</div>
          </li>
        ))}
      </ul>
      <div className={toPrefix('content-social')}>
        <span>Síguenos</span>
        <div className={toPrefix('social')}>
          {data?.instagramUrl && (
            <a target="_blank" rel="noreferrer" href={data?.instagramUrl}>
              <img
                src="https://images.ctfassets.net/16npdkkoi5mj/1eM4rBG1wW77VLJ4VdPcRh/3573e8985a259d0fd591c140ff4f08df/instagram.svg"
                alt="icon-instagram"
              />
            </a>
          )}
          {data?.facebookUrl && (
            <a target="_blank" rel="noreferrer" href={data?.facebookUrl}>
              <img
                src="https://images.ctfassets.net/16npdkkoi5mj/1sf3n4E4z8c76x0I6qQL1k/dedeaf64ca41147c8cd44aa70e9b0d5d/facebook.svg"
                alt="icon-facebook"
              />
            </a>
          )}
          {data?.youtubeUrl && (
            <a target="_blank" rel="noreferrer" href={data?.youtubeUrl}>
              <img
                src="https://images.ctfassets.net/16npdkkoi5mj/6XRGnSj4AIUOr1ZYJ6jbNh/c05364cae3a28eb8a84b872ebed76957/youtube.svg"
                alt="icon-instagram"
              />
            </a>
          )}
          {data?.twitterUrl && (
            <a target="_blank" rel="noreferrer" href={data?.twitterUrl}>
              <img
                src="https://images.ctfassets.net/16npdkkoi5mj/2E1uzMpcO6MSQWaOu0hWlA/5dfb7499d6477cc90aec63503628b212/twitter.svg"
                alt="icon-facebook"
              />
            </a>
          )}
        </div>
      </div>
      <div className={toPrefix('content-info')}>
        <span>{data?.copyrightText}</span>
        <br />
        <span>
          <a target="_blank" rel="noreferrer" href={data?.termsSlug}>
            Términos y Condiciones
          </a>
          &#160;|&#160;
          <a target="_blank" rel="noreferrer" href={data?.privacyPolicies}>
            Políticas de privacidad
          </a>
        </span>
      </div>
    </FooterWrapper>
  )
}
