import { Country } from '@ecommerce/shared'

const { version } = require('../../package.json')

export default {
  ALGOLIA_APP_ID: process.env.GATSBY_ALGOLIA_APP_ID || '',
  ALGOLIA_API_KEY: process.env.GATSBY_ALGOLIA_API_KEY || '',
  ALGOLIA_INDEX_NAME: process.env.GATSBY_ALGOLIA_INDEX_NAME || '',
  GATSBY_GOOGLE_MAPS_URL_EMBED: process.env.GATSBY_GOOGLE_MAPS_URL_EMBED || '',
  SHIPPING_COST: Number(process.env.GATSBY_SHIPPING_COST || 0),
  COUNTRY: process.env.GATSBY_COUNTRY as Country,
  MIN_RAW_TOTAL: process.env.GATSBY_MIN_RAW_TOTAL || 10000,
  GATSBY_GOOGLE_MAPS_URL: process.env.GATSBY_GOOGLE_MAPS_URL || '',
  EMBONOR_SERVERLESS_SIGNUP_SERVICE: process.env.GATSBY_EMBONOR_SERVERLESS_SIGNUP_SERVICE,
  GOOGLE_RECAPTCHAV3_SITE_KEY: process.env.GATSBY_GOOGLE_RECAPTCHAV3_SITE_KEY || '',
  SKU_LIST_EMPTY_CART: process.env.GATSBY_SKU_LIST_EMPTY_CART || '',
  SKU_LIST_PROMOTIONS_HOME: process.env.GATSBY_SKU_LIST_PROMOTIONS_HOME || '',
  SKU_LIST_RETURNABILITY_HOME: process.env.GATSBY_SKU_LIST_RETURNABILITY_HOME || '',
  APP_VERSION: version,
  MULTIPLE_REGIONS_CITIES: process.env.GATSBY_MAPS_MULTIPLE_REGIONS || '',
  DEVICE_FINGERPRINT_JS_URL: process.env.GATSBY_DEVICE_FINGERPRINT_JS_URL || 'https://h.online-metrix.net/fp/tags.js',
  DEVICE_FINGERPRINT_ORG_ID: process.env.GATSBY_DEVICE_FINGERPRINT_ORG_ID || 'k8vif92e',
  SECURE_ACCEPTANCE_HOSTED_CHECKOUT_URL:
    process.env.GATSBY_SECURE_ACCEPTANCE_HOSTED_CHECKOUT_URL || 'https://secureacceptance.cybersource.com/pay',
  BO_CITIES: process.env.GATSBY_BOLIVIA_CITIES || '',
  CHECKOUT_INACTIVITY_ALERT: process.env.GASTBY_CHECKOUT_INACTIVITY_ALERT_TIME || 4.5,
  CHECKOUT_INACTIVITY_REDIRECT: process.env.GATSBY_CHECKOUT_INACTIVITY_REDIRECT_TIME || 5,
  GATSBY_PREHOME_REDIRECT_URL: process.env.GATSBY_PREHOME_REDIRECT_URL || null,
  SPACE_ID: process.env.GATSBY_SPACE_ID || '',
  CONTENTFUL_ACCESS_TOKEN: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN || '',
  MIN_GIFT_CARD_CODE_LENGTH: Number(process.env.MIN_GIFT_CARD_CODE_LENGTH ?? 16),
  INCLUDES_BOX_PRODUCT_FORMATS: process.env.GATSBY_INCLUDES_BOX_PRODUCT_FORMATS ?? '',
  TAX_MULTIPLIER: Number(process.env.GATSBY_TAX_MULTIPLIER ?? '0.19'),
  JW_LABEL_STUDIO_URL: process.env.GATSBY_JW_LABEL_STUDIO_URL || '',
  JW_LABEL_STUDIO_TOKEN: process.env.GATSBY_JW_LABEL_STUDIO_TOKEN || '',
  DISABLE_ERROR_BOUNDARY: process.env.GATSBY_DISABLE_ERROR_BOUNDARY === 'true',
  BEETRACK_API_KEY: process.env.GATSBY_BEETRACK_API_KEY || '',
  JW_PRESALE_SKU: -1,
  mainAlcoholCategoryName: 'LICORES',
  alcoholCategories: ['RON', 'PISCO', 'VODKA', 'COCTEL', 'WHISKY', 'CREMAS', 'GIN', 'TEQUILA', 'HARD ZELTZER'],
  mainWineCategorySlug: 'Vinos y Espumantes',
  mainWineCategoryName: 'VINO',
  wineCategories: ['VINO', 'ESPUMANTE'],
  documentTypes: [
    {
      value: 'ci',
      label: 'CI',
    },
    {
      value: 'nit',
      label: 'NIT',
    },
    {
      value: 'pasaporte',
      label: 'Pasaporte',
    },
    {
      value: 'otros',
      label: 'Otros',
    },
    {
      value: 'carnet_extranjeria',
      label: 'Carnet Extranjería',
    },
  ],
}
