import { Product, ProductCart, ActionApplyCoupon, Country, ActionRemoveCoupon } from '../../types'
import { ShoppingDispatch, ShoppingCartState } from './context'
import { deleteLineItem } from '../../services/BFF/lineItems'
import { applyCoupon, removeCoupon, removeGiftcard, applyGiftCard, getSkuStock } from '../../services/BFF'
import { getStoredCity, CartStockErrorHandler } from '../../..'
import { sendMessageToSentry, ErrorLevel, ErrorSource } from '../../../../../apps/cl-customer-webapp/src/utils/sentry'
import config from '../../config'

const { COUNTRY, MIN_GIFT_CARD_CODE_LENGTH } = config

export type StockErrorHandler = (props: { product: ProductCart; stock: number }) => void
const emptyProduct: ProductCart = {
  skuCode: '0',
  image: '',
  title: '',
  price: 0,
  rawPrice: 0,
  hasDiscount: false,
  discount: 0,
  recyclable: false,
  unavailable: false,
  isBundle: false,
  brandName: '',
  categoryName: '',
  packing: '',
  size: '',
  slugLocation: '',
  quantity: 0,
  lineItemId: '',
  labelId: '',
  labelUrl: '',
  presale: false,
}

// Formatter
export const productToStateFormat = (product: Product & { quantity?: number; lineItemId?: string }) =>
  Object.entries(product)
    .filter(([key]) => Object.keys(emptyProduct).includes(key))
    .reduce<ProductCart>((obj, [key, value]) => ({ ...obj, ...{ [key]: value ?? null } }), emptyProduct)

export function actionSetItemStock(dispatch: ShoppingDispatch) {
  return (skuCode: string, stock: number) => {
    dispatch({ type: 'SET_ITEM_STOCK', skuCode, stock })
  }
}

export function actionRemoveProduct(dispatch: ShoppingDispatch) {
  return (skuCode: string) =>
    dispatch({
      type: 'REMOVE_PRODUCT',
      skuCode,
    })
}

export function actionAddProduct(dispatch: ShoppingDispatch) {
  return (state: ShoppingCartState) => async (
    params: { product: Product; quantity?: number },
    stockErrorHandler?: CartStockErrorHandler,
  ) => {
    const { product } = params
    const { byHash } = state
    const quantity = params.quantity ?? 1
    const storedProduct = byHash[product.skuCode] || undefined
    if (
      storedProduct &&
      storedProduct.stock &&
      storedProduct.quantity + quantity > storedProduct.stock &&
      stockErrorHandler
    ) {
      stockErrorHandler({ product: storedProduct, stock: storedProduct.stock })
      dispatch({
        type: 'SET_PRODUCT_QUANTITY',
        skuCode: storedProduct.skuCode,
        quantity: storedProduct.stock,
      })
      return
    }

    const maxQuantity = product.isBundle ? state.maxCartLineBundleQuantity : state.maxCartLineProductQuantity

    if (maxQuantity && (quantity > maxQuantity || (storedProduct && storedProduct.quantity + quantity > maxQuantity))) {
      dispatch({
        type: 'SET_PRODUCT_QUANTITY',
        skuCode: storedProduct.skuCode,
        quantity: maxQuantity,
      })
      return
    }

    dispatch({ type: 'ADD_PRODUCT', product: productToStateFormat(product), quantity })
    if (!storedProduct || storedProduct.stock === undefined) {
      const city = getStoredCity()
      if (
        city &&
        city.commerceLayer.market &&
        city.commerceLayer.market.number &&
        city.commerceLayer.stockLocation &&
        city.commerceLayer.stockLocation.number
      ) {
        const stockLocation = city.commerceLayer.stockLocation.number

        getSkuStock(product.skuCode, COUNTRY, stockLocation)
          .then(({ quantity: stock }) => {
            if (stock) actionSetItemStock(dispatch)(product.skuCode, stock)
            if (stock === 0 && stockErrorHandler) {
              stockErrorHandler({
                product: { ...product, quantity, discount: product.discount || 0 },
                stock,
              })
              actionRemoveProduct(dispatch)(product.skuCode)
            }
          })
          .catch((e) => {
            sendMessageToSentry({
              message: `Error fetching max stock for sku ${product.skuCode}`,
              page: 'context - actionAddProduct',
              source: ErrorSource.CLayer,
              level: ErrorLevel.Warning,
              tags: { 'incident.type': 'stock' },
              metadata: {
                error: e?.response ?? e,
                product,
                quantity_to_add: quantity,
              },
            })
          })
      }
    }
  }
}

export function actionSubtractProduct(dispatch: ShoppingDispatch) {
  return (skuCode: string, quantity?: number) =>
    dispatch({
      type: 'SUBTRACT_PRODUCT',
      skuCode,
      quantity,
    })
}

export function actionReplaceState(dispatch: ShoppingDispatch) {
  return (state: ShoppingCartState) => {
    dispatch({ type: 'REPLACE_STATE', state })
  }
}

export function actionEmpty(dispatch: ShoppingDispatch) {
  return () => {
    dispatch({ type: 'EMPTY' })
  }
}

export function actionReset(dispatch: ShoppingDispatch) {
  return () => {
    dispatch({ type: 'RESET' })
  }
}

export function actionRemoveProductAsync(dispatch: ShoppingDispatch) {
  return async ({ skuCode, lineItemId }: ProductCart) => {
    if (lineItemId) {
      await deleteLineItem({ lineItemId })

      return dispatch({ type: 'REMOVE_PRODUCT_ASYNC', skuCode })
    }
  }
}

export const actionApplyCouponOrGiftCard: (
  dispatch: ShoppingDispatch,
  state: ShoppingCartState,
) => ActionApplyCoupon = (dispatch, { promotionCouponsWhitelist }) => {
  return async ({ code, orderId, country }) => {
    const city = getStoredCity()
    const market = city?.commerceLayer.market?.number

    const handler =
      promotionCouponsWhitelist.includes(code) || code.length < MIN_GIFT_CARD_CODE_LENGTH ? applyCoupon : applyGiftCard

    if (market) {
      const { discountedAmount, isRaw, adjustment } = await handler({ code, orderId, country, market })

      dispatch({ type: 'APPLY_COUPON', amount: discountedAmount, code, isRawDiscount: isRaw ?? false, adjustment })
      return { discountedAmount, isRaw, adjustment }
    }
  }
}

export const actionRemoveCouponOrGiftCard: (
  dispatch: ShoppingDispatch,
  state: ShoppingCartState,
) => ActionRemoveCoupon = (dispatch, { promotionCouponsWhitelist }) => {
  return async ({ orderId, country, code }) => {
    const handler =
      promotionCouponsWhitelist.includes(code) || code.length < MIN_GIFT_CARD_CODE_LENGTH
        ? removeCoupon
        : removeGiftcard
    await handler({ orderId, country })
    return dispatch({ type: 'REMOVE_COUPON' })
  }
}

export function actionSetCartId(dispatch: ShoppingDispatch) {
  return (cartId: string) => {
    dispatch({ type: 'SET_CART_ID', cartId })
  }
}
