import { AxiosResponse } from 'axios'
import { CommerceLayerAddress, FormattedAddress } from '../../types'
import {
  CLObject,
  CLPaymentMethodListResponse,
  CLShippingMethodListResponse,
  ResourceType,
  CLOrder,
  OrderStatus,
} from './types'
import { City } from '../../..'
import fetch, { headers } from './fetch'

const formatAddress = (
  { id, attributes }: CommerceLayerAddress,
  customerAddresses?: CommerceLayerAddress[],
): FormattedAddress => ({
  id,
  cityId: attributes.metadata.cityId,
  city: attributes.city,
  cityName: attributes.metadata.cityName,
  region: attributes.zip_code,
  isFavorite: attributes.metadata.favorite,
  shipping_address: attributes.line_1,
  shipping_instructions: attributes.notes,
  name: attributes.full_name,
  shipping_phone: attributes.phone,
  shipping_name: attributes.metadata.alias,
  shipping_number: attributes.line_2,
  customer_address: customerAddresses?.filter((address) => address.relationships?.address.data.id === id)[0].id,
  geocode: {
    latitude: attributes.lat,
    longitude: attributes.lng,
  },
})

const getFavoriteAddressId = (addresses: FormattedAddress[]): string | undefined => {
  let result

  addresses.forEach((address) => {
    if (address.isFavorite) result = address.id
  })

  return result
}

const getAvailableAddresses = (currentCity: number, addresses: FormattedAddress[]): [FormattedAddress[], boolean] => {
  const valid = addresses.filter(({ cityId }) => cityId === currentCity)
  const hasAvailable = addresses.length !== 0 ? valid.length !== 0 : false

  return [valid, hasAvailable]
}

const sort = (addresses: FormattedAddress[]): FormattedAddress[] =>
  addresses.sort((a) => {
    if (a.isFavorite) return -1

    return 1
  })

export { formatAddress, getFavoriteAddressId, getAvailableAddresses, sort }

export type IncludedDicts = Record<ResourceType, Record<string, CLObject>>

export function buildIncludedDictionaries(included: CLObject[] | undefined): IncludedDicts {
  const includedDicts: IncludedDicts = Object.values(ResourceType).reduce(
    (dict, type) => ({ ...dict, [type]: {} }),
    {} as IncludedDicts,
  )
  if (included) {
    included.forEach((resource) => {
      includedDicts[resource.type][resource.id] = resource
    })
  }
  return includedDicts
}

export const addShippingAndPaymentMethod = async (city: City) => {
  const paymentMethodsResult: AxiosResponse<CLPaymentMethodListResponse> = await fetch(
    `api/payment_methods/?filter[q][reference_eq]=${city.id}`,
    { headers },
  )
  const shippingMethodsResult: AxiosResponse<CLShippingMethodListResponse> = await fetch(
    `api/shipping_methods/?filter[q][reference_eq]=${city.id}`,
    { headers },
  )
  return {
    ...city,
    commerceLayer: {
      ...city.commerceLayer,
      paymentMethodId: paymentMethodsResult.data.data[0].id,
      shippingMethodId: shippingMethodsResult.data.data[0].id,
    },
  }
}

const getOrderDateByStatus = (order: CLOrder): string => {
  switch (order.attributes.status) {
    case OrderStatus.APPROVED:
      return order.attributes.approved_at

    case OrderStatus.PLACED:
      return order.attributes.placed_at

    default:
      return order.attributes.updated_at
  }
}

export const filterOrders = (orders: CLOrder[], maxAge: number): CLOrder[] => {
  const filterStatus = [OrderStatus.APPROVED, OrderStatus.PLACED, OrderStatus.CANCELLED]

  const validOrders = orders
    .filter((order) => {
      if (filterStatus.includes(order.attributes.status)) {
        const orderDate = getOrderDateByStatus(order)
        const now = new Date().getTime()
        const orderDateTime = new Date(orderDate).getTime()
        const orderDaysOfDifference = (now - orderDateTime) / (1000 * 3600 * 24)

        return orderDaysOfDifference <= maxAge
      }

      return false
    })
    .sort((a, b) => {
      return new Date(b.attributes.updated_at).getTime() - new Date(a.attributes.updated_at).getTime()
    })

  return validOrders
}
