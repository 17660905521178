import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import {
  Button,
  Product,
  getAuth,
  TextField,
  StoredCustomer,
  getStoredCity,
  useAuth,
  saveUserAvailabilitySkus,
  Image,
  toCurrency,
  breakpoints,
} from '@ecommerce/shared'

import styled from 'styled-components'
import { Icon } from '../Icon/Icon'
import { modalMountAnimation } from './utils'

interface NotifyModalProps {
  isSuccess?: boolean
  isLoading?: boolean
}

const Wrapper = styled.div<NotifyModalProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${modalMountAnimation} 0.1s linear forwards;

  .inner {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius};
    width: 341px;
    min-height: 320px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 27px;
  }

  .top-modal {
    padding: 29px 30.5px 0 30.5px;
  }

  .close {
    background: none;
    border: none;
    padding: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 30px;
    right: 30px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    max-width: 250px;
  }

  .product-card {
    display: flex;
    flex-direction: row;
    width: 341px;
    height: 175px;
    padding: 15px 16px 15px 12px;
    margin: 0 auto;

    &-image {
      width: 145px;
      min-width: 145px;
      height: 145px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 0 0 13px;
      width: calc(100% - 145px);
    }

    &-price {
      color: ${({ theme }) => theme.colors.black};
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      letter-spacing: -0.25px;
      margin: 0 5px;
    }

    &-title {
      color: ${({ theme }) => theme.colors.dark};
      font-size: 13px;
      font-weight: bold;
      line-height: 18px;
      letter-spacing: -0.06px;
      height: 42px;
      margin: 0;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &-button {
      width: 155px;
    }
  }

  .buttons {
    padding: 0 30.5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 27px;

    &-continue {
      background: ${({ isSuccess }) => isSuccess && '#F00000'};
    }

    button {
      padding: 0 25px;
    }
  }

  .result-indicator {
    font-weight: 400;
    display: flex;
    gap: 15px;
    margin: 0 30.5px;

    p {
      font-size: 16px;
      margin: 0;
      width: auto;
    }

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.dark};
      display: block;
      width: 100%;
    }

    svg {
      width: 24px;
      height: 24px;

      &.is-success {
        fill: ${({ theme }) => theme.colors.success};
      }

      &.is-error {
        fill: ${({ theme }) => theme.colors.error};
      }
    }
  }

  .result-auth {
    margin: 0 30.5px;
    font-size: 17px;
    color: ${({ theme }) => theme.colors.black};
    span:nth-child(2) {
      font-weight: bold;
    }
  }

  .guest-form {
    margin: 0 30.5px;

    &-input {
      margin-bottom: 18px;
    }
  }

  @media (min-width: 668px) {
    .inner {
      min-width: 435px;
      width: 435px;
    }

    .top-modal {
      padding: 29px 47.5px 0 47.5px;
    }

    .guest-form,
    .result-auth,
    .result-indicator {
      margin: 0 44px;
    }

    .title {
      max-width: unset;
    }

    .buttons {
      flex-direction: row;
      justify-content: center;
      gap: 30px;

      button {
        min-width: 140px;
      }
    }
  }
`

const emailSchema = yup.string().email('Debes ingresar un email valido')
const nameSchema = yup.string().matches(/^[a-zA-Z\u00C0-\u017F\u0027\s]+$/, 'Debe ingresar un nombre válido')

type FormValue = { value?: string; errorMessage?: string }

type Props = { product: Product; onCancel?: () => void }

const NotifyModal = ({ product, onCancel }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const [formValues, setFormValues] = useState<{ email?: FormValue; name?: FormValue }>()

  const isAuth = getAuth()
  const { state: authState, setAvailabilities } = useAuth()
  const currentCity = getStoredCity()

  const canSubmit = !isAuth
    ? !!formValues?.email?.value && !formValues?.email?.errorMessage && !!formValues?.name?.value
    : true

  const validateEmail = async () => {
    try {
      await emailSchema.validate(formValues?.email?.value)

      return true
    } catch (error) {
      setFormValues((prev) => ({ ...prev, email: { ...(prev?.email ?? {}), errorMessage: error?.message } }))

      return false
    }
  }

  const validateName = async () => {
    try {
      await nameSchema.validate(formValues?.name?.value)
      return true
    } catch (error) {
      setFormValues((prev) => ({ ...prev, name: { ...(prev?.name ?? {}), errorMessage: error?.message } }))
      return false
    }
  }

  const getPayload = (): StoredCustomer => {
    const customerName = isAuth ? `${authState.firstName} ${authState.lastName}` : formValues?.name?.value
    const customerEmail = isAuth ? authState.email : formValues?.email?.value
    const clayerId = authState.ownerId.length > 0 ? authState.ownerId : undefined

    if (!currentCity?.slug || !customerName || !customerEmail) {
      throw new Error('city, customerName and customerEmail are required')
    }

    return {
      clayerId,
      slugLocation: currentCity?.slug,
      name: customerName,
      email: customerEmail,
      favoriteSkus: authState.favoriteSkus,
      skusAvailabilityList: [Number(product?.skuCode)],
    }
  }

  const onAdd = async () => {
    try {
      if (!canSubmit) return
      if (isSuccess) {
        return onCancel ? onCancel() : null
      }

      if (!isAuth) {
        const emailIsValid = await validateEmail()
        const nameIsValid = await validateName()

        if (!nameIsValid || !emailIsValid) return
      }

      setIsLoading(true)
      setIsSuccess(false)
      setIsError(false)

      await saveUserAvailabilitySkus(getPayload())

      if (isAuth) {
        setAvailabilities([...new Set([...(authState?.availabilitySkus ?? []), Number(product?.skuCode)])])
      }

      setIsSuccess(true)
    } catch (error) {
      setIsSuccess(false)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isAuth) {
      onAdd()
    }
  }, [])

  const authMessage = () => {
    return (
      <div>
        <span>Te enviaremos un correo a </span>
        <span>{authState.email}</span>
        <span> cuando este producto vuelva a estar disponible en el sitio.</span>
      </div>
    )
  }

  const [resultHeading, resultText] = React.useMemo(() => {
    if (!isAuth && isSuccess) {
      return ['Ya tenemos tus datos', 'Te enviaremos un correo cuando este producto esté nuevamente disponible']
    }

    if (isAuth && isSuccess) {
      return ['', authMessage()]
    }

    if (isError) {
      return ['Ha habido un error agregando el producto', 'Por favor intenta de nuevo']
    }

    return [null, null]
  }, [isSuccess, isAuth, isError])

  const renderSubmitButton = () => {
    let text: string
    if (isSuccess) text = 'Seguir comprando'
    else text = 'Continuar'

    return (
      <Button
        className="buttons-continue"
        onClick={onAdd}
        isLoading={isLoading}
        isDisabled={isLoading || !product || !canSubmit}
      >
        {text}
      </Button>
    )
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value } = e.currentTarget

    setFormValues((prev) => ({ ...(prev ?? {}), [inputName]: { value } }))
  }

  const onPrevent = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <Wrapper
      isLoading={isLoading}
      isSuccess={isSuccess}
      onMouseUp={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => onPrevent(e)}
    >
      <div className="inner">
        <div className="top-modal">
          <button
            onClick={() => (onCancel ? onCancel() : null)}
            disabled={isLoading}
            type="button"
            title="Cerrar"
            className="close"
          >
            <Icon iconId="close" />
          </button>
          <p className="title">Notificarme cuando esté disponible</p>
        </div>
        {!isAuth && (isSuccess || isError) ? (
          <div className="result-indicator">
            <div>
              <Icon
                className={`is-${isSuccess ? 'success' : 'error'}`}
                iconId={isSuccess ? 'success' : 'error_outline'}
              />
            </div>
            <div>
              <p>{resultHeading}</p>
              <span>{resultText}</span>
            </div>
          </div>
        ) : null}
        {product && (
          <div className="product-card">
            <Image className="product-card-image" alt={product.title} src={product.thumbnail ?? product.image} />
            <div className="product-card-content">
              <p className="product-card-title">{product.title}</p>
              <div className="product-card-bottom">
                <p className="product-card-price">{toCurrency(product.price > 1 ? product.price : 0)}</p>
                <Button
                  className="product-card-button"
                  onMouseUp={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                  disabled
                  isDisabled
                >
                  Agregar
                </Button>
              </div>
            </div>
          </div>
        )}
        {!isAuth && (
          <div className="guest-form">
            <TextField
              onChange={onInputChange}
              className="guest-form-input"
              placeholder="Nombre"
              name="name"
              errorMessage={formValues?.name?.errorMessage}
              status={formValues?.name?.errorMessage ? 'error' : undefined}
              disabled={isSuccess}
              autoComplete="off"
            />
            <TextField
              onChange={onInputChange}
              className="guest-form-input"
              placeholder="Correo electrónico"
              name="email"
              errorMessage={formValues?.email?.errorMessage}
              status={formValues?.email?.errorMessage ? 'error' : undefined}
              disabled={isSuccess}
              autoComplete="off"
              autoCapitalize="nope"
            />
          </div>
        )}
        {isAuth && isSuccess ? <div className="result-auth">{resultText}</div> : null}
        <div className="buttons">
          {renderSubmitButton()}

          {!isAuth && !isSuccess && (
            <Button
              onClick={() => (onCancel && !isLoading ? onCancel() : null)}
              isDisabled={isLoading}
              btnType={!isAuth && isSuccess ? 'primary' : 'secondary'}
            >
              {isSuccess ? 'Cerrar' : 'Cancelar'}
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default NotifyModal
