import {
  City,
  getStoredCity,
  setStoredCity,
  getAuth,
  getAccessToken,
  setAccessToken,
  setAccessTokenExpirationTimestamp,
  useAuth,
  useShoppingCart,
  addShippingAndPaymentMethod,
  getOrderId,
  useFirebase,
  useLocation,
  getCustomerEmail,
  setAnonymousToken,
  getAnonymousToken,
  getOwnerId,
  signOutRedirect,
  log,
} from '@ecommerce/shared'
import { useEffect } from 'react'
import { guestSignIn } from '@ecommerce/shared/src/services/CommerceLayer/auth'
import { getCustomer } from '@ecommerce/shared/src/services/CommerceLayer/customer'
import { PageProps, useInitCitiesInContext } from '@ecommerce/shared/src/hooks/useInitCitiesInContext'
import { useUserLoginHandler } from './useUserLoginHandler'
import { sendMessageToSentry, ErrorLevel } from '../utils/sentry'
import { checkTokenExpiration, decodeToken } from '../utils/auth'

const initLocation = (city: City, onCityChange: () => void, setLocation: (city: City) => void) => {
  let storedCity = null
  storedCity = getStoredCity()
  log.info('storedCity', storedCity)

  if (!storedCity || !storedCity.id || !storedCity.commerceLayer?.market?.number) {
    if (city && (!city.id || !city.commerceLayer?.market?.number))
      throw new Error('city or market number are not defined')
    setStoredCity(city)
    setLocation(city)
    return true
  }
  setLocation(storedCity)
  if (storedCity.id !== city.id) {
    onCityChange()
    return false
  }
  return true
}

const initAnonymousAuth = async () => {
  const storedCity = getStoredCity()
  const storedToken = getAnonymousToken()

  if (storedToken) {
    const marketIds = decodeToken<{ market: { id: string[] } }>(storedToken)?.market?.id ?? []
    const isExpired = checkTokenExpiration(storedToken)
    const tokenMarketsAreValid = marketIds?.some((id) => id === storedCity?.commerceLayer?.market?.id)

    if (!isExpired && tokenMarketsAreValid) return Promise.resolve()
  }

  const {
    data: { access_token },
  } = await guestSignIn(storedCity?.commerceLayer?.market?.number)
  setAnonymousToken(access_token)
}

const initAuth = async (forceFetch = false) => {
  const accessToken = getAccessToken()
  const isExpired = checkTokenExpiration(accessToken ?? '')

  if ((!getAuth() && !accessToken) || forceFetch || isExpired) {
    const {
      data: { access_token, expires_in },
    } = await guestSignIn()
    setAccessToken(access_token)
    setAccessTokenExpirationTimestamp(Date.now() + expires_in * 1000)
    const city = getStoredCity()
    if (
      city &&
      (!city.commerceLayer || !city.commerceLayer.paymentMethod.id || !city.commerceLayer.shippingMethod.id)
    ) {
      setStoredCity(await addShippingAndPaymentMethod(city))
    }
  }
}

export interface ClCustomerInitPipelineParams {
  pageProps: PageProps
  onCityChange?: () => void
}

export default ({ pageProps, onCityChange = () => null }: ClCustomerInitPipelineParams) => {
  useInitCitiesInContext(pageProps)

  const pathname = pageProps.location?.pathname

  const cityState = useLocation().state.currentCity

  const city = pageProps.pageContext?.currentCity ?? pageProps.pageContext?.city ?? cityState

  const {
    state: { email },
    onHasToken,
    onHasAnonymousToken,
    setIsLoading,
    setUser,
  } = useAuth()

  const {
    state: { cartId },
  } = useShoppingCart()

  const {
    state: { firebaseInstance },
  } = useFirebase()
  const { userLoginCartHandler } = useUserLoginHandler()

  const { setLocation } = useLocation()

  useEffect(() => {
    const ownerId = getOwnerId()

    const onAppMount = async () => {
      try {
        // Logged user - check existing token
        if (ownerId) {
          const {
            data: {
              data: {
                id: customerId,
                attributes: {
                  email: customerEmail,
                  metadata: {
                    dni,
                    firstName,
                    lastName,
                    birthdate,
                    city: customerCity,
                    registered,
                    favoriteSkus,
                    documentType,
                    billingName,
                    dniComplement,
                    isUpdated,
                  },
                },
              },
            },
          } = await getCustomer({ ownerId })

          return setUser({
            ownerId: customerId,
            email: customerEmail,
            dni,
            firstName,
            lastName,
            birthdate,
            city: customerCity,
            registered,
            favoriteSkus,
            isUpdated,
            billingName,
            documentType,
            dniComplement,
          })
        }

        // Guest user - get new token
        await initAuth(true)
      } catch (error) {
        const currentCity = getStoredCity()

        signOutRedirect(currentCity ? `/${currentCity?.slug}` : '/')
      }
    }

    onAppMount()
  }, [])

  useEffect(() => {
    setIsLoading(false)
    async function fetch() {
      try {
        if (!city) return
        if (!initLocation(city, onCityChange, setLocation)) return
        await initAuth()
        if (getAccessToken()) onHasToken()

        await initAnonymousAuth()
        if (getAnonymousToken()) onHasAnonymousToken()
      } catch (e) {
        sendMessageToSentry({
          message: `Error: app init`,
          page: 'global - useInit',
          level: ErrorLevel.Error,
          metadata: {
            error: e,
          },
        })
      }
    }

    fetch()
  }, [pathname])

  useEffect(() => {
    try {
      if (getAuth() && firebaseInstance && !cartId) {
        userLoginCartHandler({ orderId: getOrderId() || '', cartId, customerEmail: email ?? getCustomerEmail() ?? '' })
      }
    } catch (e) {
      sendMessageToSentry({
        message: `Error: app init`,
        page: 'global - useInit auth',
        level: ErrorLevel.Error,
        metadata: {
          error: e,
        },
      })
    }
  }, [firebaseInstance])
}
