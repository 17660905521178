import { LocationState } from './context'
import { City, toHash } from '../../..'
import { Error as ErrorType, Country } from '../../types'

export type BEGINNING = { type: 'BEGINNING' }
export type SET_CURRENT_CITY = { type: 'SET_CURRENT_CITY'; city: City }
export type SET_CITIES = { type: 'SET_CITIES'; cities: City[] }
export type SET_COUNTRY = { type: 'SET_COUNTRY'; country: Country }
export type FETCH_CITIES = { type: 'FETCH_CITIES'; cities: City[]; country: Country }
export type ERROR = { type: 'ERROR'; error: ErrorType }

export type LocationActions = SET_CURRENT_CITY | FETCH_CITIES | SET_CITIES | SET_COUNTRY | ERROR | BEGINNING

function reducer(state: LocationState, action: LocationActions): LocationState {
  switch (action.type) {
    case 'BEGINNING': {
      return { ...state, isLoading: true, error: null }
    }

    case 'ERROR': {
      return { ...state, isLoading: false, error: action.error }
    }

    case 'SET_CITIES': {
      return {
        ...state,
        byHashCities: toHash<City>(action.cities),
        cities: action.cities,
      }
    }

    case 'SET_COUNTRY': {
      return {
        ...state,
        country: action.country,
      }
    }

    case 'FETCH_CITIES': {
      return {
        ...state,
        byHashCities: toHash<City>(action.cities),
        cities: action.cities,
        isLoading: false,
        country: action.country,
      }
    }

    case 'SET_CURRENT_CITY': {
      return { ...state, currentCity: action.city }
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

export default reducer
