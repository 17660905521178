import { useEffect } from 'react'
import { useLocation, City } from '@ecommerce/shared'

export interface PageProps {
  pageContext?: {
    cities?: City[]
    city?: City
    currentCity?: City
  }
  location?: {
    pathname: string
  }
}

export const useInitCitiesInContext = (pageProps: PageProps) => {
  const { setCities } = useLocation()
  const pageContextHasCities = !!(
    pageProps.pageContext &&
    pageProps.pageContext.cities &&
    pageProps.pageContext.cities.length
  )
  useEffect(() => {
    if (pageContextHasCities) {
      setCities(pageProps.pageContext?.cities || [])
    }
  }, [])
}
