import styled, { css } from 'styled-components'
import React from 'react'
import { breakpoints } from '../../../utils/breakpoints'
import { useShoppingCart } from '../../../context/ShoppingCart'
import { Product } from '../../../types'
import { sendAddProductToGTM, sendRemoveProductToGTM, Button as Btn, CartStockErrorHandler } from '../../../..'
import { useDisableAddProductButton } from '../../../hooks/useDisableAddProductButton'
import secrets from '../../../config'
import PromotionHelpText, { StyleProps as PromotionHelpTextProps } from './PromotionHelpText'

const { COUNTRY } = secrets

const MinusIcon = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>minus_fill</title>
    <path d="M22.667 17.333h-13.333v-2.667h13.333zM16 2.667c-7.364 0-13.333 5.97-13.333 13.333 0 3.536 1.405 6.928 3.905 9.428s5.892 3.905 9.428 3.905c3.536 0 6.928-1.405 9.428-3.905s3.905-5.892 3.905-9.428c0-3.536-1.405-6.928-3.905-9.428s-5.892-3.905-9.428-3.905z" />
  </svg>
)

const PlusIcon = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <title>add_fill</title>
    <path d="M22.667 17.333h-5.333v5.333h-2.667v-5.333h-5.333v-2.667h5.333v-5.333h2.667v5.333h5.333zM16 2.667c-7.364 0-13.333 5.97-13.333 13.333 0 3.536 1.405 6.928 3.905 9.428s5.892 3.905 9.428 3.905c3.536 0 6.928-1.405 9.428-3.905s3.905-5.892 3.905-9.428c0-3.536-1.405-6.928-3.905-9.428s-5.892-3.905-9.428-3.905z" />
  </svg>
)

const defaultStyles = css`
  @media (${breakpoints.desktop.min}) {
    height: 40px;
    width: 160px;
    span {
      font-size: 17px;
    }
  }
`

type TypeButton = 'small' | 'large'

const Button = styled.button<{ typeButton?: TypeButton }>`
  touch-action: manipulation;
  border-radius: ${(props) => props.theme.borderRadius};
  height: 35px;
  width: 125px;
  span {
    font-size: 13px;
    font-weight: bold;
  }

  background: ${(props) => (props.disabled ? props.theme.colors.blackLow : props.theme.colors.primary)};
  color: ${(props) => props.theme.colors.white};
  font-size: 13px;
  font-weight: bold;
  outline: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};
  &:hover {
    opacity: ${(props) => (props.disabled ? '1' : '0.7')};
  }
  &.disabled {
    background: ${({ theme }) => theme.colors.blackLow};
    &:hover {
      opacity: 1;
    }
  }

  ${(props) => (props.typeButton === 'large' ? defaultStyles : '')};
`

const WrapperActionButton = styled.div<{ typeButton?: TypeButton }>`
  touch-action: manipulation;
  border-radius: ${(props) => props.theme.borderRadius};
  height: 35px;
  width: 125px;
  min-width: 136px;
  span {
    font-size: 13px;
    font-weight: bold;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.colors.black75};
  justify-items: center;
  .quantity-label {
    font-size: 16px;
    color: ${(props) => props.theme.colors.white};
  }

  ${(props) => (props.typeButton === 'large' ? defaultStyles : '')};
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ActionButton = styled.button<{ isDisabled?: boolean }>`
  touch-action: manipulation;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0 8px;
  > svg {
    fill: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.dark : theme.colors.white)};
    width: 26px;
  }
`

interface Props {
  typeButton?: TypeButton
  quantity: number
  product: Product
  className?: string
  isLoading?: boolean
  notify?: boolean
  stockErrorHandler?: CartStockErrorHandler
  disabled?: boolean
  promotionActivationQuantity?: number
  promotionHelpTextProps?: PromotionHelpTextProps
  isLoadingNotify?: boolean
}

function AddProductButton(props: Props) {
  const {
    className,
    quantity,
    product,
    typeButton = 'small',
    isLoading,
    stockErrorHandler,
    disabled = false,
    promotionActivationQuantity,
    promotionHelpTextProps,
    notify = false,
    isLoadingNotify,
  } = props

  const { addProduct, subtractProduct } = useShoppingCart()

  const disabledAddButton = useDisableAddProductButton({ skuCode: product.skuCode, quantity })
  const isDisabled = disabled || disabledAddButton

  const isBO = COUNTRY === 'BO'

  function onIncrease() {
    if (isDisabled) return
    sendAddProductToGTM(product, isBO)
    return addProduct({ product }, stockErrorHandler)
  }

  function onDecrease() {
    sendRemoveProductToGTM([product], isBO)
    return subtractProduct(product.skuCode)
  }

  if (isLoading) {
    return <Btn disabled isDisabled isLoading className={className} />
  }

  if (product.unavailable && !isLoadingNotify) {
    return (
      <Button typeButton={typeButton} disabled className={`disabled ${className}`}>
        No disponible
      </Button>
    )
  }

  function onPreventAndStop(e: React.MouseEvent) {
    e.stopPropagation()
    e.preventDefault()
    return (fn?: () => void) => {
      if (fn) fn()
    }
  }

  if (quantity === 0)
    return (
      <Button
        className={`${className} ${className}-quantity-is-0`}
        typeButton={typeButton}
        onClick={(e) => onPreventAndStop(e)(onIncrease)}
        onMouseUp={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        disabled={disabled || notify}
      >
        Agregar
      </Button>
    )

  const shouldActivatePromotion = promotionActivationQuantity && quantity <= (promotionActivationQuantity ?? 0) - 1

  return (
    <Wrapper>
      <WrapperActionButton
        data-test="product-add"
        className={className}
        typeButton={typeButton}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        <ActionButton
          data-test="product-add-decrease"
          className="action-button"
          type="button"
          onClick={(e) => onPreventAndStop(e)(onDecrease)}
          onMouseUp={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <MinusIcon />
        </ActionButton>
        <span className="quantity-label">{quantity}</span>
        <ActionButton
          data-test="product-add-increase"
          className="action-button"
          type="button"
          onClick={(e) => onPreventAndStop(e)(onIncrease)}
          onMouseUp={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          isDisabled={isDisabled}
        >
          <PlusIcon />
        </ActionButton>
      </WrapperActionButton>
      {shouldActivatePromotion && promotionActivationQuantity && (
        <PromotionHelpText
          {...(promotionHelpTextProps ?? {})}
          text={`Faltan ${promotionActivationQuantity - quantity} para la promoción`}
        />
      )}
    </Wrapper>
  )
}

export default AddProductButton
