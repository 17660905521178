import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'

type Props = InputHTMLAttributes<HTMLInputElement>
type Ref = HTMLInputElement

const StyledCheckbox = styled.input.attrs<Props>({ type: 'checkbox' })`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  appearance: none;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  background: transparent;
  border-radius: ${({ theme }) => theme.borderRadius};
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 4px;
    top: -1px;
    width: 7px;
    height: 15px;
    border: solid ${({ theme }) => theme.colors.white};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }

  &:checked {
    background: ${({ theme }) => theme.colors.primary};

    &::after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`

const Checkbox = React.forwardRef<Ref, Props>((props, ref) => <StyledCheckbox {...props} ref={ref} />)

export default Checkbox
