import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { baseInput } from '../../../styles/baseForms'
import { ControlSection, Label, Status, Wrapper } from '../../molecules/TextField/Styled'
import InfoMessage from '../InfoMessage'

const TextAreaStyled = styled.textarea`
  ${baseInput}
`

type Ref = HTMLTextAreaElement

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  helpMessage?: string
  errorMessage?: string
  className?: string
  status?: Status
}
const TextArea = React.forwardRef<Ref, TextAreaProps>((props, ref) => {
  const { className, status, label, name, placeholder, errorMessage, helpMessage, ...inputProps } = props
  return (
    <Wrapper className={className} status={status}>
      <Label htmlFor={name}>{label}</Label>
      <ControlSection>
        <TextAreaStyled
          ref={ref}
          {...inputProps}
          data-test={`input-${name}`}
          placeholder={placeholder}
          name={name}
          className="TextFieldComponent__Base"
        />
      </ControlSection>
      <InfoMessage
        className="TextField__help"
        isHidden={!(errorMessage || helpMessage)}
        message={status === 'error' ? errorMessage : helpMessage}
      />
    </Wrapper>
  )
})

export default TextArea
