import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { ZIndex } from '../../../utils/Constants'

interface Props {
  isGhost?: boolean
  className?: string
  size?: 'default' | 'small'
}

const spinner = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`

const Wrapper = styled.div<Props>`
  ${({ theme, isGhost, size }) => {
    function getScale() {
      if (size === 'small') return css`scale(0.3)`

      return css`scale(1)`
    }

    return css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;

      > div {
        position: absolute;
        width: 84px;
        height: 80px;
        display: inline-block;
        transform: ${getScale()};
        z-index: ${ZIndex.medium};
      }

      > div div {
        transform-origin: 40px 40px;
        animation: ${spinner} 1.2s linear infinite;

        &:after {
          content: ' ';
          display: block;
          position: absolute;
          top: 3px;
          left: 37px;
          width: 6px;
          height: 13px;
          border-radius: 100px;
          background: ${isGhost ? theme.colors.white : theme.colors.primary};
        }

        &:nth-child(1) {
          transform: rotate(0deg);
          animation-delay: -1.1s;
        }
        &:nth-child(2) {
          transform: rotate(30deg);
          animation-delay: -1s;
        }
        &:nth-child(3) {
          transform: rotate(60deg);
          animation-delay: -0.9s;
        }
        &:nth-child(4) {
          transform: rotate(90deg);
          animation-delay: -0.8s;
        }
        &:nth-child(5) {
          transform: rotate(120deg);
          animation-delay: -0.7s;
        }
        &:nth-child(6) {
          transform: rotate(150deg);
          animation-delay: -0.6s;
        }
        &:nth-child(7) {
          transform: rotate(180deg);
          animation-delay: -0.5s;
        }
        &:nth-child(8) {
          transform: rotate(210deg);
          animation-delay: -0.4s;
        }
        &:nth-child(9) {
          transform: rotate(240deg);
          animation-delay: -0.3s;
        }
        &:nth-child(10) {
          transform: rotate(270deg);
          animation-delay: -0.2s;
        }
        &:nth-child(11) {
          transform: rotate(300deg);
          animation-delay: -0.1s;
        }
        &:nth-child(12) {
          transform: rotate(330deg);
          animation-delay: 0s;
        }
      }
    `
  }}
`

const Loading = (props: Props) => {
  return (
    <Wrapper {...props}>
      <div>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </Wrapper>
  )
}

export default Loading
