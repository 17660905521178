import React, { ReactNode, useMemo } from 'react'
import {
  ActivityDialogProvider,
  AuthProvider,
  GlobalFonts,
  GlobalStyle,
  LocationProvider,
  ShoppingCartProvider,
  Theme,
  FirebaseProvider,
  NotificationContainer,
} from '@ecommerce/shared'
import { Link } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Helmet } from 'react-helmet'
import { useGlobalThemeQuery, useMaxCartQuantitiesQuery, usePromotionCouponsWhitelist } from './graphql/global'
import 'react-toastify/dist/ReactToastify.css'

import config from './config/secrets'
import ErrorBoundary from './components/ErrorBoundary'

export default (props: { children: ReactNode }) => {
  const { themeExtension } = useGlobalThemeQuery()
  const { maxCartProductQuantity, maxCartBundleQuantity } = useMaxCartQuantitiesQuery()
  const promotionCouponsWhitelist = usePromotionCouponsWhitelist()
  const ErrorWrapper = useMemo(() => {
    if (config.DISABLE_ERROR_BOUNDARY) return ({ children }: { children: React.ReactNode }) => <>{children}</>
    return ErrorBoundary
  }, [config.DISABLE_ERROR_BOUNDARY])

  return (
    <>
      <Helmet>
        <meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
        <meta httpEquiv="Pragma" content="no-cache" />
        <meta httpEquiv="Expires" content="0" />

        <body data-app-version={config.APP_VERSION} />
      </Helmet>
      <Theme themeExtension={themeExtension}>
        <GlobalFonts />
        <GlobalStyle />

        <Link to="/network-error" />
        <FirebaseProvider>
          <NotificationContainer />
          <GoogleReCaptchaProvider reCaptchaKey={config.GOOGLE_RECAPTCHAV3_SITE_KEY}>
            <LocationProvider country={config.COUNTRY}>
              <AuthProvider>
                <ActivityDialogProvider>
                  <ShoppingCartProvider
                    shippingCost={config.SHIPPING_COST}
                    maxCartLineProductQuantity={maxCartProductQuantity}
                    maxCartLineBundleQuantity={maxCartBundleQuantity}
                    promotionCouponsWhitelist={promotionCouponsWhitelist}
                  >
                    <ErrorWrapper>{props.children}</ErrorWrapper>
                  </ShoppingCartProvider>
                </ActivityDialogProvider>
              </AuthProvider>
            </LocationProvider>
          </GoogleReCaptchaProvider>
        </FirebaseProvider>
      </Theme>
    </>
  )
}
