module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"chile-customer-webapp","short_name":"cl-b2c","start_url":"/","background_color":"#921919","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"bbf27c86514d39386aec12d90704451f"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[],"transitionTime":900},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
