import React, { useState, ReactNode, useEffect } from 'react'
import loadable from '@loadable/component'
import { navigate } from 'gatsby'
import { City, getStoredCity, cityChangeMessage } from '@ecommerce/shared'
import { PgPageProps } from '../types/PgPages'
import useInit from '../hooks/useInit'
import useSession from '../hooks/useSession'
import { useUtmQueryParams } from '../hooks/useUtmQueryParams'
import { useCartRefresh } from '../hooks/useCartRefresh'
import { fireSentry } from '../utils/sentry'

const ConfirmationAlert = loadable(() => import('../components/ConfirmationAlert'))

interface InitComponentProps {
  pageProps: PgPageProps
  children: ReactNode
}

interface UseDefaultCityChangeHandlerProps {
  prevCity?: City
  currentCity?: City
}

const useDefaultCityChangeHandler = ({ prevCity, currentCity }: UseDefaultCityChangeHandlerProps) => {
  const [locationChangeAlertState, setLocationChangeAlertState] = useState({
    isOpen: false,
    message: cityChangeMessage,
  })

  const { resetAppState } = useSession()

  const { appendUtmQuery } = useUtmQueryParams()

  const onCityChange = () => {
    setLocationChangeAlertState({ isOpen: true, message: cityChangeMessage })
  }
  const onCancelLocationChange = () => {
    setLocationChangeAlertState({ ...locationChangeAlertState, isOpen: false })
    navigate(appendUtmQuery(`/${prevCity ? prevCity.slug : currentCity?.slug ?? '/'}`))
  }

  const { refreshCart } = useCartRefresh()

  const onCancelCityChange = async () => {
    const redirectSlug = appendUtmQuery(
      /(promotion|category\/)/.test(window.location.pathname) ? `/${currentCity?.slug}` : window.location.pathname,
    )

    refreshCart(currentCity)

    await resetAppState(redirectSlug, {
      newCity: currentCity,
      keepCart: true,
      keepAuth: true,
      onFinish: () => {
        setLocationChangeAlertState({ ...locationChangeAlertState, isOpen: false })
      },
    })
  }

  const CityChangeAlertComponent = (
    <>
      {currentCity && locationChangeAlertState.isOpen && (
        <ConfirmationAlert
          onClose={onCancelLocationChange}
          onBlur={onCancelLocationChange}
          onCancel={onCancelCityChange}
          onConfirm={onCancelLocationChange}
          confirmButtonText="Mantener ciudad"
          cancelButtonText="Cambiar"
          text={locationChangeAlertState.message}
        />
      )}
    </>
  )
  return {
    onCityChange,
    onCancelLocationChange,
    locationChangeAlertState,
    setLocationChangeAlertState,
    CityChangeAlertComponent,
  }
}

export const InitAppDataLayer = (props: InitComponentProps) => {
  const { pageProps, children } = props

  const prevCity = getStoredCity()
  const currentCity = props.pageProps.pageContext?.currentCity

  const { onCityChange: onCityChangeDefault, CityChangeAlertComponent } = useDefaultCityChangeHandler({
    prevCity: prevCity || undefined,
    currentCity,
  })

  const { appendUtmQuery } = useUtmQueryParams()

  const productDetailCityChangeHandler = () => {
    const [sku] = props.pageProps.location.pathname.split('/').reverse()
    navigate(appendUtmQuery(prevCity && prevCity.slug && sku ? `/${prevCity.slug}/products/${sku}` : '/'))
  }

  const checkoutCityChangeHandler = () => navigate(appendUtmQuery(`/${prevCity ? prevCity.slug : '/'}`))

  const cityChangeHandlersByRoute = (citySlug: City['slug']) => [
    { path: `/${citySlug}/checkout`, handler: checkoutCityChangeHandler, exact: true },
    {
      path: `/${citySlug}/products/`,
      handler: productDetailCityChangeHandler,
      exact: false,
    },
  ]

  let onCityChange = onCityChangeDefault
  const pathName = pageProps.location.pathname
  if (currentCity && pathName) {
    const routeCityChange = cityChangeHandlersByRoute(currentCity.slug).find((routeHandler) =>
      routeHandler.exact ? routeHandler.path === pathName : pathName.includes(routeHandler.path),
    )
    if (routeCityChange) onCityChange = routeCityChange.handler
  }

  useEffect(fireSentry, [])
  useInit({ pageProps, onCityChange: currentCity ? onCityChange : undefined })

  return (
    <>
      {children}
      {CityChangeAlertComponent}
    </>
  )
}
