import React, { ReactNode, useEffect, useReducer, useRef } from 'react'
import { ShoppingCartContext, DispatchContext, ShoppingCartState, ShoppingCart } from './context'
import {
  actionAddProduct,
  actionRemoveProduct,
  actionEmpty,
  actionSubtractProduct,
  actionReset,
  actionReplaceState,
  StockErrorHandler,
  actionRemoveProductAsync,
  actionApplyCouponOrGiftCard,
  actionSetCartId,
  actionRemoveCouponOrGiftCard,
} from './actions'
import middleware from './middleware'
import { getCartId, setCartId, getStoredCity } from '../../..'
import { getAuth } from '../../utils/store'
import { useFirestore } from '../../hooks/useFirestore'
import { useFirebase } from '../Firebase'
import { sendMessageToSentry, ErrorLevel, ErrorSource } from '../../../../../apps/cl-customer-webapp/src/utils/sentry'
import { debounce } from '../../utils/debounce'
import { log } from '../../utils/log'

export function ShoppingCartProvider(props: {
  shippingCost: number
  maxCartLineProductQuantity: number
  maxCartLineBundleQuantity: number
  children: ReactNode
  promotionCouponsWhitelist?: string[]
}) {
  const {
    children,
    shippingCost,
    maxCartLineProductQuantity,
    maxCartLineBundleQuantity,
    promotionCouponsWhitelist,
  } = props

  const cartId = getCartId()

  const {
    state: { firebaseInstance },
  } = useFirebase()

  const {
    fsShoppingCartSubscription,
    objectToShoppingCart,
    createFsShoppingCart,
    pushCartStateToFirestore,
  } = useFirestore()

  const initialState = {
    ...ShoppingCartState,
    maxCartLineProductQuantity,
    maxCartLineBundleQuantity,
    shippingCost,
    cartId: cartId ?? '',
    promotionCouponsWhitelist: promotionCouponsWhitelist ?? [],
  }

  const pushToFirestore = useRef(pushCartStateToFirestore)

  const [state, dispatch] = useReducer(middleware(pushToFirestore.current), initialState)

  const createShoppingCart = (cartState: ShoppingCartState, resetCart = false) => {
    return createFsShoppingCart(cartState)
      .then((id) => {
        setCartId(id)
        if (resetCart) actionReplaceState(dispatch)(initialState)
        actionSetCartId(dispatch)(id)
      })
      .catch((e) => {
        log.error('error', e.message)
        if (e && e.code === 'permission-denied') return
        sendMessageToSentry({
          message: `Firestore: Failed to create cart`,
          page: 'context - cart index - createShoppingCart',
          source: ErrorSource.Firestore,
          level: ErrorLevel.Error,
          metadata: {
            error: e,
            cartState,
            resetCart,
            citySlug: getStoredCity()?.slug ?? '',
          },
        })
      })
  }

  useEffect(() => {
    pushToFirestore.current = debounce(pushCartStateToFirestore, 200)
    if (!cartId && !getAuth() && firebaseInstance) {
      createShoppingCart(state)
    }
  }, [firebaseInstance])

  useEffect(() => {
    setCartId(state.cartId)
    if (!firebaseInstance) return
    if (state.cartId) {
      try {
        const unsubscribe = fsShoppingCartSubscription(state.cartId, (doc) => {
          const data = doc.data()
          if (!data || (data.status && data.status === 'placed')) {
            const storedId = getCartId()
            if (!storedId || state.cartId === storedId) createShoppingCart(initialState, true)
            else actionSetCartId(dispatch)(storedId)
          } else {
            actionReplaceState(dispatch)({ ...state, ...objectToShoppingCart(data) })
          }
        })
        return unsubscribe
      } catch (e) {
        sendMessageToSentry({
          message: `Firestore: Failed subscription for cart ${state.cartId}`,
          page: 'context - cart index',
          source: ErrorSource.Firestore,
          level: ErrorLevel.Warning,
          metadata: {
            cartId: state.cartId,
            error: e,
          },
        })
        createShoppingCart(state)
      }
    }
  }, [state.cartId, firebaseInstance])

  return (
    <ShoppingCartContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </ShoppingCartContext.Provider>
  )
}

export function useShoppingCart() {
  const state = React.useContext(ShoppingCartContext)
  const dispatch = React.useContext(DispatchContext)

  const { createFsShoppingCart } = useFirestore()

  if (state === undefined || dispatch === undefined) {
    throw new Error('useShoppingCart must be used within a ShoppingCartProvider')
  }

  const createShoppingCart = async (cartState = ShoppingCartState) => {
    const cartId = await createFsShoppingCart(cartState)
    return cartId
  }

  const createShoppingCartAndSetId = async (cartState = ShoppingCartState) => {
    const cartId = await createShoppingCart(cartState)
    actionSetCartId(dispatch)(cartId)
    return cartId
  }

  return {
    state,
    addProduct: actionAddProduct(dispatch)(state),
    subtractProduct: actionSubtractProduct(dispatch),
    removeProduct: actionRemoveProduct(dispatch),
    removeProductAsync: actionRemoveProductAsync(dispatch),
    replaceState: actionReplaceState(dispatch),
    setCartId: actionSetCartId(dispatch),
    createShoppingCart,
    createShoppingCartAndSetId,
    empty: actionEmpty(dispatch),
    reset: actionReset(dispatch),
    applyCouponOrGiftCard: actionApplyCouponOrGiftCard(dispatch, state),
    removeCouponOrGiftCard: actionRemoveCouponOrGiftCard(dispatch, state),
  }
}

export type ShoppingCartContext = ReturnType<typeof useShoppingCart>
export type CartStockErrorHandler = StockErrorHandler
export type ShoppingCartModel = ShoppingCart

export const initialShoppingCartState = ShoppingCartState
