import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@ecommerce/shared'

export interface LocationOptionResult {
  title: string
  slug: string
  isExternal: boolean
}
export interface LocationOptionsQueryResults {
  allContentfulGlobal: {
    nodes: [{ locationSelectorOptions: LocationOptionResult[] }]
  }
}

const extractLocationSelectorOptions = (results: LocationOptionsQueryResults) =>
  results.allContentfulGlobal.nodes[0].locationSelectorOptions

export const useLocationSelectorOptionsQuery = () =>
  extractLocationSelectorOptions(
    useStaticQuery(graphql`
      query LocationOptionsQuery {
        allContentfulGlobal {
          nodes {
            locationSelectorOptions {
              title
              slug
              isExternal
            }
          }
        }
      }
    `),
  )

export interface LocationSelectorOption extends LocationOptionResult {
  id: number | null
  googleEmbedId: string | undefined
}

export const useLocationSelectorOptions = () => {
  const {
    state: { cities: contextCities },
  } = useLocation()
  const locations = useLocationSelectorOptionsQuery()
  return locations
    .filter((location) => location.isExternal || contextCities.some((city) => city.slug === location.slug))
    .map((location) => {
      const locationCity = contextCities.find((city) => city.slug === location.slug)
      return { ...location, id: locationCity?.id ?? null, googleEmbedId: locationCity?.googleEmbedId }
    })
}
