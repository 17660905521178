import { AxiosPromise } from 'axios'
import fetch, { headers } from './fetch'
import { CLListResponse, CLRetrieveResponse } from './types'

export function getOrderShipments(orderId: string): AxiosPromise<CLListResponse> {
  return fetch(`/api/orders/${orderId}/shipments`, { headers, method: 'GET' })
}

export function setShippingDetails(
  shipmentId: string,
  shippingMethodId: string,
  shippingDate?: string,
): AxiosPromise<CLRetrieveResponse> {
  const data = {
    data: {
      type: 'shipments',
      id: shipmentId,
      attributes: {
        metadata: {
          shippingDate,
        },
      },
      relationships: {
        shipping_method: {
          data: {
            type: 'shipping_methods',
            id: shippingMethodId,
          },
        },
      },
    },
  }
  return fetch(`api/shipments/${shipmentId}`, { data, headers, method: 'PATCH' })
}
