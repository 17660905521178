import React from 'react'
import styled, { css } from 'styled-components'
import { ProductCardType } from '../../../types'
import { breakpoints } from '../../../utils/breakpoints'
import { ZIndex } from '../../../utils/Constants'

export const cssPrefix = 'ProductCard__'

export interface StyleProps {
  type: ProductCardType
  off?: boolean
  isBundle?: boolean
  isRecyclable?: boolean
  hasDiscount?: boolean
  bgColor?: string
  hasLabel?: boolean
  hasStock?: boolean
  isDummy?: boolean
  notify?: boolean
  isLoading?: boolean
  isAuth?: boolean
}

const shouldCenterContent = (type: ProductCardType) => ['small', 'large', 'responsive'].includes(type)

const getLargeCardStyles = (type: ProductCardType, { hasLabel, hasDiscount, hasStock }: StyleProps) => {
  const largeCardStyles = css`
    width: 242px;
    height: 424px;
    padding: 24px 15px 6px;

    .${cssPrefix} {
      &image,
      &content {
        flex: 1 1 0%;
      }

      &image {
        order: 1;
        height: 203px;
        max-height: 203px;
        min-height: 203px;
        font-size: 16px;
        padding-bottom: 8px;
      }

      &content {
        padding: 0;
        order: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      &bottom {
        margin: 0;
      }

      &discount-badge {
        width: 50px;
        height: 23px;
        font-size: 15px;
        margin-left: 10px;
        padding-top: 3px;
      }

      &recyclable-badge,
      &custom-label-support-text {
        top: 219px;
        height: 26px;
        font-size: 14px;

        &.is-presale {
          top: 195px;
        }
      }

      &discount-price {
        margin: 0;
        font-weight: bold;
        font-size: 16px;
      }

      &title {
        margin: ${hasLabel || hasDiscount ? '0 0' : '-8px 0 0'};
        font-size: 16px;
        line-height: 16.5px;
        height: 50px;

        ${() => {
          let marginTop = '24px'
          let marginBottom = '5px'

          if (hasDiscount && hasLabel && hasStock) {
            marginBottom = '9px'
          }

          if (hasDiscount && hasLabel && !hasStock) {
            marginBottom = '21px'
          }

          if (hasDiscount && !hasLabel && !hasStock) {
            marginTop = '-3px'
            marginBottom = '45px'
          }

          if (hasDiscount && !hasLabel && hasStock) {
            marginTop = '-3px'
            marginBottom = '33px'
          }

          if (!hasDiscount && hasLabel && hasStock) {
            marginBottom = '42px'
          }

          if (!hasDiscount && !hasLabel && hasStock) {
            marginTop = '0'
            marginBottom = '41px'
          }

          if (!hasDiscount && hasLabel && !hasStock) {
            marginBottom = '22px'
          }

          if (!hasDiscount && !hasLabel && !hasStock) {
            marginTop = '-3px'
            marginBottom = '48px'
          }

          return css`
            margin-top: ${marginTop};
            margin-bottom: ${marginBottom};
          `
        }}
      }

      &final-price {
        font-size: 24px;
        margin: ${hasLabel && !hasDiscount ? '-26px 0 6px' : '0 0 6px'};
      }

      &add-button {
        height: 35px;
        min-width: 155px;
      }
    }
  `
  if (type === 'responsive' || type === 'responsive-flex') {
    return css`
      @media screen and (${breakpoints.desktop.min}) {
        ${largeCardStyles}
      }
    `
  }

  if (type === 'large') return largeCardStyles
}

const calculateMarginTitle = (
  hasDiscount: boolean | undefined,
  hasLabel: boolean | undefined,
  hasStock: boolean | undefined,
) => {
  if (hasDiscount && hasLabel && hasStock) return '27px'
  if (hasDiscount && hasLabel && !hasStock) return '31px'
  if (hasDiscount && !hasLabel && !hasStock) return '27px'
  if (hasDiscount && !hasLabel && hasStock) return '27px'
  if (!hasDiscount && hasLabel && hasStock) return '19px'
  if (!hasDiscount && !hasLabel && hasStock) return '18px'
  if (!hasDiscount && hasLabel && !hasStock) return '24px'
  if (!hasDiscount && !hasLabel && !hasStock) return '18px'
}

export const ProductCardWrapper = styled.div<StyleProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 190px;
  border-radius: 4px;
  opacity: 1;
  cursor: ${(isDummy) => (isDummy ? 'initial' : 'pointer')};
  user-select: none;
  padding-top: ${({ type }) => (!shouldCenterContent(type) ? `8px` : `16px`)};
  position: relative;
  height: 335px;
  background: ${({ theme, bgColor }) => bgColor ?? theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  margin: 8px 0;

  .${cssPrefix} {
    &content {
      padding: 0 10px 10px;
    }

    &image,
    &title {
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &bottom {
      display: flex;
      justify-content: ${({ type }) => (shouldCenterContent(type) ? 'center' : 'flex-start')};
      align-items: center;
      margin-bottom: 4px;
    }

    &unavailable {
      margin-top: 27px;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 0;
      letter-spacing: 0.1px;
      font-size: 15px;

      div {
        p {
          color: ${({ theme }) => theme.colors.primary};
          font-weight: bold;
          margin: 0;
        }
      }

      p {
        color: ${({ theme }) => theme.colors.black};
        font-weight: normal;
      }

      :hover {
        cursor: pointer;
      }
    }

    &discount-wrapper {
      display: flex;
      align-items: center;
      justify-content: ${({ type }) => (shouldCenterContent(type) ? 'center' : 'flex-start')};
    }

    &discount-badge {
      border-radius: ${({ theme }) => theme.borderRadius};
      font-weight: bold;
      font-size: 12px;
      z-index: ${ZIndex.low};
      background: ${({ theme }) => theme.colors.success};
      color: ${({ theme }) => theme.colors.white};
      width: 40px;
      height: 20px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      align-self: flex-end;
    }

    &image {
      width: 100%;
      height: ${({ hasLabel }) => (hasLabel ? '131px' : '155px')};
      min-height: ${({ hasLabel }) => (hasLabel ? '131px' : 'auto')};
      object-fit: contain;
      object-position: top;
      font-size: 10px;
      text-align: center;
      -webkit-user-drag: none;
    }

    &recyclable-badge,
    &custom-label-support-text {
      width: 100%;
      height: 22px;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      left: 0;
      top: 148px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &custom-label-support-text {
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.white};

      &.is-presale {
        background-color: ${({ theme }) => theme.colors.gold};
        top: 150px;
      }
    }

    &recyclable-badge {
      color: ${({ theme, isBundle }) => (isBundle ? theme.colors.success : theme.colors.white)};
      background-color: ${({ theme, isBundle }) => (isBundle ? theme.colors.medium : theme.colors.success)};
    }

    &discount-price {
      font-size: 14px;
      color: ${(props) => props.theme.colors.dark};
      text-decoration: line-through;
      height: 16px;
      margin: 5px 0 0;
    }

    &final-price {
      font-size: 21px;
      margin: 0;
      font-weight: 700;
      color: ${(props) => props.theme.colors.black};
      text-align: center;
    }

    &title {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      user-select: none;
      font-weight: bold;
      height: 43px;
      font-size: 14px;
      line-height: 14px;
      text-align: ${({ type }) => (shouldCenterContent(type) ? 'center' : 'left')};
      margin: ${({ hasLabel }) => (hasLabel ? '28px 0 12px' : '8px 0 7px')};
      color: ${({ theme }) => theme.colors.dark};
    }

    &add-button {
      display: flex;
      width: 100%;
      max-width: 160px;
      height: 35px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;

      &.disabled {
        justify-content: center;
      }
    }

    &add-button-quantity-is-0 {
      justify-content: center;
    }

    &action-buttons {
      display: flex;
      margin: 0 auto;
    }

    &add-favorite {
      width: 35px;
      height: 35px;
      padding: 0;
      background: ${({ theme }) => theme.colors.light};
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: ${({ theme }) => theme.borderRadius};
      cursor: pointer;

      &.is-favorite {
        svg {
          fill: ${({ theme }) => theme.colors.tertiary};
        }
      }
    }
  }

  ${(props) => getLargeCardStyles(props.type, props)}
  ${({ type, hasLabel, notify, hasStock, hasDiscount }) => {
    if (type === 'small-flex' || type === 'responsive-flex' || type === 'small-horizontal') {
      return css`
        @media screen and (${type === 'small-horizontal'
            ? breakpoints.desktopMega.max
            : breakpoints.tabletPortrait.max}) {
          flex-direction: row;
          width: 330px;
          height: ${notify ? '160px' : '175px'};

          .${cssPrefix} {
            &image {
              width: 145px;
              min-width: 145px;
              height: ${hasLabel && !notify ? '131px' : 'auto'};
            }

            &content {
              width: calc(100% - 145px);
            }

            &recyclable-badge,
            &custom-label-support-text {
              height: 22px;
              width: 135px;
              left: 8px;
              top: unset;
              bottom: 14px;
              font-size: 10px;

              &.is-presale {
                left: 10px;
                bottom: 6px;
                top: unset;
              }
            }

            &title {
              margin-top: 0;
              font-size: 14px;
              height: 42px;
              margin-bottom: ${calculateMarginTitle(hasLabel, hasStock, hasDiscount)};
            }

            &add-button {
              margin: 0;
              max-width: 155px;
            }

            &discount-price {
              font-size: 14px;
              margin-top: 0;
            }
          }
        }
      `
    }
  }}
`
export const PromotionLabelWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;

  .shape {
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 15px;
    font-weight: bold;
    padding: 0;
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 104px solid transparent;
    border-top: 104px solid ${({ theme }) => theme.colors.black};

    span {
      display: block;
      white-space: nowrap;
    }

    .content-text {
      height: min-content;
      padding-right: 50px;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      left: 0;

      .label-text {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  .promotionTooltip {
    position: absolute;
    bottom: 115px;
    width: 242px;
    display: none;
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .promotionTooltip {
      display: block;
    }
  }
`

export const HeartIcon = () => (
  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.235 2.90876C17.7881 2.46164 17.2574 2.10695 16.6734 1.86496C16.0894 1.62297 15.4634 1.49841 14.8312 1.49841C14.1991 1.49841 13.5731 1.62297 12.989 1.86496C12.405 2.10695 11.8744 2.46164 11.4275 2.90876L10.5 3.83626L9.57248 2.90876C8.66975 2.00603 7.44539 1.49888 6.16873 1.49888C4.89208 1.49888 3.66771 2.00603 2.76498 2.90876C1.86225 3.81149 1.3551 5.03586 1.3551 6.31251C1.3551 7.58916 1.86225 8.81353 2.76498 9.71626L3.69248 10.6438L10.5 17.4513L17.3075 10.6438L18.235 9.71626C18.6821 9.26935 19.0368 8.73872 19.2788 8.1547C19.5208 7.57067 19.6453 6.94469 19.6453 6.31251C19.6453 5.68033 19.5208 5.05435 19.2788 4.47032C19.0368 3.8863 18.6821 3.35567 18.235 2.90876Z"
      stroke="#FF4F4F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
