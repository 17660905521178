import React, { useEffect, useState } from 'react'
import { City } from '../services/Location/cities'

type Props = {
  isGuest: boolean
  emailCoincidence: boolean
  dniCoincidence: boolean
  country?: City | null
}

const messageContent = (message: string, country?: City | null) => {
  return (
    <div>
      <p style={{ margin: 0 }}>{message}</p>
      <a
        style={{
          textDecoration: 'none',
          fontWeight: 100,
          color: '#030f1c',
        }}
        href={`/${country?.slug}/reset-password`}
      >
        Intenta
        <span style={{ color: '#F00000', fontWeight: 800 }}> recuperar tu contraseña </span>
      </a>
    </div>
  )
}

export const userDataErrorMessage = ({ isGuest, emailCoincidence, dniCoincidence, country }: Props) => {
  if (isGuest) {
    if (emailCoincidence && !dniCoincidence) {
      return {
        content: `Este correo electrónico ya está asociado a otro número de documento.`,
        title: `Ya existe este correo electrónico`,
        id: 'email',
        message: `Este correo electrónico ya está asociado a otro número de documento.`,
      }
    }
    if (!emailCoincidence && dniCoincidence) {
      return {
        content: `Este documento ya está asociado a otro correo electrónico.`,
        title: `Ya existe este documento`,
        id: 'dni',
        message: `Este documento ya está asociado a otro correo electrónico.`,
      }
    }
  } else {
    if (emailCoincidence) {
      return {
        content: messageContent(`Este correo electrónico ya está asociado a una cuenta.`, country),
        title: `Ya existe este correo electrónico`,
        id: 'email',
        message: `Este correo electrónico ya está asociado a una cuenta.`,
      }
    }
    if (dniCoincidence) {
      return {
        content: messageContent(`Este documento ya está asociado a una cuenta.`, country),
        title: `Ya existe este documento`,
        id: 'dni',
        message: `Este documento ya está asociado a una cuenta.`,
      }
    }
  }
}
