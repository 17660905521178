import { useState, useEffect } from 'react'
import { FormattedAddress, DistrictState } from '../types'
import config from '../config'

const REGIONS = config.MULTIPLE_REGIONS_CITIES
const MultipleRegions = REGIONS.split('|').map((str) => {
  const [key, ...values] = str.split('-')

  return { city: key, locations: [...values] }
})

export const checkDistricts = (city: string) => MultipleRegions.find((region) => region.city === city)

type Dispatcher = React.Dispatch<React.SetStateAction<DistrictState>>
type Context = 'checkout' | 'my-addresses'

type Params = {
  city: string
  edittingAddress: FormattedAddress | null
  context?: Context
}

type Return = {
  districtState: DistrictState
  setDistrictState: Dispatcher
  resetDistrictState: () => void
}

type UseDistricts = ({ ...params }: Params) => Return

export const useDistricts: UseDistricts = ({ edittingAddress, city, context = 'checkout' }) => {
  const regions = checkDistricts(edittingAddress?.cityName ?? city)

  const [districtState, setDistrictState] = useState<DistrictState>({
    checkRegion: regions !== undefined,
    hasError: false,
    selectedRegion: edittingAddress?.region,
  })

  useEffect(() => {
    if (context !== 'checkout') {
      const newRegion = checkDistricts(city)

      setDistrictState({
        checkRegion: newRegion !== undefined,
        hasError: false,
        selectedRegion: newRegion?.city,
      })
    }
  }, [city, context])

  const resetDistrictState = () => setDistrictState((prev) => ({ ...prev, selectedRegion: undefined }))

  return { districtState, setDistrictState, resetDistrictState }
}
