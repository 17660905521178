import { useCallback, useMemo } from 'react'
import { getAuth, getStoredCity } from '../utils/store'
import { useAuth } from '../context/Auth'
import { saveUserFavoriteSkus, deleteUserFavoriteSku } from '../..'
import { showToast } from '../components/molecules/Notification'

type Params = {
  sku: number
}

const removeFromArray = (item: number, array: number[]) => array.filter((i) => i !== item)

const useFavoriteProducts = ({ sku }: Params) => {
  const {
    state: { favoriteSkus, firstName, lastName, email: customerEmail },
    setFavorites,
  } = useAuth()
  const shouldRenderProductCardButton = getAuth()
  const currentCity = getStoredCity()
  const isFavorite = favoriteSkus?.includes(sku)

  const updatedSkus = useMemo(() => {
    let skus = favoriteSkus?.length > 0 ? [...favoriteSkus] : []

    if (isFavorite) {
      skus = removeFromArray(sku, skus)
    } else skus.push(sku)

    return [...new Set(skus)]
  }, [isFavorite, sku, favoriteSkus])

  const revertFavorite = useCallback(() => {
    const skus = favoriteSkus?.length > 0 ? [...favoriteSkus] : []

    if (isFavorite || favoriteSkus?.includes(sku)) {
      setFavorites([...skus, sku])
    } else setFavorites(removeFromArray(sku, skus))
  }, [favoriteSkus, isFavorite, sku])

  const onUpdateFavorites = useCallback(async () => {
    try {
      const customerName = `${firstName} ${lastName}`

      if (!currentCity?.slug || !customerName || !customerEmail) {
        throw new Error('city, customerName and customerEmail are required')
      }

      if (isFavorite) {
        await deleteUserFavoriteSku(customerEmail, sku)
        showToast({ content: 'Producto eliminado de Mis Favoritos', type: 'trash' })
      } else {
        await saveUserFavoriteSkus({
          email: customerEmail,
          name: customerName,
          slugLocation: currentCity.slug,
          favoriteSkus: [sku],
        })
        showToast({ content: '¡Producto agregado a Mis Favoritos!', type: 'success' })
      }
    } catch (error) {
      showToast({ content: 'Ha ocurrido un error, intenta de nuevo', type: 'error' })
      revertFavorite()
    }
  }, [favoriteSkus, isFavorite, sku])

  const onButtonClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    setFavorites(updatedSkus)

    return onUpdateFavorites()
  }

  return { shouldRenderProductCardButton, isFavorite, onUpdateFavorites, onButtonClick }
}

export default useFavoriteProducts
