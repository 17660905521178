import { createContext } from 'react'
import { Country, Error } from '../../types'
import { LocationActions } from './reducer'
import { City } from '../../services/Location/cities'

type ByHash = { [K: string]: City }

export const LocationState = {
  isLoading: false,
  error: null as Error | null,
  cities: [] as City[],
  country: Country.CHILE as Country,

  // FIXME: delete
  byHashCities: {} as ByHash,
  currentCity: null as City | null,
}

export type LocationState = typeof LocationState
export const LocationContext = createContext<LocationState>(LocationState)
export type LocationDispatch = (action: LocationActions) => void
export const DispatchContext = createContext<LocationDispatch | undefined>(undefined)
