import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { RemoveScroll } from 'react-remove-scroll'
import { ZIndex } from '../../../utils/Constants'

const Wrapper = styled.div<{ zIndex?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.colors.black75};
  z-index: ${(props) => {
    if (!props.zIndex) return ZIndex.ultraHigh
    const constZindex = ZIndex[props.zIndex as keyof typeof ZIndex]
    if (constZindex) return constZindex
    return props.zIndex
  }};
  ModalBackground__RemoveScroll {
    margin-top: -21.5px;
  }
`

export interface ModalBackgroundProps extends HTMLAttributes<HTMLDivElement> {
  zIndex?: string
  onBlur?: () => void
}

export interface ModalBackgroundBlurEvent {
  target: {
    className?: string
  }
}

const ModalBackground = (props: ModalBackgroundProps) => {
  const handleClick = (
    e:
      | (ModalBackgroundBlurEvent & React.MouseEvent<HTMLDivElement>)
      | (ModalBackgroundBlurEvent & React.TouchEvent<HTMLDivElement>),
  ) => {
    if (
      props.onBlur &&
      e.target.className &&
      e.target.className.includes &&
      (e.target.className.includes('ModalBackground__RemoveScroll') ||
        e.target.className.includes('ModalBackground__Wrapper'))
    )
      props.onBlur()
  }
  return (
    <Wrapper {...props} onClick={handleClick} data-test="modal-background">
      <RemoveScroll data-test="remove-scroll" className="ModalBackground__RemoveScroll" removeScrollBar={false}>
        {props.children}
      </RemoveScroll>
    </Wrapper>
  )
}

export default ModalBackground
