import { useEffect, useState } from 'react'

export const breakpoints = {
  phoneSmall: {
    min: 'min-width: 280px',
    max: 'max-width: 374px',
  },
  phoneSmallLandscape: {
    min: 'min-width: 375px',
    max: 'max-width: 567px',
  },
  phonePortrait: {
    min: 'min-width: 320px',
    max: 'max-width: 414px',
  },
  phoneLandscape: {
    min: 'min-width: 415px',
    max: 'max-width: 667px',
  },
  tabletPortrait: {
    min: 'min-width: 668px',
    max: 'max-width: 768px',
  },
  tabletLandscape: {
    min: 'min-width: 769px',
    max: 'max-width: 1024px',
  },
  desktop: {
    min: 'min-width: 1025px',
    medium: 'min-width: 1200px',
    max: 'max-width: 1366px',
  },
  desktopWide: {
    min: 'min-width: 1367px',
    max: 'max-width: 1680px',
  },
  desktopHD: {
    min: 'min-width: 1681px',
    max: 'max-width: 1920px',
  },
  desktopMega: {
    min: 'min-width: 1921px',
    max: 'max-width: 2560px',
  },
}

export class BrowserResolution {
  private window: Window

  constructor(window: Window) {
    this.window = window
  }

  public get mqMobile(): MediaQueryList {
    const query = `(${breakpoints.phoneLandscape.max})`
    return this.window.matchMedia(query)
  }

  public get mqTablet(): MediaQueryList {
    const query = `(${breakpoints.tabletPortrait.min}) and (${breakpoints.tabletLandscape.max})`
    return this.window.matchMedia(query)
  }

  public get mqTabletLandscape(): MediaQueryList {
    const query = `(${breakpoints.tabletLandscape.min}) and (${breakpoints.tabletLandscape.max})`
    return this.window.matchMedia(query)
  }

  public get mqMSite(): MediaQueryList {
    const query = `(${breakpoints.phonePortrait.min}) and (${breakpoints.tabletLandscape.max})`
    return this.window.matchMedia(query)
  }

  public get mqDesktop(): MediaQueryList {
    const query = `(${breakpoints.desktop.min})`
    return this.window.matchMedia(query)
  }
}

declare const window: Window
export function useResolution() {
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [isTabletLandscape, setIsTabletLandscape] = useState(false)
  const [isMSite, setIsMSite] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)

  const mqChange = (fn: (match: boolean) => void) => (mq: MediaQueryListEvent) => fn(mq.matches)

  const onSubscribe = (listener: MediaQueryList, fn: (mq: MediaQueryListEvent) => void) => {
    if (listener.addEventListener) {
      return {
        add: () => listener.addEventListener('change', fn),
        remove: () => listener.removeEventListener('change', fn),
      }
    }

    return { add: () => listener.addListener(fn), remove: () => listener.removeListener(fn) }
  }

  const mqChangeMobile = mqChange(setIsMobile)
  const mqChangeTablet = mqChange(setIsTablet)
  const mqChangeTabletLandscape = mqChange(setIsTabletLandscape)
  const mqChangeMSite = mqChange(setIsMSite)
  const mqChangeDesktop = mqChange(setIsDesktop)

  useEffect(() => {
    const { mqMobile, mqTablet, mqMSite, mqDesktop, mqTabletLandscape } = new BrowserResolution(window)

    setIsMobile(mqMobile.matches)
    setIsTablet(mqTablet.matches)
    setIsTabletLandscape(mqTabletLandscape.matches)
    setIsMSite(mqMSite.matches)
    setIsDesktop(mqDesktop.matches)

    onSubscribe(mqMobile, mqChangeMobile).add()
    onSubscribe(mqTablet, mqChangeTablet).add()
    onSubscribe(mqTabletLandscape, mqChangeTabletLandscape).add()
    onSubscribe(mqMSite, mqChangeMSite).add()
    onSubscribe(mqDesktop, mqChangeDesktop).add()

    return () => {
      onSubscribe(mqMobile, mqChangeMobile).remove()
      onSubscribe(mqTablet, mqChangeTablet).remove()
      onSubscribe(mqTabletLandscape, mqChangeTabletLandscape).remove()
      onSubscribe(mqMSite, mqChangeMSite).remove()
      onSubscribe(mqDesktop, mqChangeDesktop).remove()
    }
  }, [])

  return { isMobile, isTablet, isTabletLandscape, isMSite, isDesktop }
}
