import { graphql, useStaticQuery } from 'gatsby'
import { ThemeExtension } from '@ecommerce/shared'
import { ContentfulTheme } from '../../types/PgPages'

export interface ContentfulThemeQueryResult {
  allContentfulGlobal: {
    edges: { node: { theme: ContentfulTheme } }[]
  }
}

export const contentfulThemeToThemeExtension = (contentfulTheme: ContentfulTheme): ThemeExtension => {
  const {
    bodyBackground,
    bodyBackgroundAlt,
    bodyText,
    brandActive,
    brandHover,
    brandDefault,
    brandSelected,
    brandTextActive,
    brandTextHover,
    brandTextDefault,
    brandTextSelected,
    productViewWrapperBackground,
    productViewContainerBackground,
  } = contentfulTheme

  return {
    brand: {
      default: brandDefault,
      selected: brandSelected,
      hover: brandHover,
      active: brandActive,
    },
    brandText: {
      default: brandTextDefault,
      selected: brandTextSelected,
      hover: brandTextHover,
      active: brandTextActive,
    },
    bodyBackground: {
      default: bodyBackground,
      alt: bodyBackgroundAlt,
    },
    productView: {
      wrapperBackground: productViewWrapperBackground,
      containerBackground: productViewContainerBackground,
    },
    bodyText,
  }
}

const extractContentfulTheme = (queryResult: ContentfulThemeQueryResult) => {
  if (!queryResult.allContentfulGlobal.edges.length) return undefined
  return queryResult.allContentfulGlobal.edges[0].node.theme
}

export const extractSearchBoxBackgroundImageUrlFromContentfulTheme = (contentfulTheme: ContentfulTheme) => {
  return contentfulTheme.searchBoxBackgroundImage.file.url
}

export const useGlobalThemeQuery = () => {
  const theme = extractContentfulTheme(
    useStaticQuery(graphql`
      query GlobalThemeQuery {
        allContentfulGlobal {
          edges {
            node {
              theme {
                bodyBackground
                bodyBackgroundAlt
                bodyText
                brandActive
                brandHover
                brandDefault
                brandSelected
                brandTextActive
                brandTextHover
                brandTextDefault
                brandTextSelected
                productViewWrapperBackground
                productViewContainerBackground
                contentful_id
                searchBoxBackgroundImage {
                  id
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `),
  )
  const themeExtension = theme ? contentfulThemeToThemeExtension(theme) : undefined
  const searchBoxBackgroundImageUrl = theme ? extractSearchBoxBackgroundImageUrlFromContentfulTheme(theme) : undefined
  return { themeExtension, searchBoxBackgroundImageUrl }
}
