import { navigate } from 'gatsby'
import { useShoppingCart, useLocation, useAuth, cleanStoreOnRedirect, City, setStoredCity } from '@ecommerce/shared'

type ResetAppState = (
  redirectSlug: string,
  options?: {
    keepCart?: boolean
    keepAuth?: boolean
    newCity?: City
    onFinish?: () => void
  },
) => Promise<void>

const useSession = () => {
  const { empty: emptyCart, createShoppingCartAndSetId } = useShoppingCart()
  const { setLocation } = useLocation()
  const { onReset } = useAuth()

  const resetAppState: ResetAppState = async (redirectSlug, options) => {
    const keepCart = options?.keepCart ?? false
    const keepAuth = options?.keepAuth ?? false
    const newCity = options?.newCity
    const onFinish = options?.onFinish

    cleanStoreOnRedirect(!keepCart, !!newCity, !keepAuth)

    if (!keepCart) {
      emptyCart()
      await createShoppingCartAndSetId()
    }
    if (newCity) {
      setLocation(newCity)
      setStoredCity(newCity)
    }
    if (!keepAuth) {
      onReset()
    }

    if (onFinish) onFinish()

    await navigate(redirectSlug)
  }

  return { resetAppState }
}

export default useSession
