import React from 'react'
import App from './src/App'
import { InitAppDataLayer } from './src/pageWrapper/InitDataLayer'
import { navigateWithUTMQueryParams } from './src/utils/utmParams'

import 'swiper/swiper-bundle.min.css'

export const wrapRootElement = ({ element }) => <App>{element}</App>
export const wrapPageElement = ({ element, props }) => {
  return <InitAppDataLayer pageProps={props}>{element}</InitAppDataLayer>
}

export const onPreRouteUpdate = navigateWithUTMQueryParams

export const onRouteUpdate = () => {
  if (window.dataLayer) {
    window.dataLayer = window.dataLayer || []
  }
}

/* Load network error page */
const count = 0
export const onPrefetchPathname = ({ loadPage }) => {
  if (count === 0) {
    loadPage('/network-error')
  }
}
