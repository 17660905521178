import React, { SVGAttributes } from 'react'
import styled, { DefaultTheme } from 'styled-components'
import './icon_sprite.svg'

type StyleProps = {
  fillColor?: keyof DefaultTheme['colors']
  size?: number | string
  sizeUnit?: string
  sizeWidth?: number | string
  sizeHeight?: number | string
}

const SvgElement = styled.svg<StyleProps>`
  fill: ${(props) => {
    if (!props.fillColor) return 'currentColor'
    if (props.theme.colors[props.fillColor]) return props.theme.colors[props.fillColor]
    return props.fillColor
  }};
  height: ${(props) => (props.sizeHeight || props.size || 32) + (props.sizeUnit || 'px')};
  width: ${(props) => (props.sizeWidth || props.size || 32) + (props.sizeUnit || 'px')};
`

export interface IconProps extends SVGAttributes<SVGElement>, StyleProps {
  iconId:
    | 'add'
    | 'addcircle_fill'
    | 'addcircle_outline'
    | 'arrow_down'
    | 'arrow_left'
    | 'arrow_right'
    | 'arrow_up'
    | 'bookmark'
    | 'calendar'
    | 'cart_0'
    | 'cart_1'
    | 'cart_2'
    | 'cart_3'
    | 'cart_4'
    | 'checklist'
    | 'chevron_down'
    | 'chevron_left'
    | 'chevron_right'
    | 'chevron_up'
    | 'close'
    | 'creditcard'
    | 'error_outline'
    | 'eye_close'
    | 'eye_open'
    | 'facebook'
    | 'grid'
    | 'help'
    | 'home'
    | 'info_fill'
    | 'info_outline'
    | 'info_alt'
    | 'instagram'
    | 'loading'
    | 'location'
    | 'logout'
    | 'mail_subject'
    | 'menu'
    | 'minus'
    | 'minuscircle_fill'
    | 'minuscircle_outline'
    | 'Promociones'
    | 'search'
    | 'filter_fill'
    | 'filter_outline'
    | 'order_clipboard'
    | 'product_list'
    | 'refresh'
    | 'sort'
    | 'star_fill'
    | 'star_outline'
    | 'success'
    | 'trash'
    | 'truck'
    | 'user'
    | 'warning_fill'
    | 'warning_outline'
    | 'cocktail'
    | 'ingredients'
    | 'mix'
    | 'whatsapp'
    | 'facebook-outline'
    | 'twitter'
    | 'link'
    | 'mail'
    | 'share'
    | 'qr'
    | 'download'
    | 'help-fill'
    | 'heart'
    | 'heart_outline'
    | 'grape'
    | 'meal'
    | 'thermometer'
    | 'valley'
}

export const Icon = (props: IconProps) => {
  return (
    <SvgElement {...props}>
      <use xlinkHref={`#icon_sprite_icon-${props.iconId}`} />
    </SvgElement>
  )
}
