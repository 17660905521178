import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { ZIndex, Image, breakpoints, Country } from '@ecommerce/shared'
import secrets from '../../config/secrets'

const isBO = secrets.COUNTRY === Country.BOLIVIA

type Props = {
  className?: string
}

const TopNavbar = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.brand.default};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  padding: 24px 16px;
  z-index: ${ZIndex.medium};
  position: fixed;
  top: 0;
  box-shadow: ${({ theme }) => theme.boxShadow.lvlThree};

  button {
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    padding: 0;
    cursor: pointer;
    position: absolute;
    left: 16px;

    svg {
      fill: ${({ theme }) => theme.colors.brandText.default};
    }
  }

  img {
    width: auto;
    height: 36px;
  }
  @media (${breakpoints.desktop.min}) {
    img {
      height: 48px;
    }
  }
`
const NavBarStaticPage = ({ className = '' }: Props) => (
  <TopNavbar className={className}>
    <Link to="/">
      <Image
        preload={false}
        src={
          isBO
            ? 'https://images.ctfassets.net/5czn5snkqxg9/6PESRN9i2N3aVUjITRalJS/786ba991d56a889d19c0379d7e051bdc/logo.svg'
            : 'https://images.ctfassets.net/16npdkkoi5mj/7E46MY9iDUr4A5RkUAwsq5/8dd893fc8bf06d7c7bf048751cc74c35/160x50mcc__1_.svg'
        }
        alt="logo"
      />
    </Link>
  </TopNavbar>
)

export default NavBarStaticPage
