import { loadState, removeState, saveState } from './localStorage'
import { City, ShoppingCartContext } from '../..'
import { AuthState } from '../context/Auth/context'

export const authKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  accessTokenExpirationTimestamp: 'accessTokenExpirationTimestamp',
  auth: 'auth-v5',
  anonymousToken: 'anonymousToken',
}
export type AuthKeys = keyof typeof authKeys
// Add new namespaced keys here
export const cartKeys = {
  cart: 'cart-v4', // Todo: will be deprecated
  cartId: 'cart-id',
  orderId: 'order-id',
}
export type cartKeys = keyof typeof cartKeys

export const userKeys = {
  isAdult: 'user-is-adult',
}
export type userKeys = keyof typeof userKeys

export const locationKeys = {
  city: 'cityV5',
}
export type locationKeys = keyof typeof locationKeys

export const storeKeys = { ...authKeys, ...cartKeys, ...userKeys } // Add all key namespaces here
export type storeKeys = keyof typeof storeKeys

// Getters
export const getAuth = () => !!loadState(authKeys.refreshToken) && !!loadState<AuthState>(authKeys.auth)?.email
export const getOwnerId = () => {
  const auth = loadState<AuthState>(authKeys.auth)
  if (auth?.ownerId) return auth.ownerId
  return null
}
export const getGuest = () => {
  const auth = loadState<AuthState>(authKeys.auth)
  if (auth?.guest) return auth.guest
  return { firstName: undefined, lastName: undefined }
}
export const getCustomerEmail = () => {
  const auth = loadState<AuthState>(authKeys.auth)
  if (auth?.email) return auth.email
  return null
}
export const getAccessToken = () => loadState<string>(authKeys.accessToken)
export const getRefreshToken = () => loadState<string>(authKeys.refreshToken)
export const getAccessTokenExpirationTimestamp = () => loadState<number>(authKeys.accessTokenExpirationTimestamp)

export const getIsUserAdult = () => loadState(userKeys.isAdult) ?? false
export const saveIsUserAdult = () => saveState(userKeys.isAdult, 'true')

export const getOrderId = () => loadState<string>(cartKeys.orderId)
export const getCartId = () => loadState<string>(cartKeys.cartId)

export const getStoredCity = () => {
  const city = loadState<City>(locationKeys.city) ?? null

  return city
}

export const getStoredCartState = () => loadState<ShoppingCartContext['state']>(cartKeys.cart)

// setters
export const setAccessToken = (token: string) => saveState(authKeys.accessToken, token)
export const setAccessTokenExpirationTimestamp = (timestamp: number) =>
  saveState(authKeys.accessTokenExpirationTimestamp, timestamp)

export const setStoredCity = (city: City) => saveState(locationKeys.city, city)

export const setOrderId = (orderId: string) => saveState(cartKeys.orderId, orderId)
export const setCartId = (cartId: string) => saveState(cartKeys.cartId, cartId)

export const setStoredCartState = (cartState: ShoppingCartContext['state']) => saveState(cartKeys.cart, cartState)

// actions
export const cleanAuth = () => {
  Object.values(authKeys).forEach((key) => removeState({ key }))
}

export const cleanRefreshToken = () => {
  removeState({ key: authKeys.refreshToken })
}

export const cleanCartId = () => removeState({ key: cartKeys.cartId })

export const cleanShoppingCart = () => {
  removeState({ key: cartKeys.orderId })
  removeState({ key: cartKeys.cart })
}

export const cleanOrderId = () => removeState({ key: cartKeys.orderId })

export const signOutStoreClear = () => {
  const signoutKeys = { ...authKeys }
  Object.values(signoutKeys).forEach((key) => removeState({ key }))
}

export const signOutRedirect = (slug?: string) => {
  signOutStoreClear()
  window.location.href = `${window.location.origin}${slug || ''}`
}
export const cleanStore = () => {
  Object.values(storeKeys).forEach((key) => removeState({ key }))
}

export const cleanStoreOnRedirect = (clearCart = true, clearLocation = true, clearAuth = true) => {
  const cartStoreKeys = clearCart ? cartKeys : {}
  const locationStoreKeys = clearLocation ? locationKeys : {}
  const authStoreKeys = clearAuth ? authKeys : {}
  const keys: { [key: string]: string } = { ...cartStoreKeys, ...locationStoreKeys, ...authStoreKeys }

  Object.values(keys).forEach((key) => {
    if (key) removeState({ key })
  })
}

export const setAnonymousToken = (token: string) => saveState(authKeys.anonymousToken, token)
export const getAnonymousToken = () => loadState<string>(authKeys.anonymousToken)
