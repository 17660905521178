import * as React from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import PageTransition from 'gatsby-plugin-page-transitions'

const index = <Props extends Record<string, any>>(Component: React.FC<Props>) => (props: Props) => {
  return (
    <PageTransition>
      <Component {...props} />
    </PageTransition>
  )
}

export default index
