import styled from 'styled-components'
import Anchor from '../../atoms/Anchor'
import { breakpoints } from '../../../utils/breakpoints'
import { hasScrollbarStyles } from '../../../styles/hasScrollbar'
import { toCssPrefix } from '../../../utils/format'

export const { toPrefix, cssPrefix } = toCssPrefix('AuthForm__')

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.medium};
`

export const ALink = styled(Anchor)<{ disabled?: boolean }>`
  font-size: 15px;
  width: auto;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  :hover {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.primary : theme.colors.tertiary)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`

export const CheckboxOption = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 8px 0 0;
  width: 100%;
  min-height: 35px;

  .CheckboxOption__ {
    &label {
      margin: 0;
    }
    &input {
      margin-right: 1em;
    }
    &anchor {
      text-decoration-color: ${(props) => props.theme.colors.black};
      color: ${(props) => props.theme.colors.black};
    }
  }
  span {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.black};
    font-size: 13px;
  }
`

export const WrapperForm = styled.form<{ height?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 33px;
  width: 100%;
  gap: 24px;
  height: ${({ height }) => height ?? 'auto'};
  background-color: ${(props) => props.theme.colors.white};
  ${hasScrollbarStyles};

  .${cssPrefix} {
    &terms-error {
      color: ${({ theme }) => theme.colors.error};
      align-self: flex-start;

      svg {
        fill: ${({ theme }) => theme.colors.error};
      }
    }

    &title {
      font-size: 17px;
      font-weight: bold;
      color: ${(props) => props.theme.colors.black};
      padding-bottom: 10px;
    }

    &subtitle {
      margin-bottom: 8px;
    }

    &submit {
      margin-top: 13px;
      width: 250px;
      min-height: 40px;
    }

    &select {
      width: 100%;
      margin: 15px auto;
    }

    &setting_account {
      span {
        padding-right: 4px;
      }
    }

    &submit-white {
      margin-top: 28px;
      width: 250px;
      min-height: 40px;
    }

    &email-image {
      margin-bottom: 30px;
      width: 150px;
      height: 150px;
    }

    &main-indication,
    &indication {
      text-align: center;
    }

    &main-indication {
      font-size: 1.2em;
      margin-bottom: 30px;
      color: ${(props) => props.theme.colors.black};
    }

    &indication {
      font-size: 1em;
      margin-bottom: 30px;
      color: ${(props) => props.theme.colors.black};
    }

    &status {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;
    }

    &error_message {
      display: flex;
      align-items: center;
      margin-top: 15px;
      color: ${(props) => props.theme.colors.error};
      font-size: 15px;
      font-weight: normal;
      line-height: 1.33;

      svg {
        width: 24px;
        height: 24px;
        font-size: 15px;

        fill: ${(props) => props.theme.colors.error};
      }
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    padding: 39px 85px;

    .${cssPrefix} {
      &title {
        display: none;
      }
    }
  }
`

export const WrapperNoAccount = styled.div<{ disabled?: boolean; height?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px 33px;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '100px')};

  .${cssPrefix} {
    &submit {
      width: 250px;
      min-height: 40px;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.primary};
      opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
      cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    }

    &setting_account {
      span {
        padding-right: 4px;
      }
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    padding: 16px 96px;
  }
`
