import React, { useEffect, useState } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import DocumentType from '@ecommerce/shared/src/components/molecules/DocumentType'
import secrets from '@ecommerce/chile-customer-webapp/src/config/secrets'
import { checkCustomerDniExist, showToast, log, checkCustomerDniAndEmailExist } from '@ecommerce/shared'
import useDebounce from '@ecommerce/shared/src/hooks/useDebounce'
import Button from '../../atoms/Button'
import InfoMessage from '../../atoms/InfoMessage'
import TextField from '../../molecules/TextField'
import Checkbox from '../../atoms/Checkbox'
import { useAuth } from '../../../context/Auth'
import { Error as ErrorObject } from '../../../types'
import { City } from '../../../services/Location/cities'
import { getSchema } from '../AuthWidget/libs'
import { ALink, CheckboxOption, WrapperForm, WrapperNoAccount, toPrefix } from '../AuthWidget/styled'
import { formatRut, formatDate } from '../../../utils/forms'
import { getStoredCity, getCartId, getOrderId, getAuth } from '../../../utils/store'
import { useLocation } from '../../../context/Location'
import {
  sendMessageToSentry,
  ErrorLevel,
  ErrorSource,
} from '../../../../../../apps/cl-customer-webapp/src/utils/sentry'
import useEnterKeyPress from '../../../hooks/useEnterKeyPress'
import { CLOrder } from '../../../services/CommerceLayer/types'
import BirthDateInput from '../FormSignUp/BirthDateInput'
import { userDataErrorMessage } from '../../../utils/userDataErrorMessage'

const { documentTypes } = secrets

interface FormData {
  firstName: string
  lastName: string
  dni: string
  email: string
  birthdate: string
  terms: boolean
  documentType?: string
  dniComplement?: string
  billingName?: string
}

export interface Props {
  onFinished: (metadata?: Partial<CLOrder['attributes']['metadata']>, email?: string) => Promise<void>
  onClickSignIn: () => void
  onClickSignUp: () => void
  willCheckout?: boolean
  currentCity?: City
  onSend?: () => void
  onError?: () => void
  setLoading: (isLoading: boolean) => void
  loading: boolean
}

const FormGuest = (props: Props) => {
  const { onClickSignIn, willCheckout, onClickSignUp, setLoading, loading, onError, onFinished } = props
  const {
    isBolivia,
    state: { country },
  } = useLocation()

  const [selectedDocumentType, setSelectedDocumentType] = useState('ci')
  const [isBillingName, setIsBillingName] = useState(true)
  const [isChecking, setIsChecking] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [dniValue, setDniValue] = useState('')

  const emailDebounce = useDebounce(emailValue, 1000)
  const dniDebounce = useDebounce(dniValue, 1000)

  const prevDni: string | number = ''

  const yupSchema = getSchema(isBolivia(), false, selectedDocumentType)

  const methods = useForm<FormData>({
    resolver: yupResolver(yupSchema),
    mode: 'all',
  })

  const {
    register,
    handleSubmit,
    errors,
    watch,
    setValue,
    control,
    reset,
    trigger,
    setError,
    getValues,
    clearErrors,
  } = methods

  const watchFields = watch()

  const [authError, setAuthError] = useState<ErrorObject | null>(null)
  const { onGuest, setGuest, state: authState } = useAuth(country)
  const currentCity = getStoredCity()

  useEffect(() => {
    register({ name: 'birthdate' })
    register({ name: 'billingName' })
  }, [register])

  useEffect(() => {
    reset({
      ...watchFields,
      documentType: isBolivia() ? 'ci' : 'rut',
    })
  }, [reset])

  useEffect(() => {
    if (!isBillingName) {
      setValue('billingName', `${watchFields.firstName} ${watchFields.lastName}`, { shouldDirty: true })
    } else {
      setValue('billingName', '', { shouldDirty: true })
    }
  }, [isBillingName])

  useEffect(() => {
    if (getAuth()) {
      onFinished()
    }
  }, [authState])

  const checkEmail = async () => {
    try {
      const response = await checkCustomerDniExist({ dni: getValues().email })
      if (response.status === 200) {
        if (response.data.customerExists && !response.data.isGuest) {
          showToast({
            content: `Ya tenemos este email asociado a una cuenta.`,
            title: `Ya existe este email`,
            type: 'error',
          })
          setError('email', {
            type: 'manual',
            message: `Ya tenemos este email asociado a una cuenta.`,
          })
        } else {
          clearErrors('email')
        }
      }
    } catch (error) {
      log.error('ERROR CHECKING EMAIL: ', error)
      showToast({
        content: `Ha ocurrido un error en la búsqueda del email`,
        title: `Ha ocurrido un error`,
        type: 'error',
      })
    }
    setIsChecking(false)
  }

  const checkEmailBolivia = async () => {
    try {
      const response = await checkCustomerDniAndEmailExist({ dni: getValues().dni, email: getValues().email })
      if (response.status === 200) {
        if (response.data.customerExists) {
          const errorData = userDataErrorMessage({
            isGuest: response.data.isGuest,
            emailCoincidence: response.data.emailCoincidence,
            dniCoincidence: response.data.dniCoincidence,
            country: currentCity,
          })

          if (errorData) {
            showToast({
              content: errorData.content,
              title: errorData.title,
              type: 'error',
            })

            if (response.data.emailCoincidence) {
              setError('email', {
                type: 'manual',
                message: errorData.message,
              })
            } else if (response.data.dniCoincidence) {
              setError('dni', {
                type: 'manual',
                message: errorData.message,
              })
            }
          }
        }

        if (response.data.isGuest && response.data.dniCoincidence && response.data.emailCoincidence) {
          clearErrors('email')
          clearErrors('dni')
        }
        if (!response.data.customerExists) {
          clearErrors('email')
          clearErrors('dni')
        }
      }
    } catch (error) {
      log.error('ERROR CHECKING EMAIL: ', error)
      showToast({
        content: `Ha ocurrido un error en la búsqueda del email`,
        title: `Ha ocurrido un error`,
        type: 'error',
      })
    }
    setIsChecking(false)
  }

  useEffect(() => {
    if (getValues().dni !== undefined) {
      setDniValue(getValues().dni)
    }
  }, [getValues().dni])

  useEffect(() => {
    if (emailValue !== '' && dniValue !== '') {
      if (isBolivia()) {
        checkEmailBolivia()
      } else {
        checkEmail()
      }
    }
  }, [emailDebounce, dniDebounce])

  async function onSubmit(data: FormData) {
    try {
      if (loading) return
      if (props.onSend) props.onSend()

      const { email, firstName, lastName, dni, birthdate, documentType, billingName, dniComplement, terms } = data
      const metadata = { firstName, lastName, dni, birthdate, documentType, billingName, dniComplement }

      if (willCheckout) {
        await onFinished(metadata, email)
        setGuest({ firstName, lastName })
        return
      }

      await onGuest({
        firstName,
        lastName,
        rut: dni,
        email,
        birthdate,
        terms,
        documentType,
        billingName,
        dniComplement,
        willCheckout: !!willCheckout,
        currentCity: props.currentCity,
      })

      await onFinished(metadata, email)
    } catch (error) {
      const cartId = getCartId()
      const city = getStoredCity()
      const orderId = getOrderId()

      setAuthError({
        code: error?.message,
        status: 400,
        metadata: error,
        description: 'Ha habido un error, por favor intenta de nuevo.',
      })

      sendMessageToSentry({
        page: `shared-formCheckoutGuest`,
        message: `onGuest request error: ${error?.message}`,
        source: ErrorSource.CLayer,
        level: ErrorLevel.Error,
        metadata: {
          ...error,
          params: {
            ...data,
            willCheckout: !!willCheckout,
            currentCity: props.currentCity,
          },
          cartId,
          city,
          orderId,
        },
      })

      if (onError) onError()
    }
  }

  useEnterKeyPress(() => handleSubmit(onSubmit)())

  const errorMessage = authError?.description
  const isSubmitDisabled =
    !watchFields.firstName ||
    !watchFields.lastName ||
    !watchFields.dni ||
    !watchFields.email ||
    !watchFields.birthdate ||
    !watchFields.terms ||
    !!Object.keys(errors).length

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value: date } = event.currentTarget
    if (event.key === 'Backspace') {
      return
    }
    return setValue('birthdate', date.length > 2 ? formatDate(date) : date)
  }

  function handleDniChange(dni: string) {
    let value = dni
    if (!isBolivia()) value = dni.length === 0 || dni === '-' || dni === ' ' ? '' : formatRut(dni)
    return setValue('dni', value)
  }

  const isBO = isBolivia()

  const onBillingNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue('billingName', value, { shouldDirty: true })
    await trigger('billingName')
  }

  const onDateChange = async (date: string) => {
    setValue('birthdate', date)
    await trigger('birthdate')
  }

  return (
    <>
      <FormProvider {...methods}>
        <WrapperForm onSubmit={handleSubmit(onSubmit)}>
          <span className={toPrefix('title')}>Continua como invitado</span>
          <span className={toPrefix('subtitle')}>Completa tus datos</span>

          <Controller
            control={control}
            name="firstName"
            render={({ value, onChange }) => (
              <TextField
                data-testid="firstname-guest"
                maxLength={isBO ? 50 : undefined}
                className={toPrefix('input')}
                label="Nombre"
                value={value || ''}
                type="text"
                status={!errors.firstName ? undefined : 'error'}
                errorMessage={errors.firstName?.message}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ value, onChange }) => (
              <TextField
                data-testid="lastname-guest"
                maxLength={isBO ? 50 : undefined}
                className={toPrefix('input')}
                label="Apellido"
                value={value || ''}
                type="text"
                status={!errors.lastName ? undefined : 'error'}
                errorMessage={errors.lastName?.message}
                onChange={onChange}
              />
            )}
          />

          {isBolivia() && (
            <>
              <CheckboxOption>
                <Checkbox
                  disabled={!watchFields.firstName && !watchFields.lastName}
                  onChange={() => setIsBillingName(!isBillingName)}
                  className="CheckboxOption__input"
                  checked={!isBillingName}
                />
                <p className="CheckboxOption__label">Utilizar mi nombre y apellidos para facturas.</p>
              </CheckboxOption>
              {isBillingName && (
                <TextField
                  label="Nombre para facturas"
                  name="billingName"
                  status={!errors.billingName ? undefined : 'error'}
                  errorMessage={errors.billingName?.message}
                  value={watchFields.billingName || ''}
                  onChange={onBillingNameChange}
                />
              )}
              <DocumentType
                isVisible
                isGuest
                dni={prevDni}
                setIsCheckingDni={setIsChecking}
                selectedDocumentType={selectedDocumentType}
                setSelectedDocumentType={setSelectedDocumentType}
                types={documentTypes}
              />
            </>
          )}

          {!isBolivia() && (
            <Controller
              control={control}
              name="dni"
              render={({ value }) => (
                <TextField
                  data-testid="dni-guest"
                  className={toPrefix('input')}
                  label="Rut"
                  onChange={({ target: { value: dni } }) => handleDniChange(dni)}
                  type="text"
                  maxLength={12}
                  status={!errors.dni ? undefined : 'error'}
                  errorMessage={errors.dni?.message}
                  value={value || ''}
                />
              )}
            />
          )}

          <Controller
            control={control}
            name="email"
            render={({ value, onChange }) => (
              <TextField
                data-testid="email-guest"
                maxLength={isBO ? 50 : undefined}
                className={toPrefix('input')}
                label="Correo electrónico"
                status={!errors.email ? undefined : 'error'}
                errorMessage={errors.email?.message}
                value={emailValue || ''}
                autoCapitalize="nope"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIsChecking(true)
                  setValue('email', e.target.value)
                  setEmailValue(e.target.value)
                }}
              />
            )}
          />

          <BirthDateInput
            data-testid="birthday-guest"
            errorMessage={errors.birthdate?.message}
            className={toPrefix('input')}
            onDateChange={onDateChange}
            status={!errors.birthdate ? undefined : 'error'}
          />

          <CheckboxOption>
            <Checkbox data-testid="terms-guest" className="CheckboxOption__input" name="terms" ref={register} />
            <p className="CheckboxOption__label">
              {'He leído y acepto los '}
              <a className="CheckboxOption__anchor" href="/terminos-y-condiciones" target="_blank">
                Términos y Condiciones
              </a>
              .
            </p>
          </CheckboxOption>
          <InfoMessage className="TextField__help" isHidden={!errors.terms} message={errors?.terms?.message} />
          <Button
            data-test="signin-submit"
            className={toPrefix('submit')}
            whileTap={{ scale: 0.9 }}
            type="submit"
            isDisabled={isSubmitDisabled || loading || isChecking}
            disabled={isSubmitDisabled || loading || isChecking}
            isLoading={loading || isChecking}
          >
            Continuar
          </Button>

          <div className={toPrefix('status')}>
            <InfoMessage className={toPrefix('error_message')} isHidden={!errorMessage} message={errorMessage} />
          </div>
        </WrapperForm>
      </FormProvider>
      <WrapperNoAccount>
        <div className={toPrefix('setting_account')}>
          <span>¿Ya tienes cuenta?</span>
          <ALink data-testid="sign-in-guest" disabled={loading} onClick={() => (loading ? null : onClickSignIn())}>
            Inicia sesión
          </ALink>
        </div>
        <div className={toPrefix('setting_account')}>
          <span>O crea tu cuenta</span>
          <ALink data-testid="sign-up-guest" disabled={loading} onClick={() => (loading ? null : onClickSignUp())}>
            aquí
          </ALink>
        </div>
      </WrapperNoAccount>
    </>
  )
}

export default FormGuest
