import {
  CleanCLOrder,
  CLLineItem,
  CLRetrieveOrderResponse,
  createOrder,
  getDiscountPercentage,
  getLastPendingOrDraftCustomerOrders,
  OrderStatus,
  ProductCart,
  ResourceType,
  ShoppingCartContext,
  toHash,
  getGiftCardBalance,
  getOrder,
  CLOrder,
  log,
} from '../..'
import { ShoppingCartState } from '../context/ShoppingCart/context'
import { getPricedSku } from '../services/BFF'
import { calculateCartTotals } from '../utils/prices'
import { ByHash, Country, PromotionDetail, PromotionType } from '../types'
import { CLPrice } from '../services/CommerceLayer'

export function lineItemsToCartState(lineItems: CLLineItem[]): ShoppingCartContext['state'] {
  const byHash = toHash(
    lineItems
      .filter(
        (lineItem) => lineItem.attributes.item_type === ResourceType.SKUS && !lineItem.attributes?.metadata?.isGift,
      )
      .map((lineItem) => {
        const {
          id,
          attributes: {
            quantity,
            image_url,
            sku_code,
            name,
            unit_amount_cents: price,
            metadata: {
              originalPrice,
              isBundle = false,
              recyclable = false,
              brandName,
              categoryName,
              size,
              labelId = '',
              labelUrl = '',
            },
          },
        } = lineItem
        let discount = 0
        if (originalPrice && price) {
          discount = getDiscountPercentage(price, originalPrice)
        }
        return {
          discount,
          hasDiscount: !!discount,
          image: image_url,
          isBundle,
          lineItemId: id,
          price,
          quantity,
          rawPrice: originalPrice || price,
          recyclable,
          skuCode: sku_code,
          title: name,
          brandName: brandName ?? '',
          categoryName: categoryName ?? '',
          size: size ?? '',
          labelId,
          labelUrl,
        } as ProductCart
      }),
    'skuCode',
  )

  const globalSums = calculateCartTotals(byHash)
  const state = {
    byHash,
    ...globalSums,
    shippingCost: 0,
    orderId: 'removeMeLater',
    cartId: 'removeMeLater',
    maxCartLineProductQuantity: 0,
    maxCartLineBundleQuantity: 0,
    couponCode: undefined,
    promotionCouponsWhitelist: [],
  }

  return state
}

export function extractLineItemsFromIncluded(included: CLRetrieveOrderResponse['included']): CLLineItem[] {
  return included?.filter((item): item is CLLineItem => item.type === 'line_items') || []
}

export function extractAdjustmentsFromIncluded(included: CLRetrieveOrderResponse['included']) {
  return included?.filter(
    (inc) =>
      inc.type === ResourceType.LINE_ITEMS &&
      inc.attributes.item_type === ResourceType.ADJUSTMENT &&
      inc.attributes.name === 'external-promotion-adjustment',
  ) as CLLineItem[]
}

const promotionItemTypes = [
  'fixed_amount_promotions',
  'percentage_discount_promotions',
  'external_promotions',
  'free_shipping',
]
export function extractPromotionsFromIncluded(included: CLRetrieveOrderResponse['included']): CLLineItem[] {
  return included?.filter((item): item is CLLineItem => promotionItemTypes.includes(item.type)) || []
}

function getGiftDiscountItems(lineItem: CLLineItem) {
  return lineItem?.attributes?.metadata?.discountDetails?.filter(
    (detail) => detail?.promotion?.type === PromotionType.A_DISCOUNTS_B,
  )
}

function lineItemIsGift(lineItem: CLLineItem) {
  return !!(
    lineItem.attributes.item_type === ResourceType.EXTERNAL_PROMOTIONS &&
    lineItem?.attributes.metadata?.discountDetails &&
    getGiftDiscountItems(lineItem)?.length
  )
}

function extractGiftLineItems(lineItems: CLLineItem[]) {
  const itemsWithGifts = lineItems.filter(lineItemIsGift) || []
  const formattedGiftLineItems = itemsWithGifts
    .map<PromotionDetail[]>((lineItem) => {
      const discountItems = getGiftDiscountItems(lineItem)

      return (
        discountItems?.map((discountItem) => ({
          sku: discountItem?.sku,
          discountQuantity: discountItem?.discountQuantity ?? 0,
          isGift: !!discountItem,
          total: discountItem?.discountAmount,
          discountAmount: discountItem?.discountAmount,
        })) || []
      )
    })
    ?.flat()
  const totalGifts = Math.abs(formattedGiftLineItems.reduce((total, item) => total + (item?.total ?? 0), 0))

  return { totalGifts, formattedGiftLineItems, itemsWithGifts }
}

export async function getCartStateByOrderId(
  marketNumber?: number,
  country?: Country,
  orderId?: string,
): Promise<{ state: ShoppingCartState; gift_card_code?: string }> {
  if (orderId && country) {
    const {
      data: { included, data },
    } = await getOrder({ orderId, country, include: 'line_items' })
    const lineItems = extractLineItemsFromIncluded(included)
    const isRawDiscount = lineItems.some(
      (lineItem) =>
        lineItem.attributes.item_type === ResourceType.EXTERNAL_PROMOTIONS &&
        lineItem.attributes.name.includes('raw-price-dc'),
    )
    const hasRawXunitDiscounts = lineItems.some(
      (lineItem) =>
        lineItem.attributes.item_type === ResourceType.EXTERNAL_PROMOTIONS &&
        lineItem.attributes.metadata?.type === 'xunit-raw-percent',
    )
    const adjustments = extractAdjustmentsFromIncluded(included)
    const totalAdjustment = adjustments?.reduce(
      (total, adjustment) => total + adjustment.attributes.quantity * (adjustment.attributes.unit_amount_cents ?? 0),
      0,
    )
    const couponDiscount = data.attributes.discount_amount_cents
    let giftCardDiscount = null
    if (data.attributes.gift_card_code && marketNumber && country) {
      giftCardDiscount = await getGiftCardBalance(data.attributes.gift_card_code, marketNumber, country)
    }

    const { totalGifts, formattedGiftLineItems } = extractGiftLineItems(lineItems)

    if (lineItems && lineItems.length)
      return {
        state: {
          ...lineItemsToCartState(lineItems),
          globalCouponDiscounted: Math.abs(giftCardDiscount ?? couponDiscount),
          couponCode: data.attributes.gift_card_code ?? data.attributes.coupon_code,
          adjustment: totalAdjustment ?? undefined,
          isRawDiscount,
          hasRawXunitDiscounts,
          giftLineItems: formattedGiftLineItems,
          totalGifts,
        },
        ...(data.attributes.gift_card_code ? { gift_card_code: data.attributes.gift_card_code } : {}),
      }
  }

  return { state: ShoppingCartState }
}

export async function createOrderAndGetId() {
  const {
    data: { id },
  } = await createOrder()
  return id
}

export const formatProductDetailData = (prices: CLPrice[]) => {
  const originalPrice = prices[0].attributes.compare_at_amount_cents
  const price = prices[0].attributes.amount_cents
  const discountPercentage =
    originalPrice && originalPrice !== price ? Math.round(((originalPrice - price) / originalPrice) * 100) : undefined

  return {
    price,
    originalPrice,
    rawPrice: originalPrice,
    hasDiscount: (originalPrice && originalPrice > price) || false,
    discount: discountPercentage,
  }
}

export const getProductDetailDataBySku = async (sku: string, marketNumber: number) => {
  log.trace('SKU')
  const {
    data: { data, included },
    request,
  } = await getPricedSku(sku, marketNumber)
  log.trace('SKU2')
  if (!data || !data.length || !included || !included.length) {
    const message = 'No results found for query'
    if (request && request.responseURL) throw new Error(`${message} ${request.responseURL}`)
    throw new Error(`${message} sku: ${sku}`)
  }

  return formatProductDetailData(included)
}

export const getOrderAfterPaymentData = async (
  orderId?: string,
  country?: Country,
): Promise<
  | {
      items: ByHash
      status: OrderStatus
      total: number
      number: number
      metadata: CLOrder['attributes']['metadata']
      shippingAmountCents: number
      shippingAmountFloat: number
      clayerTotalCents: number
      clayerTotalFloat: number
      couponCode: string | null
    }
  | undefined
> => {
  if (orderId && country) {
    const {
      data: {
        included,
        data: {
          attributes: {
            status,
            metadata,
            shipping_amount_cents,
            shipping_amount_float,
            total_amount_with_taxes_cents,
            total_amount_with_taxes_float,
            coupon_code,
            number,
          },
        },
      },
    } = await getOrder({ orderId, country, include: 'line_items' })
    const lineItems = extractLineItemsFromIncluded(included)
    const { byHash: items, globalTotal: total } = lineItemsToCartState(lineItems)

    if (lineItems && lineItems.length)
      return {
        items,
        status,
        total,
        metadata,
        number,
        shippingAmountCents: shipping_amount_cents,
        shippingAmountFloat: shipping_amount_float,
        clayerTotalCents: total_amount_with_taxes_cents,
        clayerTotalFloat: total_amount_with_taxes_float,
        couponCode: coupon_code ?? null,
      }
  }
}
export const getCustomerLastFilteredOrder = async (customerEmail: string, marketNumber: number, country: Country) => {
  const orders = await getLastPendingOrDraftCustomerOrders(customerEmail, country, 20, marketNumber)
  const toCleanOrder = (order: CLOrder) => ({
    id: order.id,
    status: order.attributes.status,
    metadata: order.attributes.metadata,
  })
  const withCartId = orders
    .filter((order) => order && order.attributes.metadata && order.attributes.metadata.cartId)
    .reduce<{ pending: CleanCLOrder[]; draft: CleanCLOrder[] }>(
      (prev, order) => {
        return {
          pending: [...prev.pending, ...(order.attributes.status === OrderStatus.PENDING ? [toCleanOrder(order)] : [])],
          draft: [...prev.draft, ...(order.attributes.status === OrderStatus.DRAFT ? [toCleanOrder(order)] : [])],
        }
      },
      { pending: [], draft: [] },
    )
  return withCartId
}

export const getOrderShippingDate = (order: CLOrder) =>
  order.relationships.shipments.objects?.length
    ? order.relationships.shipments.objects[0].attributes.metadata.shippingDate
    : ''
