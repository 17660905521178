import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { breakpoints } from '../../../utils/breakpoints'
import { ZIndex } from '../../../utils/Constants'

const commonSectionProps = css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`

const Wrapper = styled.div`
  box-shadow: ${(props) => props.theme.boxShadow.lvlTwo};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: fixed;
  height: 72px;
  top: 0;
  z-index: ${ZIndex.high};
  width: 100%;
  background: ${(props) => props.theme.colors.brand.default};

  .NavBar__ {
    &section {
      &-left {
        ${commonSectionProps};
        justify-content: flex-start;
        padding-left: 15px;
      }
      &-center {
        ${commonSectionProps};
        justify-content: center;
        justify-self: center;
      }
      &-right {
        ${commonSectionProps};
        justify-content: flex-end;
        padding-right: 15px;
      }
    }
    @media (${breakpoints.tabletPortrait.min}) {
      &section {
        &-left {
          padding-left: 25px;
        }
        &-right {
          padding-right: 25px;
        }
      }
    }
    @media (${breakpoints.desktop.min}) {
      &section {
        &-left {
          padding-left: 112px;
        }
        &-right {
          padding-right: 112px;
        }
      }
    }
  }
`

interface Props {
  left?: ReactNode
  center?: ReactNode
  right?: ReactNode
}

const Index = (props: Props) => {
  const { left, center, right } = props
  return (
    <Wrapper>
      <div className="NavBar__section-left">{left}</div>
      <div className="NavBar__section-center">{center}</div>
      <div className="NavBar__section-right">{right}</div>
    </Wrapper>
  )
}
export default Index
