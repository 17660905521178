import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import Button from '../../atoms/Button'
import InfoMessage from '../../atoms/InfoMessage'
import TextField from '../../molecules/TextField'
import { useAuth, SignInReturn } from '../../../context/Auth'
import { Error } from '../../../types'
import {
  sendMessageToSentry,
  ErrorLevel,
  ErrorSource,
} from '../../../../../../apps/cl-customer-webapp/src/utils/sentry'
import { getCartId, getOrderId } from '../../../..'
import { log } from '../../../utils/log'
import { ALink, WrapperForm, WrapperNoAccount, toPrefix } from '../AuthWidget/styled'
import { flatError } from '../../../utils/format'
import Errors from '../../../services/CommerceLayer/handlingErrors'
import useEnterKeyPress from '../../../hooks/useEnterKeyPress'

interface FormData {
  email: string
  password: string
}

const schema = Yup.object().shape({
  email: Yup.string().email('El formato de email no es el correcto').required('Debes completar este campo'),
  password: Yup.string().required('Debes completar este campo'),
})

export interface Props {
  onFinished: ((signIngResponse?: SignInReturn) => void) | ((signInResponse?: SignInReturn) => Promise<void>)
  onClickResetPassword: (e: React.MouseEvent) => void
  onClickSignUp?: () => void
  showNoAccount?: boolean
  userLoginCartHandler?: (a: any) => Promise<void>
  onCloseModal?: () => void
  onSend?: () => void
  onError?: () => void
  setLoading?: (isLoading: boolean) => void
  loading?: boolean
}

const FormSignIn = (props: Props) => {
  const { register, handleSubmit, errors, watch } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  const {
    onClickSignUp,
    onClickResetPassword,
    showNoAccount = true,
    userLoginCartHandler,
    onSend,
    onCloseModal,
    onError,
    loading,
    setLoading,
  } = props
  const [authError, setAuthError] = useState<Error | null>(null)

  const watchFields = watch()

  const { onSignIn } = useAuth()

  const errorMessage = authError?.description
  const isSubmitDisabled = !watchFields.email || !watchFields.password || !!Object.keys(errors).length

  async function onSubmit({ email, password }: FormData) {
    try {
      if (isSubmitDisabled || loading) return
      if (setLoading) setLoading(true)
      setAuthError(null)

      if (onSend) onSend()
      const response = await onSignIn({ username: email, password }, false)
      const orderId = getOrderId()
      const cartId = getCartId()
      if (userLoginCartHandler) {
        await userLoginCartHandler({
          orderId,
          cartId,
          customerEmail: response.data.email,
          onFinished: props.onFinished,
        })
      } else {
        await props.onFinished(response)
        if (onCloseModal) onCloseModal()
      }
    } catch (error) {
      if (error.message === 'bloqued') {
        sendMessageToSentry({
          message: `On signIn: usuario bloqueado`,
          page: window.location.pathname,
          source: ErrorSource.CLayer,
          level: ErrorLevel.Error,
          metadata: { payload: { email }, error },
        })
      } else {
        const formattedError = flatError<keyof typeof Errors>(error.response)

        log.error({ formattedError })

        setAuthError({
          code: formattedError.code,
          status: 400,
          metadata: error,
          description: Errors[formattedError.code],
        })

        if (onError) onError()

        if (formattedError.code !== 'INVALID_GRANT') {
          sendMessageToSentry({
            message: `On signIn: ${formattedError.code}`,
            page: window.location.pathname,
            source: ErrorSource.CLayer,
            level: ErrorLevel.Error,
            metadata: { formattedError, payload: { email }, error },
          })
        }
      }
    } finally {
      if (setLoading) setLoading(false)
    }
  }

  useEnterKeyPress(() => handleSubmit(onSubmit)())

  return (
    <>
      <WrapperForm height="80%" onSubmit={handleSubmit(onSubmit)}>
        <span className={toPrefix('title')}>Inicia sesión</span>
        <span className={toPrefix('subtitle')}>Ingresa con tu correo y contraseña</span>
        <div className={toPrefix('status')}>
          <InfoMessage className={toPrefix('error_message')} isHidden={!errorMessage} message={errorMessage} />
        </div>
        <TextField
          className={toPrefix('input')}
          label="Correo electrónico"
          name="email"
          type="email"
          status={!errors.email ? undefined : 'error'}
          errorMessage={errors.email?.message}
          ref={register}
          autoCapitalize="nope"
        />
        <TextField
          className={toPrefix('input')}
          label="Contraseña"
          name="password"
          type="password"
          status={!errors.password ? undefined : 'error'}
          errorMessage={errors.password?.message}
          ref={register}
        />
        <Button
          data-test="signin-submit"
          className={toPrefix('submit')}
          whileTap={loading ? {} : { scale: 0.9 }}
          type="submit"
          isDisabled={loading || isSubmitDisabled}
          isLoading={loading}
        >
          Continuar
        </Button>
        <div className={toPrefix('status')}>
          <div className={toPrefix('setting_account')}>
            <span>¿Olvidaste tu contraseña?</span>
            <ALink
              data-testid="reset-password"
              disabled={loading}
              onClick={(e) => (loading ? null : onClickResetPassword(e))}
            >
              Recuperar
            </ALink>
          </div>
        </div>
      </WrapperForm>
      {showNoAccount && onClickSignUp ? (
        <WrapperNoAccount disabled={loading}>
          <span>¿No tienes cuenta?</span>
          <Button
            data-test="signin-signup"
            className={toPrefix('submit transparent')}
            whileTap={loading ? {} : { scale: 0.9 }}
            type="submit"
            isDisabled={false}
            disabled={loading}
            onClick={() => (loading ? null : onClickSignUp())}
          >
            Crea tu cuenta
          </Button>
        </WrapperNoAccount>
      ) : null}
    </>
  )
}

export default FormSignIn
