import React from 'react'
import Tippy from '@tippyjs/react/headless'
import { Product, ProductPromotion } from '../../../types'
import Tooltip from '../Tooltip'
import { toCurrency } from '../../../utils/i18n'
import { percentageToAmount } from '../../../utils/prices'
import { PromotionLabelWrapper } from './styled'

interface Props {
  product: Product
  promotion?: ProductPromotion
  isActiveTooltip?: boolean
}

export const PromotionLabel = ({ product, promotion, isActiveTooltip }: Props) => {
  const { rawPrice, price } = product
  if (!promotion || !promotion.productCardLabel || typeof promotion.productCardLabel !== `string`) return null

  const { discountPercentage, isRaw } = promotion
  const [discountHeader, ...restLabel] = promotion.productCardLabel.split(' ')
  const useRawPrice = isRaw && rawPrice > 0

  let discountText = discountHeader

  if (discountText === '$calculatedPrice' && discountPercentage) {
    const amount = percentageToAmount(discountPercentage, useRawPrice ? rawPrice : price)

    discountText = toCurrency(amount)
  }

  const renderTooltip = () => (
    <Tooltip className="promotionTooltip" verticalPosition="bottom">
      {promotion.catalogDetail}
    </Tooltip>
  )

  return (
    <Tippy placement="top-start" disabled={!isActiveTooltip} render={renderTooltip}>
      <PromotionLabelWrapper>
        <div className="shape">
          <div className="content-text">
            <span className="label-text">{discountText}</span>
            <span className="label-text">{restLabel.join(' ')}</span>
          </div>
        </div>
      </PromotionLabelWrapper>
    </Tippy>
  )
}
