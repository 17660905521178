import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import styled, { DefaultTheme } from 'styled-components'
import { motion } from 'framer-motion'
import { toCssPrefix } from '../../../utils/format'
import { Icon, IconProps } from '../../../../../../apps/cl-customer-webapp/src/components/Icon/Icon'
import { breakpoints } from '../../../utils/breakpoints'

const { cssPrefix, toPrefix } = toCssPrefix('Notification__')

const Wrapper = styled(motion.div)<{ color: keyof DefaultTheme['colors'] }>`
  width: 600px;
  max-width: calc(100vw - 36px);
  height: 100%;
  display: flex;
  align-items: center;
  .${cssPrefix} {
    &icon-container {
      background: ${({ theme, color }) => theme.colors[color]};
      height: 100%;
      width: 49px;
      display: flex;
      justify-content: center;
      position: absolute;
      align-items: center;
      border-radius: ${({ theme }) => theme.borderRadius};
    }
    &inner-container {
      background: ${({ theme }) => theme.colors.white};
      padding: 18px 50px 18px 16px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      min-height: 69px;
      margin-left: 49px;
      font-size: 15px;
      line-height: 20px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.black};
      span {
        font-weight: 200;
      }
    }
    &notification-body {
      flex-grow: 1;
    }
    &notification-title {
      margin-bottom: 10px;
    }
  }
`

const WrapperNotification = styled(ToastContainer)`
  --toastify-toast-width: null;
  margin: 100px 0 0;
  padding: 0 15px;
  @media (${breakpoints.tabletPortrait.min}) {
    margin: 0;
  }
  .Toastify__toast {
    padding: 0;
    min-height: auto;
    cursor: unset;
    box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
    border-radius: ${({ theme }) => theme.borderRadius};
    margin-bottom: 16px;
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
  .close-icon {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 17px;
    svg {
      cursor: pointer;
    }
  }
`
type Props = {
  closeToast: () => void
}

const CloseButton = ({ closeToast }: Props) => (
  <div className="close-icon">
    <Icon onClick={closeToast} iconId="close" size={24} fillColor="black" />
  </div>
)

const notificationTimeout = 5000

export const NotificationContainer = () => {
  return (
    <WrapperNotification
      position="top-center"
      autoClose={notificationTimeout}
      newestOnTop
      rtl={false}
      pauseOnFocusLoss
      closeOnClick={false}
      closeButton={CloseButton}
      hideProgressBar
    />
  )
}

type ToastProps = {
  content: string | React.ReactNode
  title?: string
  type?: 'success' | 'info' | 'trash' | 'error'
}

export const showToast = ({ content, title, type }: ToastProps) => {
  return toast(<Notification content={content} title={title} type={type} />)
}

const getNotificationStyle = (type: string): { icon: IconProps['iconId']; color: keyof DefaultTheme['colors'] } => {
  switch (type) {
    case 'success':
      return { icon: 'success', color: 'success' }
    case 'info':
      return { icon: 'info_outline', color: 'blackHigh' }
    case 'trash':
      return { icon: 'trash', color: 'blackHigh' }
    case 'error':
      return { icon: 'warning_fill', color: 'error' }
    default:
      return { icon: 'info_outline', color: 'blackHigh' }
  }
}

export const Notification = ({ title, content, type = 'error' }: ToastProps) => {
  const { icon, color } = getNotificationStyle(type)

  return (
    <Wrapper color={color}>
      <div className={toPrefix('icon-container')}>
        <Icon size="24" iconId={icon} fillColor="white" />
      </div>
      <div className={toPrefix('inner-container')}>
        <div className={toPrefix('notification-body')}>
          <div className={toPrefix('notification-title')}>{title}</div>
          <div>
            <span>{content}</span>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
