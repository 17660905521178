import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Image, breakpoints, Button } from '@ecommerce/shared'
import { Footer } from '../components/Footer'
import NavBar from '../components/NavBar/NavBarStaticPage'
import withPageTransition from '../components/withPageTransition'

type Props = {
  leftContent: {
    title: string | React.ReactNode | Element
    text: string
    buttonText?: string
    buttonAction?: () => void
    buttonComponent?: React.ReactNode
  }
  image: string | React.ReactNode | Element
  className?: string
  title?: string
}

const Page = styled.div`
  .page-navbar {
    position: relative;
  }
`

const ErrorPageWrapper = styled.div`
  min-height: calc(100vh - 212px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.black75};
  padding: 50px;

  @media screen and (${breakpoints.tabletLandscape.max}) {
    min-height: calc(100vh - 200px);
  }
`

const ErrorPageContent = styled.div`
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 60px;

  @media screen and (${breakpoints.tabletLandscape.max}) {
    flex-direction: column;
    padding-left: 0;
  }
`

const ErrorPageLeft = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  h2 {
    font-size: 64px;
    margin: 0;
  }

  p {
    font-size: 24px;
    width: 300px;
    margin: 32px 0;
  }

  button {
    width: 200px;
  }

  @media screen and (${breakpoints.tabletLandscape.max}) {
    margin-bottom: 60px;
  }
`

const NotFoundPage = ({ title, leftContent, image, className = '' }: Props) => (
  <Page className={`${className}__page`}>
    {title ? (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    ) : null}
    <NavBar className="page-navbar" />
    <ErrorPageWrapper className={`${className}__wrapper`}>
      <ErrorPageContent className={`${className}__content`}>
        <ErrorPageLeft className={`${className}__left`}>
          {typeof leftContent.title === 'string' ? <h2>{leftContent.title}</h2> : leftContent.title}
          <p>{leftContent.text}</p>
          {leftContent.buttonComponent ? (
            leftContent.buttonComponent
          ) : (
            <>
              {leftContent.buttonText && leftContent.buttonAction ? (
                <Button onClick={leftContent.buttonAction}>{leftContent.buttonText}</Button>
              ) : null}
            </>
          )}
        </ErrorPageLeft>
        <div className={`${className}__image-container`}>
          {typeof image === 'string' ? (
            <Image preload={false} className={`${className}__image`} src={image} alt="error-image" />
          ) : (
            image
          )}
        </div>
      </ErrorPageContent>
    </ErrorPageWrapper>
    <Footer />
  </Page>
)

export default withPageTransition(NotFoundPage)
