import { AxiosResponse } from 'axios'
import fetch from './fetch'
import {
  formatPaymentMethods,
  payInBolivia,
  payInChile,
  RedirectToPaymentGatewayParams,
  CreatePaymentResponse,
  GetPaymentStatusResponse,
} from './utils'
import {
  Country,
  PaymentMethodsObject,
  ApplyCouponParams,
  ApplyGiftCardParams,
  ByHash,
  ProductCart,
  vamosXOtraContactParams,
} from '../../types'
import { getStoredCity } from '../../utils/store'
import { CLLineItem, CLOrder, ResourceType } from '../CommerceLayer/types'
import { ImportStatus } from './types'
import { log } from '../../utils/log'
import { timeout } from '../../utils/promises'
import { getOrderForCheckout } from './orders'
import { City } from '../Location/cities'

/**
 * Get Chile payment methods
 */
const getPaymentMethods = async (country: Country): Promise<PaymentMethodsObject> => {
  try {
    // Define enpoint
    const endpoint = `/getPaymentMethods${country === Country.CHILE ? 'Chile' : 'Bolivia'}`

    // Fetch methods
    const { data } = await fetch(endpoint, { method: 'GET' })

    // Format methods
    const formatted = formatPaymentMethods(data)

    return formatted
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * Init Chile payment
 */
const initPaymentChile = async (orderId: string, returnUrl: string, cancelUrl: string) => {
  const { data } = await fetch('/initPaymentChile', {
    data: { orderId, returnUrl, cancelUrl },
    method: 'POST',
  })
  return data
}

const getReturnAndCancelUrl = (orderId: string, isBolivia = false) => {
  const domain = typeof window !== 'undefined' ? window.location.origin : ''
  return [
    `${domain}/payment/${isBolivia ? `pending?req_reference_number=${orderId}` : `processing?orderId=${orderId}`}`,
    `${domain}/payment/cancelled?req_reference_number=${orderId}`,
  ]
}

const getChilePaymentUrl = async (orderId: string): Promise<string> => {
  const [returnUrl, cancelUrl] = getReturnAndCancelUrl(orderId)
  const { paymentUrl: url } = await initPaymentChile(orderId, returnUrl, cancelUrl)
  return url
}

/**
 * Init Bolivia payment
 */
export const initPaymentBolivia = async (orderId: string, sessionId: string) => {
  const [returnUrl, cancelUrl] = getReturnAndCancelUrl(orderId, true)
  const { data } = await fetch('/initPaymentBolivia', {
    data: { orderId, sessionId, returnUrl, cancelUrl },
    method: 'POST',
  })
  return data
}

const redirectToPaymentGateway = async (params: RedirectToPaymentGatewayParams) => {
  if (params.countryCode === Country.CHILE) {
    await payInChile(params)
  } else if (params.countryCode === Country.BOLIVIA) {
    await payInBolivia(params)
  }
}

const applyCoupon = async ({ code, orderId, market, country }: ApplyCouponParams) => {
  const {
    data: { discountedAmount, isRaw, adjustment },
  }: AxiosResponse<{
    discountedAmount: number
    isRaw?: boolean
    adjustment?: number
  }> = await fetch(`/coupons/apply`, {
    method: 'POST',
    data: { coupon: code, orderId, country, market },
  })

  return { discountedAmount, isRaw, adjustment }
}

interface RemoveCouponParams {
  orderId: string
  country: Country
}

const removeCoupon = async ({ orderId, country }: RemoveCouponParams) => {
  const {
    data: { discountAmount },
  }: AxiosResponse<{
    discountAmount: number
  }> = await fetch(`/coupons/revert`, {
    method: 'POST',
    data: { orderId, country },
  })
}

const applyGiftCard = async ({ code, orderId, market, country }: ApplyGiftCardParams) => {
  const {
    data: { discountAmount, isRaw, adjustment },
  }: AxiosResponse<{
    discountAmount: number
    isRaw?: boolean
    adjustment?: number
  }> = await fetch(`/gift_card/apply`, {
    method: 'POST',
    data: { giftCardCode: code, orderId, country, market },
  })

  return { discountedAmount: discountAmount, isRaw, adjustment }
}

const removeGiftcard = async ({ orderId, country }: RemoveCouponParams) =>
  fetch(`/gift_card/revert`, {
    method: 'POST',
    data: { orderId, country },
  })

type RetryPaymentResponse = AxiosResponse<{
  order: CLOrder
  skus: CLLineItem[]
  hasStockError?: boolean
  outOfStock?: string[]
}>

const retryPaymentOrRepeatOrder = async (orderId: string, country: Country, slugLocation: string) => {
  const { data }: RetryPaymentResponse = await fetch('/orders/repeat', {
    method: 'POST',
    data: { orderId, country, slugLocation },
  })

  return data
}

type SendEmailResponse = {
  data: string
}

interface SendEmailParams {
  message: string
  subject: string
  country: Country
}

const sendCustomEmail = async (params: SendEmailParams) => {
  const { data }: SendEmailResponse = await fetch('/sendCustomEmail', {
    method: 'POST',
    data: params,
  })

  return data
}

type ResponseStockItem = {
  sku: string
  quantity: number
}

export const checkStock = async (products: ByHash, country: Country, currentCity: City) => {
  const city = getStoredCity() ?? currentCity

  if (city && city.commerceLayer.stockLocation?.number) {
    const {
      commerceLayer: {
        stockLocation: { number: stockLocationNumber },
      },
    } = city
    const {
      data: { unavailable },
    }: AxiosResponse<{ unavailable: ResponseStockItem[] }> = await fetch(`/stock_items/check`, {
      data: { skus: Object.keys(products), country, stockLocationNumber },
      method: 'POST',
    })

    return unavailable
  }
}

export const getSkuStock = async (sku: string, country: Country, stockLocationNumber: number) => {
  const { data }: AxiosResponse<ResponseStockItem> = await fetch(
    `/stock_items/stock?sku=${sku}&country=${country}&stockLocationNumber=${stockLocationNumber}`,
  )

  return data
}

export const getGiftCardBalance = async (giftCardCode: string, market: number, country: Country) => {
  const {
    data: { balance_cents },
  }: AxiosResponse<{ balance_cents: number }> = await fetch(`/gift_card/balance`, {
    data: { market, country, giftCardCode },
    method: 'POST',
  })

  return balance_cents
}

type CreatePaymentParams = {
  orderId: string
  country: Country
  paymentOptionId: number
}

export const createPayment = async ({ orderId, country, paymentOptionId }: CreatePaymentParams) => {
  const { data: response }: AxiosResponse<CreatePaymentResponse> = await fetch(`/payments/create`, {
    method: 'POST',
    data: { orderId, country, paymentOptionId },
  })

  return response
}

export const getPaymentStatus = async ({ orderId, country }: Omit<CreatePaymentParams, 'paymentOptionId'>) => {
  const { data: response }: AxiosResponse<GetPaymentStatusResponse> = await fetch(`/payments/status`, {
    method: 'GET',
    params: { country, orderId },
  })

  return response
}

export const getImportStatus = async (id: string, country: Country) => {
  const { data }: AxiosResponse<{ id: string; resourceType: ResourceType; status: ImportStatus }> = await fetch(
    `/${country}/imports/${id}`,
  )

  return data
}

export const checkImportStatus = async (importId: string, country: Country) => {
  let counter = 0

  const { status: importStatus, ...importData } = await getImportStatus(importId, country)
  if (importStatus === ImportStatus.COMPLETED) {
    log.trace('COMPLETED', { importStatus, ...importData })

    return Promise.resolve()
  }

  counter += 1
  log.trace('🔥', counter, importStatus)

  await timeout(400)
  await checkImportStatus(importId, country)
}

type PrepareOrderForCheckoutParams = {
  country: Country
  orderId?: string | null | undefined
  customerEmail: string
  lineItems: ProductCart[]
  orderMetadata?: Partial<CLOrder['attributes']['metadata']>
  currentCity: City
}

export type PrepareOrderForCheckoutResponse = {
  order: CLOrder
  hasStockError?: boolean
  outOfStock?: string[]
  skus: CLLineItem[]
}

export const prepareOrderForCheckout = async (
  params: PrepareOrderForCheckoutParams,
): Promise<PrepareOrderForCheckoutResponse> => {
  const { orderMetadata = {}, country, currentCity, ...restParams } = params
  const city = getStoredCity() ?? currentCity

  if (!city || !city?.commerceLayer?.market) throw new Error('City is not defined')

  const {
    data: response,
  }: AxiosResponse<PrepareOrderForCheckoutResponse & { importId?: string; orderId?: string }> = await fetch(
    `/orders/checkout`,
    {
      method: 'POST',
      data: { ...restParams, country, metadata: orderMetadata, marketId: city.commerceLayer.market.id },
    },
  )

  if (response.importId && response.orderId) {
    await checkImportStatus(response.importId, country)

    return getOrderForCheckout(response.orderId, country)
  }

  return response
}

export {
  getPaymentMethods,
  getChilePaymentUrl,
  redirectToPaymentGateway,
  applyCoupon,
  applyGiftCard,
  retryPaymentOrRepeatOrder,
  removeCoupon,
  removeGiftcard,
  sendCustomEmail,
}
