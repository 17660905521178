import React, { ReactNode, useEffect, useReducer } from 'react'
import { DispatchContext, LocationContext, LocationState } from './context'
import reducer from './reducer'
import { actionSetLocation, actionSetCities, actionSetCountry } from './actions'
import { Country, toCurrency as formatCurrency } from '../../..'
import secrets from '../../config'

const { INCLUDES_BOX_PRODUCT_FORMATS, COUNTRY } = secrets

const includesBoxProductFormats = (() => {
  try {
    return INCLUDES_BOX_PRODUCT_FORMATS.split(',').map(parseInt) as number[]
  } catch (e) {
    return []
  }
})()

export function LocationProvider(props: { children: ReactNode; country: Country }) {
  const { children, country } = props
  const [state, dispatch] = useReducer(reducer, LocationState)

  useEffect(() => {
    actionSetCountry(dispatch)(country)
  }, [])

  return (
    <LocationContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </LocationContext.Provider>
  )
}

export function useLocation() {
  const state = React.useContext(LocationContext)
  const dispatch = React.useContext(DispatchContext)

  if (state === undefined || dispatch === undefined) {
    throw new Error('useLocation must be used within a LocationProvider')
  }

  function isBolivia() {
    return COUNTRY === Country.BOLIVIA
  }

  function toCurrency(amount: number | string) {
    return formatCurrency(amount, isBolivia(), isBolivia())
  }

  function textByCountry(clText: string, boText: string) {
    return isBolivia() ? boText : clText
  }

  return {
    state,
    setLocation: actionSetLocation(dispatch),
    setCities: actionSetCities(dispatch),
    setCountry: actionSetCountry(dispatch),
    isBolivia,
    toCurrency,
    textByCountry,
    includesBoxProductFormats,
  }
}
