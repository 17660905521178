import { ShoppingCartState } from './context'
import { Product, ProductCart } from '../../types'

type ADD_PRODUCT = { type: 'ADD_PRODUCT'; product: Product; quantity: number }
type SUBTRACT_PRODUCT = { type: 'SUBTRACT_PRODUCT'; skuCode: string; quantity?: number }
type SET_PRODUCT_QUANTITY = { type: 'SET_PRODUCT_QUANTITY'; skuCode: string; quantity: number }
type REMOVE_PRODUCT = { type: 'REMOVE_PRODUCT'; skuCode: string }
type REMOVE_PRODUCT_ASYNC = { type: 'REMOVE_PRODUCT_ASYNC'; skuCode: string }
type SET_LINE_ITEMS_ID = { type: 'SET_LINE_ITEMS_ID'; skuCode: string; lineItemId: string }
type EMPTY = { type: 'EMPTY' }
type RESET = { type: 'RESET' }
type REPLACE_STATE = { type: 'REPLACE_STATE'; state: ShoppingCartState }
type APPLY_COUPON = { type: 'APPLY_COUPON'; amount: number; code: string; isRawDiscount: boolean; adjustment?: number }
type REMOVE_COUPON = { type: 'REMOVE_COUPON' }
type SET_CART_ID = { type: 'SET_CART_ID'; cartId: string }
type SET_ITEM_STOCK = { type: 'SET_ITEM_STOCK'; skuCode: string; stock: number }

export type ShoppingCartActions =
  | ADD_PRODUCT
  | SUBTRACT_PRODUCT
  | SET_PRODUCT_QUANTITY
  | REMOVE_PRODUCT
  | REMOVE_PRODUCT_ASYNC
  | SET_LINE_ITEMS_ID
  | EMPTY
  | RESET
  | REPLACE_STATE
  | APPLY_COUPON
  | REMOVE_COUPON
  | SET_CART_ID
  | SET_ITEM_STOCK

function reducer(state: ShoppingCartState, action: ShoppingCartActions): ShoppingCartState {
  switch (action.type) {
    case 'ADD_PRODUCT': {
      const productToBeAdded: ProductCart = state.byHash[action.product.skuCode] || {
        ...action.product,
        quantity: 0,
        rawPrice: action.product.rawPrice || action.product.price,
      }

      const newByHash = {
        ...state.byHash,
        [productToBeAdded.skuCode]: { ...productToBeAdded, quantity: productToBeAdded.quantity + action.quantity },
      }

      const globalRawTotal = state.globalRawTotal + productToBeAdded.rawPrice * action.quantity
      const globalTotal = state.globalTotal + productToBeAdded.price * action.quantity
      const globalTotalDiscounted = globalRawTotal - globalTotal

      return {
        ...state,
        byHash: newByHash,
        globalRawTotal,
        globalTotal,
        globalQuantity: state.globalQuantity + action.quantity,
        globalTotalDiscounted,
      }
    }

    case 'SUBTRACT_PRODUCT': {
      const { [action.skuCode]: productToBeRemoved, ...restProducts } = state.byHash

      if (!productToBeRemoved) return state

      const newByHash =
        productToBeRemoved.quantity === 1
          ? restProducts
          : {
              ...state.byHash,
              [action.skuCode]: {
                ...productToBeRemoved,
                quantity: productToBeRemoved.quantity - (action.quantity ?? 1),
              },
            }

      const globalRawTotal = state.globalRawTotal - productToBeRemoved.rawPrice
      const globalTotal = state.globalTotal - productToBeRemoved.price * (action.quantity ?? 1)
      const globalTotalDiscounted = globalRawTotal - globalTotal

      return {
        ...state,
        byHash: newByHash,
        globalRawTotal,
        globalTotal,
        globalQuantity: state.globalQuantity - (action.quantity ?? 1),
        globalTotalDiscounted,
      }
    }

    case 'SET_PRODUCT_QUANTITY': {
      const { [action.skuCode]: productToBeUpdated, ...restProducts } = state.byHash

      if (!productToBeUpdated) return state

      const difference = action.quantity - productToBeUpdated.quantity

      const newByHash =
        action.quantity === 0
          ? restProducts
          : { ...state.byHash, [action.skuCode]: { ...productToBeUpdated, quantity: action.quantity } }

      const globalRawTotal = state.globalRawTotal + productToBeUpdated.rawPrice * difference
      const globalTotal = state.globalTotal + productToBeUpdated.price * difference
      const globalTotalDiscounted = globalRawTotal - globalTotal

      return {
        ...state,
        byHash: newByHash,
        globalRawTotal,
        globalTotal,
        globalQuantity: state.globalQuantity + difference,
        globalTotalDiscounted,
      }
    }

    case 'REMOVE_PRODUCT':
    case 'REMOVE_PRODUCT_ASYNC': {
      const { [action.skuCode]: productToBeRemoved, ...restProducts } = state.byHash

      if (!productToBeRemoved) return state

      const globalRawTotal = state.globalRawTotal - productToBeRemoved.rawPrice * productToBeRemoved.quantity
      const globalTotal = state.globalTotal - productToBeRemoved.price * productToBeRemoved.quantity
      const globalTotalDiscounted = globalRawTotal - globalTotal

      return {
        ...state,
        byHash: restProducts,
        globalRawTotal,
        globalTotal,
        globalQuantity: state.globalQuantity - productToBeRemoved.quantity,
        globalTotalDiscounted,
      }
    }

    case 'SET_LINE_ITEMS_ID': {
      const productToBeUpdated = state.byHash[action.skuCode]

      if (!productToBeUpdated) return state

      return {
        ...state,
        byHash: {
          ...state.byHash,
          [productToBeUpdated.skuCode]: { ...productToBeUpdated, lineItemId: action.lineItemId },
        },
      }
    }

    case 'APPLY_COUPON': {
      return {
        ...state,
        globalCouponDiscounted: action.amount,
        couponCode: action.code,
        isRawDiscount: action.isRawDiscount,
        adjustment: action.adjustment,
      }
    }

    case 'REMOVE_COUPON': {
      return {
        ...state,
        globalCouponDiscounted: ShoppingCartState.globalCouponDiscounted,
        couponCode: ShoppingCartState.couponCode,
        isRawDiscount: false,
        adjustment: 0,
      }
    }

    case 'REPLACE_STATE': {
      return action.state
    }

    case 'EMPTY':
    case 'RESET':
      return {
        ...ShoppingCartState,
        shippingCost: state.shippingCost,
        cartId: state.cartId,
        maxCartLineProductQuantity: state.maxCartLineProductQuantity,
        maxCartLineBundleQuantity: state.maxCartLineBundleQuantity,
      }

    case 'SET_CART_ID':
      return { ...state, cartId: action.cartId }

    case 'SET_ITEM_STOCK': {
      const productToBeUpdated = state.byHash[action.skuCode]
      if (!productToBeUpdated) return state
      return {
        ...state,
        byHash: {
          ...state.byHash,
          [productToBeUpdated.skuCode]: { ...productToBeUpdated, stock: action.stock },
        },
      }
    }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export default reducer
