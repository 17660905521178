import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ContentfulImageQueryParams } from '../../../types'

const Img = styled.img<{ isBlurred: boolean }>`
  width: 100%;
  height: 100%;
  ${({ isBlurred }) => (isBlurred ? 'filter: blur(10px);' : '')}
  transition: 200ms -webkit-filter linear;
`

interface Props {
  src?: string | null
  preload?: boolean
  params?: ContentfulImageQueryParams
  alt: string
  className?: string
  draggable?: 'true' | 'false'
}

const defParams = {
  fm: 'png',
}

const Image = (props: Props) => {
  const { src, alt, className, preload = true } = props
  const params = { ...defParams, ...(props.params ? props.params : {}) }
  const [activeSrc, setActiveSrc] = useState(preload ? `${src}?h=4` : src)
  const [isBlurred, setIsBlurred] = useState(!!preload)

  const query = Object.entries(params)
    .map(([key, val]) => `${key}=${val}&`)
    .join('')
    .slice(0, -1)

  useEffect(() => {
    if (preload && src)
      fetch(`${src}?${query}`)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob)
          setActiveSrc(url)
        })
        .catch(() => setActiveSrc(src))
        .finally(() => setIsBlurred(false))
  }, [src])

  return (
    <Img
      isBlurred={isBlurred}
      className={className}
      src={activeSrc || undefined}
      alt={alt}
      draggable={props.draggable}
      onDragStart={(e: React.DragEvent) => (props.draggable === 'false' ? e.preventDefault() : '')}
    />
  )
}

export default Image
