import { useStaticQuery, graphql } from 'gatsby'

export interface FooterQueryResult {
  allContentfulGlobal: {
    nodes: [
      {
        footer: {
          logo: {
            file: {
              url: string
            }
          }
          linksList: [
            {
              title: string
              items: [
                {
                  label: string
                  href: string
                },
              ]
            },
          ]
          instagramUrl?: string
          facebookUrl?: string
          youtubeUrl?: string
          twitterUrl?: string
          copyrightText: string
          termsSlug: string
          privacyPolicies: string
        }
      },
    ]
  }
}

const parseFooterData = (dataQuery: FooterQueryResult) => {
  const data = dataQuery.allContentfulGlobal.nodes[0].footer
  return data
}

export const useFooterQuery = () => {
  const data = useStaticQuery<FooterQueryResult>(graphql`
    query getFooter {
      allContentfulGlobal {
        nodes {
          footer {
            logo {
              file {
                url
              }
            }
            linksList {
              title
              items {
                ... on ContentfulAtLink {
                  label
                  href
                }
              }
            }
            instagramUrl
            facebookUrl
            youtubeUrl
            twitterUrl
            copyrightText
            termsSlug
            privacyPolicies
          }
        }
      }
    }
  `)
  return parseFooterData(data)
}
