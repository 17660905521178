import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { RemoveScroll } from 'react-remove-scroll'
import { hasScrollbarStyles } from '../../../styles/hasScrollbar'
import Anchor from '../../atoms/Anchor'
import { breakpoints, useResolution } from '../../../utils/breakpoints'
import { ZIndex } from '../../../utils/Constants'
import Image from '../../atoms/Image'

function IconClose() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
      <title>close</title>
      <path d="M17.886 16l8.485 8.485-1.886 1.886-8.485-8.485-8.485 8.485-1.886-1.886 8.485-8.485-8.485-8.485 1.886-1.886 8.485 8.485 8.485-8.485 1.886 1.886z" />
    </svg>
  )
}

export const ArrowLeftIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <title>arrow_left</title>
      <path d="M10.454 14.667h16.211v2.667h-16.243l7.479 7.479-1.886 1.886-10.682-10.682 10.682-10.682 1.886 1.886z" />
    </svg>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(3, 15, 28, 0.79);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${ZIndex.ultraHigh};

  .ModalBox__ {
    background: ${(props) => props.theme.colors.white};
    width: 100%;
    height: 100%;
    border-radius: ${(props) => props.theme.borderRadius};
    padding-top: 72px;
    position: relative;

    &header {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background: ${(props) => props.theme.colors.primary};
      width: 100%;
      height: 72px;
      box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
      position: absolute;
      top: 0;

      &_title {
        display: none;
        font-size: 17px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.black};
      }

      &_logo {
        display: inherit;
        height: auto;
        width: 72px;
      }

      &_close-button {
        top: 0;
        left: 0;
        right: unset;
        position: absolute;
        height: 100%;
        margin: 0 16px;

        svg {
          fill: ${(props) => props.theme.colors.white};
          width: 24px;
        }
      }
    }

    &body {
      overflow: auto;
      height: 100%;
      ${hasScrollbarStyles}
    }
  }

  @media (${breakpoints.desktop.min}) {
    .ModalBox__ {
      width: 500px;
      max-height: 624px;

      &body {
        min-height: 552px;
      }
      &header {
        background: ${(props) => props.theme.colors.white};

        &_title {
          display: inherit;
        }

        &_logo {
          display: none;
        }

        &_close-button {
          right: 0;
          left: unset;

          svg {
            fill: ${(props) => props.theme.colors.black};
            width: 24px;
          }
        }
      }
    }
  }
`

interface Props {
  title: string
  children: ReactNode
  onClose?: () => void
  isOpen?: boolean
  noClose?: boolean
}

const ModalBox = (props: Props) => {
  const { title, children, isOpen = true, onClose, noClose } = props
  const { isDesktop } = useResolution()

  if (!isOpen) return null

  return (
    <Wrapper>
      <div className="ModalBox__">
        <div className="ModalBox__header">
          {isDesktop ? (
            <span className="ModalBox__header_title">{title}</span>
          ) : (
            <Image
              className="ModalBox__header_logo"
              src="https://images.ctfassets.net/16npdkkoi5mj/4EAocVvRjUs3wHYN7PiSDB/f89865bb82569f8a649d1c07fbfbfbd5/CCO_-_BLANCO_-_SVG.svg"
              alt="logo"
            />
          )}
          <Anchor
            data-test="modal-close"
            className="ModalBox__header_close-button"
            onClick={() => (!noClose && onClose ? onClose() : null)}
          >
            {isDesktop ? <IconClose /> : <ArrowLeftIcon />}
          </Anchor>
        </div>

        <RemoveScroll className="ModalBox__body">{children}</RemoveScroll>
      </div>
    </Wrapper>
  )
}

export default ModalBox
