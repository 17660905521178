import React, { useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { SelectOption } from '@ecommerce/shared'
import { sendMessageToSentry, ErrorLevel } from '@ecommerce/chile-customer-webapp/src/utils/sentry'
import useDebounce from '../../../hooks/useDebounce'
import Select from '../Select'
import TextField from '../TextField'
import { showToast } from '../Notification'
import { checkCustomerDniExist } from '../../../services/SignUp'
import { log } from '../../../utils/log'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`

interface Props {
  isVisible: boolean
  types: SelectOption[]
  selectedDocumentType: string
  setSelectedDocumentType: (selectedDocumentType: string) => void
  setIsCheckingDni: (setIsCheckingDni: boolean) => void
  dni?: string
  isRecovery?: boolean
  isGuest?: boolean
}

const DocumentType = ({
  isVisible,
  types,
  selectedDocumentType,
  setSelectedDocumentType,
  setIsCheckingDni,
  dni,
  isRecovery,
  isGuest = false,
}: Props) => {
  const methods = useFormContext()

  const { control, errors, setValue, setError, trigger, watch, clearErrors, getValues, reset } = methods

  const documentType = watch('documentType')
  const [dniValue, setDniValue] = useState(dni ?? '')

  const dniDebounce = useDebounce(dniValue, 1500)

  const formatDniBolivia = () => types.find((dt) => dt.value === documentType)?.label || types[0]?.label

  const checkDni = async () => {
    try {
      if (documentType !== 'otros') {
        if (dni !== getValues().dni) {
          const response = await checkCustomerDniExist({ dni: getValues().dni })
          if (response.status === 200) {
            if (response.data.customerExists && !isRecovery) {
              showToast({
                content: `Ya tenemos este ${formatDniBolivia()} asociado a una cuenta.`,
                title: `Ya existe este ${formatDniBolivia()}`,
                type: 'error',
              })
              setError('dni', {
                type: 'manual',
                message: `Ya tenemos este ${formatDniBolivia()} asociado a una cuenta.`,
              })
            }
            if (!response.data.customerExists && isRecovery) {
              showToast({
                content: `No hay una cuenta asociada a ese ${formatDniBolivia()}.`,
                title: `No tenemos ese ${formatDniBolivia()}`,
                type: 'error',
              })
              setError('dni', {
                type: 'manual',
                message: `No hay una cuenta asociada a ese ${formatDniBolivia()}.`,
              })
            }
          }
        }
      }
    } catch (error) {
      log.error('ERROR CHECKING DNI: ', error)
      showToast({
        content: `Ha ocurrido un error en la búsqueda del documento seleccionado`,
        title: `Ha ocurrido un error`,
        type: 'error',
      })
      sendMessageToSentry({
        message: `checkDni: ${error?.message ?? ''}`,
        level: ErrorLevel.Warning,
        page: 'editBolivia',
        metadata: { error: { ...error } },
      })
    }
    setIsCheckingDni(false)
  }

  useEffect(() => {
    if (dniDebounce !== '' && !isGuest) {
      checkDni()
    }
  }, [dniDebounce])

  const onDocumentTypeChange = async (selected: string) => {
    setSelectedDocumentType(selected)
    setValue('documentType', selected, { shouldDirty: true })
    reset({
      ...getValues(),
      dni: undefined,
      dniComplement: undefined,
    })
    setDniValue('')
    await trigger('documentType')
  }

  const onDniChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors('dni')
    const { value } = event.target
    setValue('dni', value, { shouldDirty: true })
    await trigger('dni')
    setDniValue(value)
    if (documentType !== 'otros') {
      setIsCheckingDni(true)
    }
  }

  const onDniComplementChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue('dniComplement', value, { shouldDirty: true })
    await trigger('dniComplement')
  }

  return (
    <Container>
      {isVisible && (
        <Controller
          control={control}
          name="documentType"
          render={({ value }) => (
            <Select
              label={isRecovery ? 'Método de recuperación' : 'Documento de identificación'}
              disabled={!isVisible}
              placeholder={
                !isRecovery ? types.find((dt) => dt.value === value)?.label || types[0].label : 'Seleccionar'
              }
              options={types}
              onSelect={(selected) => onDocumentTypeChange(selected)}
            />
          )}
        />
      )}

      {selectedDocumentType && (
        <Controller
          control={control}
          name="dni"
          render={({ value }) => (
            <TextField
              label={formatDniBolivia()}
              status={!errors.dni ? undefined : 'error'}
              errorMessage={errors.dni?.message}
              value={dniValue ?? ''}
              type={documentType === 'ci' || documentType === 'nit' ? 'number' : 'text'}
              disabled={!isVisible}
              onChange={onDniChange}
              min="1"
            />
          )}
        />
      )}

      {(getValues().dniComplement || (isVisible && selectedDocumentType === 'ci')) && !isRecovery && (
        <Controller
          name="dniComplement"
          control={control}
          render={({ value }) => (
            <TextField
              label="CI complemento (opcional)"
              status={!errors.dniComplement ? undefined : 'error'}
              errorMessage={errors.dniComplement?.message}
              value={value ?? ''}
              disabled={!isVisible}
              onChange={onDniComplementChange}
              helpMessage="Complemento al número de CI"
            />
          )}
        />
      )}
    </Container>
  )
}

export default DocumentType
