import { Country, PaymentTypes, PaymentMethodsObject } from '../../types'
import { getChilePaymentUrl, initPaymentBolivia } from './index'
import { City } from '../../..'
import { OrderStatus } from '../CommerceLayer'

export type PaymentOption = {
  id: number
  name: string
  icons: string[]
}

export type PaymentMethod = {
  name: string
  options: PaymentOption[]
}

export type CreatePaymentResponse = {
  payment_id: string
  payment_url?: string
  qr?: string
}

export type GetPaymentStatusResponse = {
  status: string
  orderId: string
  amount: string
  qrCode?: string
  qrCodeRaw?: string
  shippingDate?: string
  orderNumber: number
  orderStatus: OrderStatus
}

export const formatPaymentMethods = (methods: PaymentMethod[]): PaymentMethodsObject => {
  const formattedMethods: PaymentMethodsObject = {
    types: [],
    options: [],
  }
  const paymentMethodsRegex = /(Cybersource|Webpay)/g

  methods.forEach(({ options, name: methodName }) =>
    options.forEach(({ id, name }) => {
      if (paymentMethodsRegex.test(methodName)) {
        formattedMethods.types.push(
          {
            label: 'Pago en línea',
            value: PaymentTypes.REDENLACE,
            checked: true,
          },
          {
            label: 'Pago contra entrega',
            value: PaymentTypes.CONTRA_ENTREGA,
          },
        )
      }

      if (paymentMethodsRegex.test(name) || paymentMethodsRegex.test(methodName)) {
        formattedMethods.options.push(
          { value: id, label: 'Tarjeta de débito' },
          { value: id, label: 'Tarjeta de crédito' },
        )
      } else if (methodName === 'API-Market' && options && options[0]?.id) {
        formattedMethods.types[1].QRID = options[0].id
      } else {
        formattedMethods.options.push({ value: id, label: name })
      }
    }),
  )

  return formattedMethods
}

function postForm(url: string, data: Record<string, string>) {
  const form = document.createElement('form')
  document.body.appendChild(form)
  form.method = 'post'
  form.action = url
  Object.entries(data).forEach(([key, value]) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = key
    input.value = value
    form.appendChild(input)
  })
  form.submit()
}

export interface RedirectToPaymentGatewayParams {
  countryCode: Country
  orderId: string
  currentCity: City
  sessionId: string
  beforeRedirectCallback?: () => Promise<void>
  sahcUrl: string // sahc stands for secure acceptance hosted checkout
}

export const payInChile = async (params: RedirectToPaymentGatewayParams) => {
  const paymentUrl = await getChilePaymentUrl(params.orderId)
  if (params.beforeRedirectCallback) await params.beforeRedirectCallback()
  window.location.replace(paymentUrl)
}

export const payInBolivia = async (params: RedirectToPaymentGatewayParams) => {
  const formFields = await initPaymentBolivia(params.orderId, params.sessionId)
  if (params.beforeRedirectCallback) await params.beforeRedirectCallback()
  postForm(params.sahcUrl, formFields)
}
