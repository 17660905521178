import React from 'react'
import styled, { keyframes } from 'styled-components'

const opacityAnimation = keyframes`
	0% {
		opacity: 1;
	}
	50%{
		opacity: 0.45;
	}
	100% {
		opacity: 1;
	}
`

const SkeletonOption = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-right: none;
  border-left: none;
  padding: 12px 0;
  position: relative;
  height: 40px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 14px;
    left: 0;
    top: 12px;
    background: ${({ theme }) => theme.colors.black10};
    opacity: 1;
    animation: ${opacityAnimation} 1.5s linear infinite;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    height: 28px;
  }
`

const SkeletonGroup = () => (
  <>
    {[...Array(7)].map((e, i) => (
      <SkeletonOption key={i} />
    ))}
  </>
)

export default SkeletonGroup
