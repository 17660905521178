import { Severity, Event, EventHint, init, captureMessage, getCurrentHub } from '@sentry/browser'
import { Dsn } from '@sentry/types'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { ProductCart, getStoredCity, isValidJSON, log } from '@ecommerce/shared'
import secrets from '../config/secrets'

declare const window: Window & { sentryIsInitialized?: boolean }

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

export enum ErrorSource {
  CLayer = 'CommerceLayer',
  BFF = 'BFF',
  SIGNUP_SERVICE = 'signup-service',
  Firestore = 'Firestore',
}

type ErrorParams = {
  message: string
  level?: Severity
  page?: string
  source?: ErrorSource
  metadata?: Record<string, any>
  tags?: Record<string, string>
  attachment?: {
    includeStorage?: boolean
    data?: Record<string, unknown>
  }
}

type Hint = EventHint & {
  originalException: Error | any
}

const ignoreErrors = [
  'initMaps is not a function',
  'target.current.contains is not a function',
  't.current.contains is not a function',
  'SecurityError: Blocked a frame with origin "https://embonor.micoca-cola.cl" from accessing a cross-origin frame.',
  'Blocked a frame with origin "https://embonor.micoca-cola.cl" from accessing a cross-origin frame.',
  'Non-Error promise rejection captured with value: Timeout',
  'Geocoder failde due to OVER_QUERY_LIMIT',
  'User not found',
  'On reset pass: Request failed with status code 400',
  'Loading chunk',
  'onGuest request error: Network Error',
]

const ignoreNetworkErrors = true

const shouldSendError = (event: Event) => {
  if (ignoreErrors.includes(event?.message ?? '')) return false
  if (
    event?.exception?.values &&
    event?.exception?.values?.length &&
    event?.exception?.values?.some(
      (exception) => exception.type === 'TypeError' || ignoreErrors.includes(exception?.value ?? ''),
    )
  ) {
    return false
  }

  return true
}

const beforeSendError = (e: Event, hint?: Hint): Event | null => {
  if (!shouldSendError(e)) return null

  if (!e.tags?.source || !e.message) {
    e.level = Severity.Info
  }

  if (hint?.originalException?.response?.data?.errors) {
    e.message = hint.originalException.response.data.errors[0]?.detail
    e.tags = { ...e.tags, source: ErrorSource.CLayer }

    if (e.exception) e.exception.values = [{ value: e.message, type: ErrorSource.CLayer }]
  }

  if (hint?.originalException?.config?.data && isValidJSON(hint?.originalException.config.data)) {
    e.contexts = {
      ...e.contexts,
      metadata: {
        request_payload: JSON.parse(hint?.originalException?.config?.data ?? '{}'),
      },
    }
  }

  return e
}

export const fireSentry = () => {
  if (!window.sentryIsInitialized) {
    init({
      ignoreErrors,
      dsn: process.env.GATSBY_SENTRY_DSN,
      environment: activeEnv,
      release: secrets.APP_VERSION,
      enabled: (() => ['prod', 'staging'].indexOf(activeEnv) !== -1)(),
      beforeSend: beforeSendError,
      normalizeDepth: 0,
      tracesSampleRate: 0.1,
      integrations: [new TracingIntegrations.BrowserTracing()],
      initialScope: {
        tags: { country: secrets.COUNTRY },
      },
    })

    window.sentryIsInitialized = true
  }
}

const attachmentUrlFromDsn = (dsn: Dsn, eventId: string) => {
  const { host, path, projectId, port, protocol, user } = dsn
  return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
    path !== '' ? `/${path}` : ''
  }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`
}

const sendAttachment = async (eventId: string, payload: Record<string, unknown>) => {
  try {
    const client = getCurrentHub().getClient()
    const dsn = client?.getDsn()

    if (dsn) {
      const endpoint = attachmentUrlFromDsn(dsn, eventId)
      const formData = new FormData()

      formData.append(
        `attachment-${eventId}`,
        new Blob([JSON.stringify(payload)], {
          type: 'application/json',
        }),
        `log-${eventId}.json`,
      )

      await fetch(endpoint, {
        method: 'POST',
        body: formData,
      })
    }
  } catch (error) {
    log.error(`On send attachment to Sentry`, error)
  }
}

export const sendMessageToSentry = ({
  message,
  page,
  source,
  level = Severity.Info,
  metadata = {},
  tags = {},
  attachment,
}: ErrorParams) => {
  if (ignoreErrors.some((err) => message.includes(err))) return
  if (
    ignoreNetworkErrors &&
    (message.toUpperCase().includes('NETWORK ERROR') ||
      (metadata?.error?.message?.toUpperCase && metadata.error.message.toUpperCase().includes('NETWORK ERROR')))
  ) {
    return
  }

  const eventId = captureMessage(message, {
    level,
    contexts: {
      metadata: { page, message, source, ...metadata },
    },
    tags: { source: source || 'b2c', ...tags },
  })

  if (eventId && attachment) {
    const attachmentData = {
      message,
      page,
      source,
      metadata,
      tags,
      data: attachment?.data,
      localStorage: attachment.includeStorage
        ? Object.keys(window.localStorage).reduce<Record<string, unknown>>((curr = {}, key) => {
            const data = window.localStorage.getItem(key)

            return { [key]: isValidJSON(data) ? JSON.parse(data ?? '{}') : data, ...curr }
          }, {})
        : null,
    }

    sendAttachment(eventId, attachmentData)
  }
}

export const sendStockReports = ({ products, section }: { products: ProductCart[]; section: string }) => {
  const skus = products.map((p) => p.skuCode).join()
  const city = getStoredCity()

  return sendMessageToSentry({
    message: `Stock event on: ${section}`,
    level: Severity.Warning,
    source: ErrorSource.CLayer,
    page: section,
    metadata: { products, skus, city },
    tags: { 'incident.type': 'stock' },
  })
}

export { Severity as ErrorLevel }
