import React, { useState } from 'react'
import { navigate } from 'gatsby'
import loadable from '@loadable/component'
import ModalBox from '../../molecules/ModalBox'
import { Wrapper } from './styled'
import { getStoredCity } from '../../../utils/store'
import { Props } from './libs'
import { AuthFormType } from '../../../types'

const FormGuest = loadable(() => import('../FormGuest'))
const FormSignUp = loadable(() => import('../FormSignUp'))
const FormSignIn = loadable(() => import('../FormSignIn'))

const AuthWidget = (widgetProps: Props) => {
  const { title, onClose, noClose, showWidget, closeWidget, useModal = true } = widgetProps

  const [isLoading, setIsLoading] = useState(false)
  const [boxTitle, setBoxTitle] = useState<string>()

  const modalBoxProps = { onClose, title: boxTitle || title }

  const mergeProps = <T extends Record<string, unknown>>(currentProps: T) => ({
    ...currentProps,
    onClickSignUp: () => showWidget(AuthFormType.SIGNUP),
    onClickSignIn: () => showWidget(AuthFormType.SIGNIN),
    onCloseModal: () => closeWidget(),
    onSend: () => {
      if (widgetProps.formSettings.props?.onSend) widgetProps.formSettings.props.onSend()

      setIsLoading(true)
    },
    onError: () => {
      if (widgetProps.formSettings.props?.onError) widgetProps.formSettings.props.onError()

      setIsLoading(false)
    },
    onClickSignUpConfirm: () => {
      setBoxTitle('Confirma tu cuenta')
      setIsLoading(false)
    },
    onClickResetPassword: async (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      const currentCity = getStoredCity()
      await navigate(currentCity && currentCity?.slug ? `/${currentCity.slug}/reset-password` : '/')
    },
    setLoading: (loading: boolean) => setIsLoading(loading),
    loading: isLoading,
  })

  const renderForm = () => {
    switch (widgetProps.formSettings.type) {
      case AuthFormType.GUEST: {
        return <FormGuest {...mergeProps(widgetProps.formSettings.props)} />
      }

      case AuthFormType.SIGNUP: {
        return <FormSignUp {...mergeProps(widgetProps.formSettings.props)} />
      }

      case AuthFormType.SIGNIN: {
        return <FormSignIn {...mergeProps(widgetProps.formSettings.props)} />
      }

      default:
        return null
    }
  }

  if (!useModal) return <Wrapper>{renderForm()}</Wrapper>

  return (
    <ModalBox noClose={noClose || isLoading} {...modalBoxProps}>
      <Wrapper>{renderForm()}</Wrapper>
    </ModalBox>
  )
}

export default AuthWidget
