import React from 'react'
import styled from 'styled-components'

type Props = {
  title: string
  icon: React.ReactNode
  buttonIcon?: React.ReactNode
  onButtonClick?: (args: unknown) => unknown
  className?: string
}

const StyledCardTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  min-height: 60px;
  background: ${({ theme }) => theme.colors.medium50};
  width: 100%;

  svg {
    margin-right: 16px;
    width: 30px;
    height: 30px;

    path {
      fill: ${({ theme }) => theme.colors.black};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.black};
    font-size: 18px;
    width: 100%;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 25px;
    height: 25px;

    svg {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const CardTitle = ({ title, icon, buttonIcon, onButtonClick, className }: Props) => (
  <StyledCardTitle className={className}>
    {icon}
    <span>{title}</span>
    {buttonIcon ? (
      <button type="button" onClick={onButtonClick}>
        {buttonIcon}
      </button>
    ) : null}
  </StyledCardTitle>
)

export default CardTitle
