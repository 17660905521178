import { ProductCart, Product } from '../types'
import { log } from './log'
import { toCurrencyNumber } from './i18n'
import { capitalize } from './format'

interface EventGMT extends Record<string, unknown | undefined> {
  event: string
  pageTitle?: string
  pageUrl?: string
  actionField?: Record<string, any>
}

const ecommerceClearEvent = {
  ecommerce: null,
}

const formatActionFieldObject = (actionField: Record<string, any>) => {
  const list = actionField.list ? { list: capitalize(actionField.list, true) } : {}
  const option = actionField.option ? { option: capitalize(actionField.option, true) } : {}
  return { ...actionField, ...list, ...option }
}

type BannerEventParams = {
  campaign: string
  position: number
  city: string
}

declare let window: Window & {
  dataLayer: { push: (eventObj: EventGMT | typeof ecommerceClearEvent) => void } | undefined
}

function dataLayerPush(data: EventGMT, logLabel?: string) {
  log.info(logLabel ?? 'dataLayer.push', data)
  if (window && window.dataLayer) {
    window.dataLayer.push(ecommerceClearEvent)
    window.dataLayer.push(data)
  }
}

export function sendDataToGTM({ pageUrl = window.location.href, ...restEvent }: EventGMT) {
  dataLayerPush({ ...restEvent, pageUrl }, `sendDataToGTM - ${restEvent.event}`)
}

export function sendDataWithTitleToGTM({ pageUrl = window.location.href, ...restEvent }: EventGMT, label?: string) {
  dataLayerPush(
    { ...restEvent, pageUrl, pageTitle: document.title },
    `${label ?? 'sendDataWithTitleToGTM'} - ${restEvent.event}`,
  )
}

export function sendProductsToGTM(args: EventGMT & { products: ProductCart[] }) {
  const { pageUrl = window.location.href, products, actionField, ...restEvent } = args

  dataLayerPush(
    {
      ...restEvent,
      pageUrl,
      products: products.map((product) => ({
        title: product.title,
        price: product.price,
        quantity: product.quantity,
        categoryName: product.categoryName,
        brandName: product.brandName,
      })),
      ...(args.event
        ? {
            ecommerce: {
              [args.event]: {
                ...(actionField ? { actionField: formatActionFieldObject(actionField) } : {}),
                products: products.map((product) => ({
                  name: product.title,
                  id: product.skuCode,
                  price: `${product.price}`,
                  brand: product.brandName ?? null,
                  category: product.categoryName ?? null,
                  variant: product.size ?? null,
                  quantity: product.quantity,
                })),
              },
            },
          }
        : {}),
    },
    `sendProductsToGTM - ${restEvent.event}`,
  )
}

export function sendCheckoutEventToGTM(products: ProductCart[], actionField?: EventGMT['actionField']) {
  sendProductsToGTM({
    event: 'checkout',
    products,
    ...(actionField ? { actionField: formatActionFieldObject(actionField) } : {}),
  })
}

export function sendAddProductToGTM(product: Product, isBolivia: boolean, quantity?: number) {
  sendDataWithTitleToGTM({
    event: 'addToCart',
    categoryName: product.categoryName,
    title: product.title,
    price: product.price,
    brandName: product.brandName,
    ecommerce: {
      currencyCode: isBolivia ? 'BOB' : 'CLP',
      add: {
        products: [
          {
            name: product.title,
            id: product.skuCode,
            price: `${toCurrencyNumber(product.price, isBolivia)}`,
            brand: product.brandName,
            category: product.categoryName ?? null,
            variant: product.size ?? null,
            quantity: quantity ?? 1,
          },
        ],
      },
    },
  })
}

export function sendRemoveProductToGTM(products: (Product & { quantity?: number })[], isBolivia: boolean) {
  sendDataWithTitleToGTM({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: products.map((product) => ({
          name: product.title,
          id: product.skuCode,
          price: `${toCurrencyNumber(product.price, isBolivia)}`,
          brand: product.brandName,
          category: product.categoryName ?? null,
          variant: product.size ?? null,
          quantity: product.quantity ?? 1,
        })),
      },
    },
  })
}

export function sendClickProductToGTM(product: Product, productListName: string, isBolivia: boolean) {
  sendDataWithTitleToGTM({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: capitalize(productListName, true) },
        products: [
          {
            name: product.title,
            id: product.skuCode,
            price: `${toCurrencyNumber(product.price, isBolivia)}`,
            brand: product.brandName,
            category: product.categoryName ?? null,
            variant: product.size ?? null,
          },
        ],
      },
    },
  })
}

export function sendProductImpressionsToGTM(
  args: Partial<EventGMT> & { products: Product[]; currencyCode: string; list?: string },
) {
  const { products, currencyCode, list, ...restEvent } = args

  dataLayerPush(
    {
      ...restEvent,
      event: 'impressions',
      ecommerce: {
        ...(currencyCode ? { currencyCode } : {}),
        impressions: products.map((product, idx) => ({
          name: product.title,
          id: product.skuCode,
          price: `${product.price}`,
          brand: product.brandName ?? null,
          category: product.categoryName ?? null,
          variant: product.size ?? null,
          position: idx + 1,
          ...(list ? { list: capitalize(list, true) } : {}),
        })),
      },
    },
    `sendProductImpressionsToGTM`,
  )
}

export function sendCheckoutOptionToGTM(actionName: string, actionValue?: string) {
  sendDataWithTitleToGTM({
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: {
          step: actionName,
          ...(actionValue ? { option: actionValue } : {}),
        },
      },
    },
  })
}

export function sendPromoViewToGTM(promotions: { id: string; name: string; creative: string; position: string }[]) {
  sendDataWithTitleToGTM(
    {
      event: 'BannerImpressionTracking',
      ecommerce: {
        promoView: { promotions },
      },
    },
    'sendBannerImpressionTrackingToGTM',
  )
}

export function sendPromoClickToGTM(promotion: { id: string; name: string; creative: string; position: string }) {
  sendDataWithTitleToGTM(
    {
      event: 'promotionClick',
      ecommerce: {
        promoClick: { promotions: [promotion] },
      },
    },
    'sendPromoClickToGTM',
  )
}

export const sendBannerEventToGTM = ({ campaign, position, city }: BannerEventParams) => {
  return sendDataToGTM({
    event: 'banner',
    'b-campaña': campaign,
    'b-pos': position,
    'b-ciudad': city,
  })
}
