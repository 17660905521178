import React, { useState, useEffect, ReactNode } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import CardTitle from './CardTitle'

type Props = {
  icon?: ReactNode
  label: string
  children: React.ReactElement | React.ReactElement[]
  isOpen?: boolean
  className?: string
  onClick?: () => void
  customMaxHeight?: number
}

/* Minus icon */
const IconSubstract = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 32 32">
    <path d="M29.333 14.667v2.667h-26.667v-2.667z" />
  </svg>
)

/* Plus icon */
const IconAdd = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 32">
    <path d="M16.744 14.669h9.336v2.075h-9.336v9.336h-2.075v-9.336h-9.336v-2.075h9.336v-9.336h2.075z" />
  </svg>
)

const CollapsibleContainer = styled.div`
  width: 100%;
`

const Collapse = ({ icon, label, children, isOpen = false, className, onClick, customMaxHeight }: Props) => {
  const [isShown, setIsShown] = useState(isOpen)
  const [maxHeight, setMaxHeight] = useState(customMaxHeight ?? 0)

  const ref = React.useRef<HTMLElement>(null)

  const handleMaxHeight = () => {
    if (!customMaxHeight) setMaxHeight((ref.current?.clientHeight ?? 0) + 100)
  }
  const handleClick = () => setIsShown((prev) => !prev)

  useEffect(() => setIsShown(isOpen), [isOpen])
  useEffect(handleMaxHeight, [children, customMaxHeight])

  const overflow = isShown ? 'visible' : 'hidden'

  return (
    <CollapsibleContainer className={className}>
      <CardTitle
        icon={icon}
        title={label}
        buttonIcon={isShown ? IconSubstract : IconAdd}
        onButtonClick={onClick ?? handleClick}
      />
      <motion.div
        transition={{ duration: 0.2 }}
        initial={{ overflow }}
        animate={{
          maxHeight: isShown ? `${maxHeight}px` : '0px',
          opacity: isShown ? 1 : 0,
          overflow,
        }}
        className="Collapsible__display-content"
      >
        {React.Children.map(children, (child) => React.cloneElement(child, { ref }))}
      </motion.div>
    </CollapsibleContainer>
  )
}

export default Collapse
