import { ShoppingCartState } from './context'
import reducer, { ShoppingCartActions } from './reducer'
import { getCartId, getStoredCity } from '../../utils/store'

export default (pushCartStateToFirestore: (fsCartId: string, nextState: ShoppingCartState) => void) => (
  prevState: ShoppingCartState,
  action: ShoppingCartActions,
) => {
  const nextState = reducer(prevState, action)

  const sync = () => {
    const fsCartId = getCartId()
    if (fsCartId) {
      pushCartStateToFirestore(fsCartId, nextState)
    }
    return nextState
  }

  // Managed this way for future per-action logic
  switch (action.type) {
    case 'ADD_PRODUCT':
    case 'REMOVE_PRODUCT':
    case 'REMOVE_PRODUCT_ASYNC':
    case 'SUBTRACT_PRODUCT':
    case 'SET_ITEM_STOCK':
    case 'SET_PRODUCT_QUANTITY':
    case 'APPLY_COUPON':
    case 'EMPTY': {
      return sync()
    }

    default:
      return nextState
  }
}
