import { AxiosResponse } from 'axios'
import client from './client'
import { toWeekDay } from '../../utils/formatDate'

type Country = 'CL' | 'BO'
type Params = { lat: number; long: number; id: number; country: Country }

type Capacity = { currentCapacity: number; date: string; maxCapacity: number }
export type AvailableDate = {
  value: string
  label: string
  weekDay: number
}

/**
 * Check if the address is in range
 * @param lat - latitude
 * @param long - longitude
 * @param id - zoneID
 */
const checkAddress = async ({ lat, long, id, country }: Params): Promise<boolean> => {
  const { data } = await client(`/cities/${id}/check-limit`, {
    method: 'GET',
    headers: { country },
    params: {
      latitude: lat,
      longitude: long,
    },
  })

  return data.valid
}

/**
 * Get available dates for delivery
 * @param {string} cityID
 * @param {Country} country
 */
const getAvailableDates = async (cityID: number, country: Country): Promise<AvailableDate[]> => {
  try {
    const { data }: AxiosResponse<{ capacities: Capacity[] }> = await client(`/cities/${cityID}/capacity`, {
      method: 'GET',
      headers: { country },
    })

    // Parse dates
    const parsedDates = data.capacities.map(({ date }: { date: string }) => {
      const { label, weekDay } = toWeekDay(date)

      return { value: date, label, weekDay }
    })

    return parsedDates
  } catch (error) {
    throw new Error(error)
  }
}

export { checkAddress, getAvailableDates }
