import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import SkeletonGroup from './SkeletonGroup'
import { baseSelect } from '../../../styles/baseForms'
import InfoMessage from '../../atoms/InfoMessage'
import { Label, Status } from '../TextField/Styled'
import { SelectOption } from '../../../types'
import { hasScrollbarStyles } from '../../../styles/hasScrollbar'

type Props<T = any> = {
  label?: string
  name?: string
  placeholder: string
  options: SelectOption<T>[]
  onSelect: (selected: T, selectedItem?: SelectOption) => unknown
  changeText?: React.ReactNode
  isExpanded?: boolean
  className?: string
  errorMessage?: string
  status?: Status
  dataTest?: string
  optionContainerTail?: (option: SelectOption<T>) => React.ReactNode
  onOptionsToggle?: (isOpen: boolean) => void
  disabled?: boolean
}

const Wrapper = styled.div<{ status?: Status }>`
  width: 100%;
  position: relative;

  ${({ status, theme }) => {
    function checkStatus(defaultColor: string) {
      if (status === 'success') return theme.colors.success

      if (status === 'error') return theme.colors.error

      return defaultColor
    }

    return css`
      width: 100%;

      ${Label} {
        color: ${checkStatus(theme.colors.black)};
      }
    `
  }};

  .TextField__help {
    color: ${({ theme }) => theme.colors.error};

    svg {
      fill: ${({ theme }) => theme.colors.error};
    }
  }
`

const SelectHandle = styled.div<{ isSelected: boolean; status?: Status; isDisabled?: boolean; hiddenIcon?: boolean }>`
  ${baseSelect}
  ${({ isSelected, hiddenIcon }) => (isSelected && hiddenIcon ? 'background-image: none;' : '')}
	display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 48px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.medium75 : theme.colors.white)};
  ${({ status, theme }) => (status === 'error' ? `border: 1px solid ${theme.colors.error};` : '')}

  span {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 15px;

    &.Select__change-text {
      position: absolute;
      right: 20px;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 14px;
    }
  }
`

const OptionsWrapper = styled.div<{ showContent: boolean }>`
  width: 100%;
  max-height: 220px;
  overflow-y: scroll;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow.lvlOne};
  padding: 12px;
  position: absolute;
  left: 0;
  top: 56px;
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: 0.15s;
  opacity: ${({ showContent }) => (showContent ? '1' : '0')};
  z-index: ${({ showContent }) => (showContent ? '1' : '-1')};
  ${hasScrollbarStyles}
`

const OptionContainer = styled.div<{ isSelected: boolean }>`
  min-height: 38px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
  margin-bottom: 10px;
  background: ${({ isSelected, theme }) => (isSelected ? theme.colors.medium : 'transparent')};

  &:hover {
    background: ${({ theme }) => theme.colors.medium};
  }

  span {
    color: ${({ theme }) => theme.colors.black};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 16px;
    top: calc(50% - 7.5px);
    width: 7px;
    height: 15px;
    border: solid ${({ theme }) => theme.colors.primary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: 0.15s;
    transition-delay: 0.15s;
    opacity: ${({ isSelected }) => (isSelected ? '1' : '0')};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const Select = ({
  name,
  label: selectLabel,
  className,
  placeholder,
  onSelect,
  options = [],
  isExpanded = false,
  changeText,
  errorMessage,
  status,
  optionContainerTail,
  dataTest,
  onOptionsToggle,
  disabled = false,
}: Props) => {
  const [isShown, setIsShown] = useState(isExpanded)
  const [displayText, setDisplayText] = useState(placeholder)
  const [isSelected, setIsSelected] = useState(false)

  const toggleIsShown = (isOpen: boolean) => {
    if (onOptionsToggle) onOptionsToggle(isOpen)
    setIsShown(isOpen)
  }

  const handleOption = (label: string, value: string | number): void => {
    // Hide options
    toggleIsShown(false)

    // Update displayText if option is diferent
    if (displayText !== label) setDisplayText(label)

    // Update if an option has been selected
    if (!isSelected) setIsSelected(true)

    // Return callback
    onSelect(value, { label, value })
  }

  const MappedOptions = options.map((option, idx) => {
    const { label, value } = option
    return (
      <OptionContainer
        className="Select__option-container"
        isSelected={displayText === label}
        key={`${idx} - ${value}`}
        onClick={() => handleOption(label, value)}
      >
        <span>{label}</span>
        {optionContainerTail && optionContainerTail(option)}
      </OptionContainer>
    )
  })

  return (
    <Wrapper data-test={dataTest} status={status} className={`${className} status--${status}`}>
      <Label className="Select__label" htmlFor={name}>
        {selectLabel}
      </Label>
      <SelectHandle
        isDisabled={disabled}
        className="Select__handle"
        status={status}
        isSelected={isSelected}
        onClick={() => toggleIsShown(!isShown)}
        hiddenIcon={!!changeText}
      >
        <span>{displayText}</span>
        {isSelected && changeText ? <span className="Select__change-text">{changeText ?? 'Cambiar'}</span> : null}
      </SelectHandle>
      <OptionsWrapper className={`Select__options ${className ? `${className}-options` : ''}`} showContent={isShown}>
        {options?.length !== 0 ? MappedOptions : <SkeletonGroup />}
      </OptionsWrapper>
      <InfoMessage className="TextField__help" isHidden={!errorMessage} message={errorMessage} />
    </Wrapper>
  )
}

export default Select
