import { AuthState } from './context'

export type SetUserParams = {
  ownerId: string
  email: string
  dni: string
  firstName: string
  lastName: string
  birthdate: string
  city: number
  registered: boolean
  favoriteSkus?: number[]
  availabilitySkus?: number[]
  documentType: string
  billingName: string
  dniComplement: string
  isUpdated: boolean
}

type BEGINNING = { type: 'BEGINNING' }
type FINISHED = { type: 'FINISHED' }
type SIGN_IN = { type: 'SIGN_IN' }
type SET_USER = {
  type: 'SET_USER'
} & SetUserParams
type RESET = { type: 'RESET' }
type HAS_TOKEN = { type: 'HAS_TOKEN' }
type SET_IS_LOADING = { type: 'SET_IS_LOADING'; isLoading: boolean }
type SET_GUEST = { type: 'SET_GUEST'; firstName: string; lastName: string }
type HAS_ANONYMOUS_TOKEN = { type: 'HAS_ANONYMOUS_TOKEN' }
type SET_FAVORITE_SKUS = { type: 'SET_FAVORITE_SKUS'; skus: number[] }
type SET_AVAILABILITY_SKUS = { type: 'SET_AVAILABILITY_SKUS'; skus: number[] }

export type AuthActions =
  | BEGINNING
  | FINISHED
  | SIGN_IN
  | SET_USER
  | RESET
  | HAS_TOKEN
  | SET_IS_LOADING
  | SET_GUEST
  | HAS_ANONYMOUS_TOKEN
  | SET_FAVORITE_SKUS
  | SET_AVAILABILITY_SKUS

function reducer(state: AuthState, action: AuthActions): AuthState {
  switch (action.type) {
    case 'BEGINNING': {
      return { ...state, isLoading: true }
    }

    case 'FINISHED': {
      return { ...state, isLoading: false }
    }

    case 'SIGN_IN':
      return {
        ...state,
        isAuth: true,
      }

    case 'SET_USER':
      return <AuthState>{
        ...state,
        ownerId: action.ownerId,
        email: action.email,
        dni: action.dni,
        firstName: action.firstName,
        lastName: action.lastName,
        birthdate: action.birthdate,
        city: action.city,
        registered: action.registered,
        favoriteSkus: action.favoriteSkus ?? [],
        availabilitySkus: action.availabilitySkus ?? [],
        documentType: action.documentType,
        billingName: action.billingName,
        dniComplement: action.dniComplement,
        isUpdated: action.isUpdated ?? false,
      }

    case 'HAS_TOKEN': {
      return { ...state, hasToken: true }
    }

    case 'HAS_ANONYMOUS_TOKEN': {
      return { ...state, hasAnonymousToken: true }
    }

    case 'SET_FAVORITE_SKUS': {
      return { ...state, favoriteSkus: [...new Set(action.skus ?? [])] }
    }

    case 'SET_AVAILABILITY_SKUS': {
      return { ...state, availabilitySkus: [...new Set(action.skus ?? [])] }
    }

    case 'SET_GUEST': {
      return { ...state, guest: { firstName: action.firstName, lastName: action.lastName } }
    }

    case 'RESET':
      return AuthState

    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.isLoading }

    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}

export default reducer
