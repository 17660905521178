import React from 'react'
import styled from 'styled-components'
import { RadioInput as Input } from '../../../types'

type Props = {
  items: Input[]
  name: string
  className?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>, label?: string) => void | unknown
  dataTest?: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;

  span {
    color: ${({ theme }) => theme.colors.black};
  }

  p {
    width: 100%;
    font-size: 15px;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.black};
  }
`

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  background: transparent;
  cursor: pointer;
  appearance: none;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 50%;
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  margin-right: 16px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.colors.primary};
    left: calc(50% - 4px);
    top: calc(50% - 4px);
    border-radius: 50%;
    opacity: 0;
    transition: 0.05s;
  }

  &:checked {
    &::after {
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }
`

const RadioButtons = ({ items, onChange, name, className, dataTest }: Props) => (
  <Wrapper className={className}>
    {items?.map(({ value, label, checked, descriptionText }, index) => (
      <InputWrapper key={label}>
        <RadioInput
          data-test={`${dataTest}-${index}`}
          onChange={(e) => onChange(e, label)}
          value={value}
          name={name}
          defaultChecked={checked}
        />
        <span>{label}</span>
        {descriptionText && <p>{descriptionText}</p>}
      </InputWrapper>
    ))}
  </Wrapper>
)

export default RadioButtons
