import { AxiosResponse } from 'axios'
import fetch from './fetch'
import { Product, ProductPromotion } from '../../types'
import { City } from '../Location/cities'
import { slugify } from '../../utils/format'
import { log } from '../../utils/log'

export interface AlgoliaProduct extends Product {
  sku: string
  name: string
  imgHeroHi: string
  contentfulImages?: string[]
  imageUrl?: string
  imagePreviewUrl?: string
  location?: string
  returnability?: string
  mainCategoryName?: string
  size?: string
  packagingName?: string
  itemType?: string
  slugLocation: string
  price: number
  originalPrice: number
  hasDiscount: boolean
  brandName?: string
  promotion?: ProductPromotion
  hasStock: boolean
  johnnieWalkerTag?: string
  isDummy?: boolean
}

interface AlgoliaResponse<T> {
  hits: T[]
  page: number
  nbPages: number
  hitsPerPage: number
}

type ProductResponse = AlgoliaResponse<AlgoliaProduct>

export interface FormattedProduct {
  skuCode: string
  image: string
  title: string
  price: number
  rawPrice: number
  hasDiscount: boolean
  discount?: number
  recyclable: boolean
  categoryName?: string
  brandName?: string
  size?: string
  packing?: string
  isBundle: boolean
  slugLocation: string
  originalPrice: number
  location?: string
  promotion?: ProductPromotion
  johnnieWalkerTag?: string
  isCustomizable?: boolean
  thumbnail?: string
  netContent?: number
  returnabilityLabelType?: string
  unavailable?: boolean
  isDummy?: boolean
}

export type AlgoliaAttributes = { hitsPerPage?: number; page?: number; facetFilters?: string[][] }

const toFormattedProduct = (product: AlgoliaProduct, includeLocation?: boolean) => {
  const productImage =
    product.imageUrl || (product.contentfulImages ? product.contentfulImages?.[0] : product.imgHeroHi)

  const percent =
    product.originalPrice && product.originalPrice !== product.price
      ? Math.round(((product.originalPrice - product.price) / product.originalPrice) * 100)
      : undefined
  const formattedProduct: FormattedProduct = {
    skuCode: product.sku,
    image: productImage,
    thumbnail: product.imagePreviewUrl || productImage,
    title: product.name,
    price: product.price,
    rawPrice: product.originalPrice && product.originalPrice !== product.price ? product.originalPrice : 0,
    hasDiscount: product.hasDiscount,
    discount: percent,
    recyclable: product.returnability === 'RETORNABLE',
    categoryName: product.mainCategoryName,
    brandName: product.brandName,
    size: product.size,
    netContent: product.netContent,
    packing: product.packagingName,
    isBundle: product.itemType === 'bundle',
    slugLocation: product.slugLocation,
    originalPrice: product.originalPrice,
    returnabilityLabelType: product.returnabilityLabelType,
    promotion: product?.promotion,
    unavailable: !product.hasStock,
    johnnieWalkerTag: product?.johnnieWalkerTag,
    isDummy: product?.isDummy,
    isCustomizable: !!product?.johnnieWalkerTag,
    ...(includeLocation ? { location: product.location } : {}),
  }

  return formattedProduct
}

export const getProducts = async (
  filters: string,
  attributes?: AlgoliaAttributes,
  includeLocation?: boolean,
): Promise<FormattedProduct[]> => {
  try {
    const { data }: AxiosResponse<ProductResponse> = await fetch('/query', {
      data: { filters, ...attributes },
      method: 'POST',
    })
    return data.hits
      .map((product) => toFormattedProduct(product, includeLocation))
      .sort((a, b) => {
        const statusAvailableA = a.unavailable ? 1 : 0
        const statusAvailableB = b.unavailable ? 1 : 0
        return statusAvailableA - statusAvailableB
      })
  } catch (e) {
    log.error(e)
    return e
  }
}

export const getAllProducts = async (filters: string, attributes?: AlgoliaAttributes, includeLocation?: boolean) => {
  try {
    const { data }: AxiosResponse<ProductResponse> = await fetch('/query', {
      data: { filters, ...attributes },
      method: 'POST',
    })
    const { nbPages, hits } = data
    // if (!nbPages) throw new Error(`No products found for filters ${filters}`)
    const firstPage = hits.map((product) => toFormattedProduct(product, includeLocation))
    if (nbPages === 1) return firstPage
    const pages = Array.from(Array(nbPages).keys()).slice(1)
    const promises = pages.map((page: number) => getProducts(filters, { ...attributes, page }, includeLocation))
    const newData = await Promise.all(promises)
    return [...firstPage, ...(newData && newData.flat ? newData.flat() : [])]
  } catch (e) {
    log.error(e)
    throw new Error(e)
  }
}

export interface AlgoliaProductImages {
  sku: string
  imgHeroHi: string
  imgShotHi: string
  imgNutriHi: string
  contentfulImages?: string[]
}

export const getProductImages = async (
  sku_codes: string[],
  city: City,
): Promise<Record<string, AlgoliaProductImages>> => {
  const payload = {
    filters: `slugLocation:${slugify(city.commerceLayer.stockLocation.name)} AND (sku=${sku_codes.join(' OR sku=')})`,
    attributesToRetrieve: ['contentfulImages', 'imgHeroHi', 'imgShotHi', 'imgNutriHi', 'sku'],
  }
  const { data } = await fetch('/query', { data: payload, method: 'POST' })
  return data.hits.reduce(
    (hash: Record<string, AlgoliaProductImages>, product: AlgoliaProductImages) => ({
      ...hash,
      [product.sku]: product,
    }),
    {},
  )
}

function getFilterBySkus(slugLocation: string, skus: string[]) {
  const skuCartEmpty = skus.join(' OR sku=')
  return `slugLocation:${slugLocation} AND (sku=${skuCartEmpty})`
}

export const getProductsBySkus = async (slugLocation: string, skus: string[], attributes?: AlgoliaAttributes) => {
  return getProducts(getFilterBySkus(slugLocation, skus), attributes)
}

export const getAllProductsBySlugLocation = async (slugLocation: string) => {
  const filters = `slugLocation:${slugLocation}`
  const hitsPerPage = 1000
  const products = (await getAllProducts(filters, { hitsPerPage }, true)) as AlgoliaProduct[]
  return products.filter((product) => product.isDummy !== true)
}
