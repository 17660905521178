import React, { useEffect, useState } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { checkCustomerDniExist, showToast, useLocation, getStoredCity } from '@ecommerce/shared'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import DocumentType from '@ecommerce/shared/src/components/molecules/DocumentType'
import secrets from '@ecommerce/chile-customer-webapp/src/config/secrets'
import useDebounce from '@ecommerce/shared/src/hooks/useDebounce'
import Button from '../../atoms/Button'
import InfoMessage from '../../atoms/InfoMessage'
import TextField from '../../molecules/TextField'
import Select from '../../molecules/Select'
import Checkbox from '../../atoms/Checkbox'
import { signUp, checkCustomerDniAndEmailExist } from '../../../services/SignUp'
import { Error as ErrorObject } from '../../../types'
import {
  ErrorLevel,
  ErrorSource,
  sendMessageToSentry,
} from '../../../../../../apps/cl-customer-webapp/src/utils/sentry'
import { getSchema } from '../AuthWidget/libs'
import { ALink, CheckboxOption, WrapperForm, toPrefix as createCssPrefix } from '../AuthWidget/styled'
import { formatRut, stripTrailingSlash } from '../../../utils/forms'
import { log } from '../../../utils/log'
import useEnterKeyPress from '../../../hooks/useEnterKeyPress'
import BirthDateInput from './BirthDateInput'
import { userDataErrorMessage } from '../../../utils/userDataErrorMessage'

const { documentTypes } = secrets

export interface FormData {
  firstName: string
  lastName: string
  dni: string
  email: string
  password: string
  city: number | string
  birthdate: string
  terms: boolean
  documentType?: string
  dniComplement?: string
  billingName?: string
}

export interface Props {
  onFinished?: (data: FormData) => void
  onClickSignIn?: () => void
  onClickSignUpConfirm?: () => void
  prehome?: boolean
  setLoading: (isLoading: boolean) => void
  loading: boolean
  onSend?: () => void
  onError?: () => void
  className?: string
  title?: string | React.ReactNode
  showBottomContent?: boolean
  showConfirmation?: boolean
  onFormatRedirectURL?: (data: FormData) => string
  disableInputs?: boolean
}

const emailImage =
  'https://images.ctfassets.net/16npdkkoi5mj/3w3FT0mHzzLuLcDRXDppod/038f39a70354cacc89688b81a54a118d/mailenviado.png'

const FormSignUp = (props: Props) => {
  const {
    onClickSignIn,
    onClickSignUpConfirm,
    prehome,
    onFinished,
    onSend,
    onError,
    loading,
    className = '',
    title = 'Crea tu cuenta',
    showBottomContent = true,
    showConfirmation = true,
    onFormatRedirectURL,
    disableInputs = false,
  } = props

  const {
    state: { byHashCities },
    isBolivia,
  } = useLocation()

  const [selectedDocumentType, setSelectedDocumentType] = useState('ci')
  const [isBillingName, setIsBillingName] = useState(true)
  const [isChecking, setIsChecking] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  const [dniValue, setDniValue] = useState('')

  const emailDebounce = useDebounce(emailValue, 1000)
  const dniDebounce = useDebounce(dniValue, 1000)

  const prevDni: string | number = ''

  const yupSchema = getSchema(isBolivia(), true, selectedDocumentType)
  const methods = useForm<FormData>({
    resolver: yupResolver(yupSchema),
    mode: 'all',
  })

  const {
    register,
    clearErrors,
    handleSubmit,
    errors,
    watch,
    setValue,
    trigger,
    control,
    reset,
    setError,
    getValues,
  } = methods

  const [citySelector, setCitySelector] = useState<string | number | undefined>()
  const [cityOptions, setCityOptions] = useState<Array<{ label: string; value: number }>>([])
  const [renderConfirmation, setRenderConfirmation] = useState(false)
  const [loadingConfirmation, setLoadingConfirmation] = useState(false)
  const [confirmationEmail, setConfirmationEmail] = useState('')
  const [formState, setFormState] = useState({})
  const [authError, setAuthError] = useState<ErrorObject | null>(null)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const watchFields = watch()
  const currentCity = getStoredCity()

  const baseUrl = stripTrailingSlash(typeof window !== `undefined` ? window.location.origin : '')

  const getRedirectURL = (data: FormData) => {
    if (prehome) return baseUrl
    if (typeof onFormatRedirectURL === `function`) return onFormatRedirectURL(data)

    return `${baseUrl}/${byHashCities[data.city].slug}/cuenta-activada`
  }

  const checkEmail = async () => {
    try {
      const response = await checkCustomerDniExist({ dni: getValues().email })
      if (response.status === 200) {
        if (response.data.customerExists && !response.data.isGuest) {
          showToast({
            content: `Ya tenemos este email asociado a una cuenta.`,
            title: `Ya existe este email`,
            type: 'error',
          })
          setError('email', {
            type: 'manual',
            message: `Ya tenemos este email asociado a una cuenta.`,
          })
        } else {
          clearErrors('email')
        }
      }
    } catch (error) {
      log.error('ERROR CHECKING EMAIL: ', error)
      showToast({
        content: `Ha ocurrido un error en la búsqueda del email`,
        title: `Ha ocurrido un error`,
        type: 'error',
      })
    }
    setIsChecking(false)
  }

  const checkEmailBolivia = async () => {
    try {
      const response = await checkCustomerDniAndEmailExist({ dni: getValues().dni, email: getValues().email })
      if (response.status === 200) {
        if (response.data.customerExists) {
          const errorData = userDataErrorMessage({
            isGuest: response.data.isGuest,
            emailCoincidence: response.data.emailCoincidence,
            dniCoincidence: response.data.dniCoincidence,
            country: currentCity,
          })

          if (errorData) {
            showToast({
              content: errorData.content,
              title: errorData.title,
              type: 'error',
            })

            if (response.data.emailCoincidence) {
              setError('email', {
                type: 'manual',
                message: errorData.message,
              })
            } else if (response.data.dniCoincidence) {
              setError('dni', {
                type: 'manual',
                message: errorData.message,
              })
            }
          }

          if (response.data.isGuest && response.data.dniCoincidence && response.data.emailCoincidence) {
            clearErrors('email')
            clearErrors('dni')
          }
        }
        if (!response.data.customerExists) {
          clearErrors('email')
          clearErrors('dni')
        }
      }
    } catch (error) {
      log.error('ERROR CHECKING EMAIL: ', error)
      showToast({
        content: `Ha ocurrido un error en la búsqueda del email`,
        title: `Ha ocurrido un error`,
        type: 'error',
      })
    }
    setIsChecking(false)
  }

  useEffect(() => {
    if (getValues().dni !== undefined) {
      setDniValue(getValues().dni)
    }
  }, [getValues().dni])

  useEffect(() => {
    if (emailValue !== '' && dniValue !== '') {
      if (isBolivia()) {
        checkEmailBolivia()
      } else {
        checkEmail()
      }
    }
  }, [emailDebounce, dniDebounce])

  async function onSubmit(data: FormData) {
    try {
      if (loading) return
      if (!executeRecaptcha) throw Error('cannot execute Recaptcha')
      if (authError) setAuthError(null)
      if (onSend) onSend()

      const {
        firstName,
        lastName,
        dni,
        email,
        password,
        city,
        birthdate,
        dniComplement,
        documentType,
        billingName,
      } = data

      setLoadingConfirmation(true)
      const reCaptchaToken = await executeRecaptcha('signup')

      await signUp({
        firstName,
        lastName,
        rut: dni,
        email,
        password,
        city,
        birthdate,
        dniComplement,
        documentType,
        billingName,
        redirectUrl: getRedirectURL(data),
        reCaptchaToken,
      })

      setFormState({
        firstName,
        lastName,
        dni,
        email,
        password,
        city,
        birthdate,
        dniComplement,
        documentType,
        billingName,
      })
      if (showConfirmation) setRenderConfirmation(true)
      setConfirmationEmail(email)
      if (onClickSignUpConfirm) onClickSignUpConfirm()
      if (onFinished) onFinished(data)
    } catch (err) {
      log.error(err)

      const errorPayload = JSON.parse(err?.response?.config?.data)
      delete errorPayload?.password

      sendMessageToSentry({
        message: 'Error on Signup',
        level: ErrorLevel.Error,
        source: ErrorSource.SIGNUP_SERVICE,
        page: window.location.pathname,
        metadata: {
          ...err?.response,
          message: err?.message,
          errorPayload,
          requestPayload: data,
        },
      })
      setAuthError({ code: '400', description: 'Error al crear usuario' })
    } finally {
      setLoadingConfirmation(false)

      if (onError) onError()
    }
  }

  useEnterKeyPress(() => handleSubmit(onSubmit)())

  useEffect(() => {
    register({ name: 'city' })
    register({ name: 'birthdate' })
    register({ name: 'billingName' })
  }, [register])

  useEffect(() => {
    const citySelectOptions = Object.values(byHashCities).map((city) => ({ label: city.name, value: city.id }))
    setCityOptions(citySelectOptions)
  }, [byHashCities])

  useEffect(() => {
    reset({
      ...watchFields,
      documentType: isBolivia() ? 'ci' : 'rut',
    })
  }, [reset])

  useEffect(() => {
    if (!isBillingName) {
      setValue('billingName', `${watchFields.firstName} ${watchFields.lastName}`, { shouldDirty: true })
    } else {
      setValue('billingName', '', { shouldDirty: true })
    }
  }, [isBillingName])

  const errorMessage = authError?.description
  const isSubmitDisabled =
    !watchFields.firstName ||
    !watchFields.lastName ||
    !watchFields.dni ||
    !watchFields.email ||
    !watchFields.password ||
    !citySelector ||
    !watchFields.birthdate ||
    !watchFields.terms ||
    !!Object.keys(errors).length

  const onCitySelect = (value: number | string): void => {
    setCitySelector(value)
    setValue('city', value, { shouldValidate: true })
  }

  function handleDniChange(dni: string) {
    clearErrors('dni')
    let value = dni
    if (!isBolivia()) value = dni.length === 0 || dni === '-' || dni === ' ' ? '' : formatRut(dni)
    return setValue('dni', value)
  }

  const isBO = isBolivia()

  const toPrefix = (prefix: string) => `${createCssPrefix(prefix)} ${className}__${prefix}`

  const onBillingNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue('billingName', value, { shouldDirty: true })
    await trigger('billingName')
  }

  const onDateChange = async (date: string) => {
    setValue('birthdate', date)
    await trigger('birthdate')
  }

  return (
    <>
      <FormProvider {...methods}>
        <WrapperForm className={className} height="100%" onSubmit={handleSubmit(onSubmit)}>
          {title && <span className={toPrefix('title')}>{title}</span>}
          {!renderConfirmation ? (
            <>
              <span className={toPrefix('subtitle')}>Regístrate para continuar</span>

              <Controller
                control={control}
                name="firstName"
                render={({ value, onChange }) => (
                  <TextField
                    maxLength={isBO ? 50 : undefined}
                    className={toPrefix('input')}
                    label="Nombre"
                    value={value || ''}
                    type="text"
                    status={!errors.firstName ? undefined : 'error'}
                    errorMessage={errors.firstName?.message}
                    onChange={onChange}
                    disabled={disableInputs}
                  />
                )}
              />

              <Controller
                control={control}
                name="lastName"
                render={({ value, onChange }) => (
                  <TextField
                    maxLength={isBO ? 50 : undefined}
                    className={toPrefix('input')}
                    label="Apellido"
                    value={value || ''}
                    type="text"
                    status={!errors.lastName ? undefined : 'error'}
                    errorMessage={errors.lastName?.message}
                    onChange={onChange}
                    disabled={disableInputs}
                  />
                )}
              />

              {isBolivia() && (
                <>
                  <CheckboxOption>
                    <Checkbox
                      disabled={!watchFields.firstName && !watchFields.lastName}
                      onChange={() => setIsBillingName(!isBillingName)}
                      checked={!isBillingName}
                      className="CheckboxOption__input"
                    />
                    <p className="CheckboxOption__label">Utilizar mi nombre y apellidos para facturas.</p>
                  </CheckboxOption>
                  {isBillingName && (
                    <TextField
                      label="Nombre para facturas"
                      name="billingName"
                      status={!errors.billingName ? undefined : 'error'}
                      errorMessage={errors.billingName?.message}
                      value={watchFields.billingName || ''}
                      onChange={onBillingNameChange}
                    />
                  )}
                  <DocumentType
                    isVisible
                    isGuest
                    dni={prevDni}
                    setIsCheckingDni={setIsChecking}
                    selectedDocumentType={selectedDocumentType}
                    setSelectedDocumentType={setSelectedDocumentType}
                    types={documentTypes}
                  />
                </>
              )}

              {!isBolivia() && (
                <Controller
                  control={control}
                  name="dni"
                  render={({ value }) => (
                    <TextField
                      className={toPrefix('input')}
                      label="RUT"
                      name="dni"
                      onChange={({ target: { value: dni } }) => handleDniChange(dni)}
                      type="text"
                      value={value || ''}
                      maxLength={isBO ? 20 : 12}
                      status={!errors.dni ? undefined : 'error'}
                      errorMessage={errors.dni?.message}
                      disabled={disableInputs}
                    />
                  )}
                />
              )}

              <Controller
                control={control}
                name="email"
                render={({ value, onChange }) => (
                  <TextField
                    maxLength={isBO ? 50 : undefined}
                    className={toPrefix('input')}
                    label="Correo electrónico"
                    type="email"
                    status={!errors.email ? undefined : 'error'}
                    value={emailValue || ''}
                    errorMessage={errors.email?.message}
                    disabled={disableInputs}
                    autoCapitalize="nope"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsChecking(true)
                      setValue('email', e.target.value)
                      setEmailValue(e.target.value)
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="password"
                render={({ value, onChange }) => (
                  <TextField
                    className={toPrefix('input')}
                    label="Contraseña"
                    type="password"
                    status={!errors.password ? 'info' : 'error'}
                    value={value || ''}
                    errorMessage={errors.password?.message}
                    helpMessage="Mínimo 8 caracteres"
                    autoComplete="new-password"
                    disabled={disableInputs}
                    onChange={onChange}
                  />
                )}
              />
              <Select
                label="Ciudad"
                name="ciudad"
                onSelect={onCitySelect}
                options={cityOptions}
                className={toPrefix('select')}
                placeholder="Ciudad"
                changeText="Cambiar"
                status={!errors.city ? undefined : 'error'}
                errorMessage={errors.city?.message}
                disabled={disableInputs}
              />

              <BirthDateInput
                errorMessage={errors.birthdate?.message}
                className={toPrefix('input')}
                onDateChange={onDateChange}
                status={!errors.birthdate ? undefined : 'error'}
                disabled={disableInputs}
              />

              <CheckboxOption className={toPrefix('terms-checkbox')}>
                <Checkbox disabled={disableInputs} className="CheckboxOption__input" name="terms" ref={register} />
                <p className="CheckboxOption__label">
                  He leído y acepto los&nbsp;
                  <a className="CheckboxOption__anchor" href="/terminos-y-condiciones" target="_blank">
                    Términos y Condiciones
                  </a>
                  .
                </p>
              </CheckboxOption>
              <InfoMessage
                className={`TextField__help ${toPrefix('terms-error')}`}
                isHidden={!errors.terms}
                message={errors?.terms?.message}
              />
              <Button
                data-test="signin-submit"
                className={toPrefix('submit')}
                whileTap={{ scale: 0.9 }}
                type="submit"
                isDisabled={isSubmitDisabled || loading || isChecking}
                disabled={isSubmitDisabled || loading || isChecking}
                isLoading={loadingConfirmation || loading || isChecking}
              >
                Crear Cuenta
              </Button>
              <div className={toPrefix('status')}>
                <InfoMessage className={toPrefix('error_message')} isHidden={!errorMessage} message={errorMessage} />
                {showBottomContent && (
                  <div className={toPrefix('setting_account')}>
                    <span>¿Ya tienes cuenta?</span>
                    <ALink disabled={loading} onClick={() => (!loading && onClickSignIn ? onClickSignIn() : null)}>
                      Inicia sesión
                    </ALink>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <span className={toPrefix('main-indication')}>
                {`Te enviamos un correo a ${confirmationEmail} para confirmar tu cuenta`}
              </span>
              <img src={emailImage} className={toPrefix('email-image')} alt="confirm" />
              <span className={toPrefix('indication')}>
                ¿No encuentras el correo? Revisa la carpeta de “No deseados”
              </span>
              <Button
                btnType="secondary"
                data-test="signup-submit"
                className={toPrefix('submit-white')}
                whileTap={{ scale: 0.9 }}
                onClick={() => onSubmit(formState as FormData)}
                isLoading={loading}
              >
                Reenviar Correo
              </Button>
            </>
          )}
        </WrapperForm>
      </FormProvider>
    </>
  )
}

export default FormSignUp
